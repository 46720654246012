import { useState, useEffect } from "react"
import { fetchAllConversationsWithFeedback } from "../lib/database"
import { Conversation } from "../types/types"


export default function useCourseFeedback(courseId: string){
    const [conversationsWithFeedback, setConversationsWithFeedback] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchFeedback = async () => {
        setIsLoading(true)
        const conversations = await fetchAllConversationsWithFeedback(courseId)
        setConversationsWithFeedback(conversations)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchFeedback()
    }, [courseId])

    return [conversationsWithFeedback, isLoading] as [Conversation[], boolean]
}