

import { Query, Timestamp, collection, doc, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { db } from "../lib/firebase";
import { onArchiveConversation } from "../lib/database";
import { Conversation } from "../types/types";
import { NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../lib/CONSTANTS";

function reducer(state: Conversation[], action){
    switch(action.type){
        case 'add':
            return [...state, ...action.payload]
        case 'clear':
            return []
    }
}

// paginates most recent conversations held by userId in courseId with pageSize
export default function useCourseConversations(courseId: string, pageSize: number, annotatedOnly=false){
    // const [conversations, setConversations] = useState([])
    const [conversations, dispatch] = useReducer(reducer, []);
    const [totalConversationCount, setTotalConversationCount] = useState(undefined)
    const [lastFetched, setLastFetched] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const fetchFirstPage = async () => {
        console.log("fetching first page of conversations")
        setIsLoading(true)

        let q = query(collection(db, `Courses/${courseId}/Conversations`),
            orderBy('timestampLastUpdated', 'desc'),
            limit(pageSize)
        )

        if(annotatedOnly){
            q = query(q, where('numAnnotations', '>=', 1))
        }

        const snapshot = await getDocs(q)
        const docs = snapshot.docs.map((d, i) => ({...d.data(), id: d.id}))
        dispatch({ type: 'add', payload: docs })
        setLastFetched(snapshot.docs[snapshot.docs.length-1])
        setIsLoading(false)
    }

    const fetchNextPage = async () => {
        console.log("fetching next page of conversations")
        setIsLoading(true)

        let q = query(collection(db, `Courses/${courseId}/Conversations`),
            orderBy('timestampLastUpdated', 'desc'),
            startAfter(lastFetched),
            limit(pageSize)
        )

        if(annotatedOnly){
            q = query(q, where('numAnnotations', '>=', 1))
        }

        const snapshot = await getDocs(q)
        const docs = snapshot.docs.map((d, i) => ({...d.data(), id: d.id}))
        dispatch({ type: 'add', payload: docs })
        setLastFetched(snapshot.docs[snapshot.docs.length-1])
        setIsLoading(false)
    }

    const fetchConvoCount = async () => {
        let q = query(collection(db, `Courses/${courseId}/Conversations`))
        if(annotatedOnly){
            q = query(q, where('numAnnotations', '>=', 1))
        }
        const snapshot = await getCountFromServer(q)
        setTotalConversationCount(snapshot.data().count)
    }

    // Fetch first conversations
    useEffect(() => {
        if(!courseId) return

        if(totalConversationCount == undefined){
            fetchConvoCount()
        }

        if(conversations.length == 0) fetchFirstPage()
    }, [courseId])

    return [
        conversations, 
        totalConversationCount,
        isLoading,
        fetchNextPage, 
    ] as [
        Conversation[], 
        number,
        boolean,
        () => void, 
    ]
}