import { Query, Timestamp, collection, doc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { db } from "../lib/firebase";
import { onArchiveConversation } from "../lib/database";
import { Conversation } from "../types/types";

function reducer(state: Conversation[], action){
    switch(action.type){
        case 'add':
            return [...state, ...action.payload]
        case 'clear':
            return []
        case 'archiveConvo':
            return state.filter((convo, i) => convo.id != action.payload)

        // replace Convo with action.payload.id with action.payload.convo
        // if no convo with that id, inserts it
        case 'setConvo':
            const i = state.findIndex((c, _) => c.id == action.payload.id)
            if(i == -1){ // Insert convo
                return [...state, action.payload.convo]
            }else{ // replace convo
                return state.map((convo, i) => convo.id == action.payload.id ? action.payload.convo : convo)
            }
    }
}

// paginates most recent conversations held by userId in courseId with pageSize
export default function useUserConversations(courseId: string, uid: string, pageSize: number){
    // const [conversations, setConversations] = useState([])
    const [conversations, dispatch] = useReducer(reducer, []);
    const [lastFetched, setLastFetched] = useState(undefined)

    const fetchFirstPage = async () => {
        console.log("fetching first conversations")
        const userDocRef = doc(db, 'Users', uid)

        const q = query(collection(db, `Courses/${courseId}/Conversations`),
            where('userDocRef', '==', userDocRef),
            where('isArchived', '!=', true),
            orderBy('timestampLastUpdated', 'desc'),
            limit(pageSize)
        )
       
        const snapshot = await getDocs(q)

        const docs = snapshot.docs.map((d, i) => ({...d.data(), id: d.id}))
        dispatch({ type: 'add', payload: docs })
        setLastFetched(snapshot.docs[snapshot.docs.length-1])
    }

    const fetchNextPage = async () => {
        const userDocRef = doc(db, 'Users', uid)

        const q = query(collection(db, `Courses/${courseId}/Conversations`),
            where('userDocRef', '==', userDocRef),
            where('isArchived', '!=', true),
            orderBy('timestampLastUpdated', 'desc'),
            startAfter(lastFetched),
            limit(pageSize)
        )

        const snapshot = await getDocs(q)

        const docs = snapshot.docs.map((d, i) => ({...d.data(), id: d.id}))
        dispatch({ type: 'add', payload: docs })
        setLastFetched(snapshot.docs[snapshot.docs.length-1])
    }

    const archiveConversation = (convoId: string) => {
        // update db
        console.log("HERE", convoId)
        onArchiveConversation(convoId, courseId, uid)

        // update local convo
        dispatch({ type: 'archiveConvo', payload: convoId })
    }

    const setConversation = (convo: Conversation, id: string) => {
        dispatch({ type: 'setConvo', payload: { convo, id } })
    }

    // Fetch first conversations
    useEffect(() => {
        if(!uid || !courseId || lastFetched != undefined) return

        fetchFirstPage()
    }, [courseId, uid])

    return [
        conversations, 
        fetchNextPage, 
        archiveConversation, 
        setConversation
        // (convo) => dispatch({ type: 'add', payload:  [convo] })
    ] as [
        Conversation[], 
        () => void, 
        (convoId: string) => void, 
        (c: Conversation, id: string) => void,
        // (c: Conversation) => void
    ]
}