import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { db } from "../lib/firebase";
import { Course } from "../types/types";

export default function useCourses(courseIds: string[]){
    const [courses, setCourses] = useState<Course[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchCourses = useCallback(async () => {
        if(courseIds.length == 0){
            setIsLoading(false)
            return
        }

        const coursesCollection = collection(db, "Courses")
        const q = query(coursesCollection, where(documentId(), "in", courseIds))
        const snapshots = await getDocs(q)
        setCourses(snapshots.docs.map((doc, i) => ({...doc.data(), id: doc.id}) as Course))
        setIsLoading(false)
        
    }, [courseIds])

    useEffect(() => { 
        setIsLoading(true)    
        fetchCourses()
    }, [courseIds, fetchCourses])

    return [courses, isLoading] as [Course[], Boolean]
}