import * as ReactDOM from "react-dom/client"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./routes/home";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Tutor from "./routes/tutor";
import theme from "./theme";
import { Analytics } from "@vercel/analytics/react"
import Share from "./routes/share";
import Dashboard from "./routes/dashboard";
import Login from "./routes/login";
import App from "./routes/app";
import User from "./routes/admin/user";
import TestPage from "./routes/test"
import Join from "./routes/join";
import Usage from "./routes/admin/usage";
import Users from "./routes/admin/users";
import Conversations from "./routes/admin/conversations";
import Controls from "./routes/admin/controls";
import Course from "./routes/course";
import Resources from "./routes/resources";
import Chats from "./routes/chats";
import Account from "./routes/account";
import NotFound from "./routes/404";
import Master from "./routes/master";
import Feedback from "./routes/admin/feedback";
import Clusters from "./routes/admin/clusters";
import Annotations from "./routes/admin/annotations";
import Annotation from "./routes/admin/annotation";
import ResourceManager from "./routes/admin/resource_manager";
import AddResource from "./routes/admin/add_resource";


const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const themeObject = extendTheme(theme)

const router = createBrowserRouter([
    {
        element: <Master />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/join/:courseId',
                element: <Join />
            },
            {
                path: '/test',
                element: <TestPage />
            },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/app/:courseId/share/:conversationId',
                element: <Share />
            },
            {
                path: '/app',
                element: <App />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="/app/dashboard" />,
                    },
                    {
                        path: 'dashboard',
                        element: <Dashboard />,
                        id: "dashboard",
                    },
                    {
                        path: ':courseId/tutor/:resourceClass/:resourceName',
                        element: <Tutor />,
                        id: "tutor"
                    },
                    {
                        path: ':courseId/admin/annotations/:conversationDocId',
                        element: <Annotation />
                    },
                    {
                        path: ':courseId',
                        element: <Course />,
                        children: [
                            {
                                index: true,
                                element: <Navigate to='start' />
                            },
                            {
                                path: 'start',
                                element: <Resources />
                            },
                            {
                                path: 'chats',
                                element: <Chats />
                            },
                            {
                                path: 'account',
                                element: <Account />
                            },
                            {
                                path: 'admin',
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'usage'} />
                                    },
                                    {
                                        path: 'usage',
                                        element: <Usage />
                                    },
                                    {
                                        path: 'feedback',
                                        element: <Feedback />
                                    },
                                    {
                                        path: 'clusters',
                                        element: <Clusters />
                                    },
                                    {
                                        path: 'conversations',
                                        element: <Conversations />
                                    },
                                    {
                                        path: 'resources',
                                        children: [
                                            {
                                                index: true,
                                                element: <ResourceManager />,
                                            },
                                            {
                                                path: 'new',
                                                element: <AddResource />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'annotations',
                                        children: [
                                            {
                                                index: true,
                                                element: <Annotations />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'users',
                                        children: [
                                            {
                                                index: true,
                                                element: <Users />
                                            },
                                            {
                                                path: ':uid',
                                                element: <User />
                                            }
                                        ]
                                    },
                                    {
                                        path: 'controls',
                                        element: <Controls />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    }
])

root.render(
    <ChakraProvider theme={themeObject}>
        <RouterProvider router={router} />
        <Analytics />
    </ChakraProvider>
);