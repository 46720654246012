import { Box, Flex, Heading, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Textarea, VStack, Text, Center, useColorModeValue, Button, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import MultiCheckbox from "../../ui/MultiCheckbox";
import { ATA_FEEDBACK_ISSUES } from "../../lib/CONSTANTS";

type state = 'input' | 'sending' | 'success' | 'failure'

export default function FeedbackBody({  sendFeedback, initialScaleValue=30, initialTextValue="", initialIssues=[], inChat=false }) {

    const [scaleValue, setScaleValue] = useState(initialScaleValue)
    const [textFeedback, setTextFeedback] = useState(initialTextValue)
    const [issues, setIssues] = useState(initialIssues)

    const [formState, setFormState] = useState<state>('input')

    const handleSave = async () => {
        setFormState('sending')

        const feedback = { helpfulness: scaleValue, comments: textFeedback, issues }
        // await addFeedbackToConversationDoc(courseId, conversationDocId, feedback)
        sendFeedback(feedback)

        setFormState('success')
    }

    const likertTextColor = useColorModeValue('slate.500', 'slate.400')
    const sliderTrackBg = useColorModeValue('primary.600', 'primary.500')
    const successIconColor = useColorModeValue('green.600', 'green.500')

    if(formState == 'sending') return <Center py='16'><Spinner /></Center>

    if(formState == 'success') return (
        <Center flexDir={'column'} gap='3' py='16'>
            <CheckIcon boxSize={10} color={successIconColor} />
            <Text>Sent! Thank you for helping to make ATA better.</Text>
        </Center>
    )

    return (
        <VStack gap='6' py='3' alignItems={'stretch'}>
            { inChat && <Text fontSize='lg'><strong>Feedback:</strong> While ATA generates your response, how is your conversation with ATA going so far?</Text>}

            <Box>
                <Heading mb='1' size='sm'>How helpful is ATA in this conversation so far?</Heading>
                <Slider 
                    colorScheme={'primary'} 
                    defaultValue={30}
                    min={1}
                    max={70}
                    step={1}
                    value={scaleValue}
                    onChange={(n) => setScaleValue(n)}
                >
                    <SliderTrack>
                        <SliderFilledTrack bg={sliderTrackBg} />
                    </SliderTrack>
                    <SliderThumb borderWidth={'1px'} borderColor={'slate.200'} />
                </Slider>
                <Flex alignItems={'center'} justifyContent={'space-between'}>
                    <Text fontSize='sm' color={likertTextColor}>Not helpful at all</Text>
                    <Text fontSize='sm' color={likertTextColor}>Very Helpful</Text>
                </Flex>
            </Box>


            <Box>
                <Heading mb='1' size='sm'>Issues</Heading>
                <MultiCheckbox 
                    allowMultipleOn
                    itemsChecked={issues}
                    items={ATA_FEEDBACK_ISSUES}
                    onChange={setIssues}
                    />
            </Box>

            <Box>
                <Heading mb='1' fontWeight={400} size='sm'><strong>Comments</strong> <Box as={'span'} fontSize='sm' color='slate.500'>(optional)</Box></Heading>
                <Textarea value={textFeedback} _focus={{ borderColor: 'primary.500', boxShadow: 'none', borderWidth: '2px' }} onChange={(e) => setTextFeedback(e.target.value)} placeholder="Type here..." />
            </Box>

            <Button color='white' onClick={handleSave} colorScheme={'primary'} m='auto' px='12' fontWeight={400}>Save</Button>
        </VStack>
    )
} 