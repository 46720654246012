import { useEffect, useState } from "react";
import { ClusterDoc } from "../types/types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../lib/firebase";


export default function useCourseClusters(courseId: string){
    const [clusterDoc, setClusterDoc] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)

    const fetchDoc = async () => {
        const docRef = doc(db, 'Courses', courseId, 'Data', 'clusters')

        const snapshot = await getDoc(docRef)
        if (snapshot.exists){
            setClusterDoc(snapshot.data())
        }
    }

    useEffect(() => {
        setIsLoading(true)
        fetchDoc()
    }, [courseId])

    useEffect(() => {clusterDoc !== undefined && setIsLoading(false)}, [clusterDoc])

    return [clusterDoc, isLoading] as [ClusterDoc, boolean]
}