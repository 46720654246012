import { ForwardedRef, MutableRefObject } from "react";
import Response from "./Response";
import { Exchange, Feedback } from "../../types/types";
import { Box } from "@chakra-ui/react";


export default function Pair({ 
    queryText, 
    responseText, 
    onFeedback, 
    isAdmin, 
    sources, 
    log,
    onCopyResponseLink, 
    stats,
    onReferenceTagClick,
    isTerminalExchange,
    isSubtaskCompleted,
    isSuggest,
    lastFeedback=undefined,
    showFeedback=false,
    userName=undefined,
    isShare=false,
    loadingMessage=""
 } : 
    Exchange & { 
        isAdmin: boolean, 
        tutorResponseRef?: (el: Element) => void, 
        onCopyResponseLink: (i: number) => void, 
        onFeedback: () => void,
        showFeedback?: boolean,
        loadingMessage: string,
        onReferenceTagClick: (id: string) => void,
        isShare: boolean,
        lastFeedback?: Feedback,
        userName?: string
    }){

    return (
        <>
            <Response 
                loadingMessage={""} 
                content={queryText} 
                speaker={"student"} 
                showFeedback={false}
                stats={null}
                userName={userName}
                sources={null}
                sendFeedback={undefined}
                log={null}
                isAdmin={isAdmin}
                isSubtaskCompleted={false}
                isSuggest={false}
                onReferenceTagClick={undefined}
                isTerminalExchange={undefined}
                isShare={isShare}
                lastFeedback={undefined}
            />
            {/* <Box w='full'> */}
                <Response 
                    content={responseText} 
                    speaker="tutor" 
                    loadingMessage={loadingMessage}
                    sources={sources} 
                    stats={stats}
                    userName={userName}
                    showFeedback={showFeedback}
                    sendFeedback={onFeedback}
                    isAdmin={isAdmin}
                    log={log}
                    onReferenceTagClick={onReferenceTagClick}
                    isSubtaskCompleted={isSubtaskCompleted}
                    isSuggest={isSuggest}
                    isTerminalExchange={isTerminalExchange}
                    isShare={isShare}
                    lastFeedback={lastFeedback}
                />
            {/* </Box> */}
        </>
    )
}