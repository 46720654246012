import { background, border, Box, Card, CardBody, Heading, position, useColorModeValue } from "@chakra-ui/react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    ArcElement,
    LineElement,
    Filler
} from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { formatDateShort } from "../../lib/date_utils";
import { get } from "http";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Filler,
    Title,
    Tooltip,
    Legend,
)

const TYPE_TO_COMPONENT = {
    'line': Line,
    'bar': Bar,
    'pie': Pie,
    'stackedbar': Bar
}

const generateHSLColor = (i: number, numColors: number) => {
    const hue = i * (360 / numColors);
    return `hsl(${hue}, 70%, 50%, 0.6)`;
}

const generateHSLBorderColor = (i: number, numColors: number) => {
    const hue = i * (360 / numColors);
    return `hsl(${hue}, 60%, 30%, 0.6)`;
}

export default function Chart({ labels, data, xLabel, yLabel, type, title, datasetLabels, fill = false, displayLegend=false, suggestedYMax = 0, suggestYMin=0, hasBaseline=false }) {

    const fillColorList = [
        useColorModeValue('rgba(124, 58, 237, 0.6)', 'rgba(124, 58, 237, 0.6)'), // primary
        useColorModeValue('rgba(34, 197, 94, 0.6)', 'rgba(34, 197, 94, 0.6)'), // green
        useColorModeValue('#f97316', '#f97316'), // orange
        useColorModeValue('#3b82f6', '#3b82f6'), // blue
        useColorModeValue('#8b5cf6', '#8b5cf6'), // violet
        useColorModeValue('#f43f5e', '#f43f5e'), // rose
        useColorModeValue('#14b8a6', '#14b8a6'), // teal
    ]

    const borderColorList = [
        useColorModeValue('rgba(124, 58, 237, 1)', '#8b5cf6'),
        useColorModeValue('rgba(34, 197, 94, 1)', 'rgba(34, 197, 94, 1)')
    ]

    const gray = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)')

    const Component = TYPE_TO_COMPONENT[type]

    const labelColor = useColorModeValue('#333', '#ccc')
    const gridColor = useColorModeValue('#ccc', '#666')

    const scaleOptions = {
        grid: {
            display: type != 'pie',
            drawBorder: type != 'pie',
            color: gridColor, // Grid line color
            borderColor: gridColor, // Color of the axis line
        },
        ticks: {
            display: type != 'pie',
            color: labelColor, // Color of the tick labels
        },
    }

    let scales = {
        x: {
            title: {
                display: xLabel != undefined,
                text: xLabel,
                color: labelColor,
                font: {
                    family: 'Open Sans',
                    size: 15,
                    weight: 'bold',
                    lineHeight: 1.2,
                },
            },
            stacked: type == 'stackedbar',
            ...scaleOptions
        },
        y: {
            title: {
                display: yLabel != undefined,
                text: yLabel,
                color: labelColor,
                font: {
                    family: 'Open Sans',
                    size: 15,
                    weight: 'bold',
                    lineHeight: 1.2,
                },
            },
            stacked: type == 'stackedbar',
            suggestedMax: suggestedYMax,
            suggestedMin: suggestYMin,
            // beginAtZero: beginAtZero,
            ...scaleOptions
        }
    }

    return (
        <Component
            data={{
                labels: labels,
                datasets: data.map((d,i) => ({
                    fill: fill,
                    label: datasetLabels[i],
                    data: d,
                    borderWidth: 2,
                    tension: 0.4,
                    borderColor: 
                        hasBaseline && i == data.length - 1 ? gray : data.length <= borderColorList.length ? borderColorList[i] : generateHSLBorderColor(i, data.length),
                    backgroundColor: 
                    hasBaseline && i == data.length - 1 ? gray : data.length <= fillColorList.length ? fillColorList[i] : generateHSLColor(i, data.length),
                }))
            }}
            options={{
                plugins: {
                    tooltip: {
                        enabled: true
                    },
                    legend: {
                        display: displayLegend
                    }
                },
                scales,
                maintainAspectRatio: true,
            }}
        />
    )
}

export function PieChart({ data, title, labels }){
    const colorList = [
        useColorModeValue('#f97316', '#f97316'), // orange
        useColorModeValue('#22c55e', '#22c55e'), // green
        useColorModeValue('#ef4444', '#ef4444'), // red
        useColorModeValue('#3b82f6', '#3b82f6'), // blue
        useColorModeValue('#8b5cf6', '#8b5cf6'), // violet
        useColorModeValue('#f43f5e', '#f43f5e'), // rose
        useColorModeValue('#14b8a6', '#14b8a6'), // teal
    ]

    const labelColor = useColorModeValue('#333', '#ccc')

    return (
        <Pie
            data={{
                labels: labels,
                datasets: [{
                    label: title,
                    data,
                    backgroundColor: colorList
                }]
            }}
            options={{
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            color: labelColor
                        },
                        position: 'right'
                    },
                    tooltip: {
                        enabled: true
                    }
                }
            }}
        />
    )
}