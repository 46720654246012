import { Box, Button, Center, Collapse, Text, Flex, Heading, Input, InputGroup, InputLeftElement, SimpleGrid, Spinner, Tag, useColorModeValue, useBreakpointValue, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Icon, GridItem, Divider, Link } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import useSingleCourse from '../../hooks/useSingleCourse'
import { ExtraCourseData, ResourceMetadata } from '../../types/types'
import ResourceCard from './ResourceCard'
import { compareDates } from '../../lib/date_utils'
import { RCLASS_TO_COLOR, COURSE_ID_TO_NAME } from '../../lib/CONSTANTS'
import { useState } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import Fuse from 'fuse.js'
import { hashString } from '../../lib/text_utils'
import useUserCourseDoc from '../../hooks/useUserCourseDoc'
import useUserDoc from '../../hooks/useUserDoc'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../lib/firebase'
import dayjs from 'dayjs'
import { IoMdMegaphone } from 'react-icons/io'
import { incrementNotificationsSeen } from '../../lib/database'

const MIN_RESOURCES_TO_HIGHLIGHT = 6

// convert extraCourseData object structure to list of Resources
export function getResourceListFromExtraCourseData(extraCourseData: ExtraCourseData) {
    const resources: ResourceMetadata[] = []
    for (const [rclass, resourceObj] of Object.entries(extraCourseData)) {
        for (const [title, metadata] of Object.entries(resourceObj)) {
            resources.push({ ...metadata, resourceClass: rclass, title })
        }
    }

    resources.sort(
        (a, b) => (a?.releaseDate || b?.releaseDate) ?
            (compareDates(a?.releaseDate || '01/01/1970', b?.releaseDate || '01/01/1970') ? 1 : -1) :
            (a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: "base" }))

    )

    return resources
}

export default function Resources({ }) {
    const params = useParams()
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)

    const [filterResourceClass, setFilterResourceClass] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState("")

    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)

    const isAdmin = userDoc.adminCourses.includes(params.courseId)

    const resourceGridBg = useColorModeValue('slate.100', 'slate.800')
    const resourceDividerColor = useColorModeValue('slate.300', 'slate.600')

    const isFull = useBreakpointValue({ base: false, xl: true });

    if (isCourseLoading || !extraCourseData) return <Center py='64'><Spinner /></Center>

    const resources = getResourceListFromExtraCourseData(extraCourseData as ExtraCourseData)

    const resourceClassesInCourse = isCourseLoading ? [] : Object.keys(course.schema)

    // filtering based on rclass
    const filteredResources = filterResourceClass === undefined ? resources : resources.filter(({ resourceClass }, i) => resourceClass === filterResourceClass)

    // apply search
    const resourcesFuse = new Fuse(filteredResources, {
        keys: ['title', 'topics', 'summary', 'stub'],
        threshold: 0.4
    })

    const resourcesToShow = searchQuery.length > 0 ? resourcesFuse.search(searchQuery).map(({ item }, i) => item) : filteredResources


    const today = dayjs()
    const tenDaysAgo = today.subtract(10, 'day')

    const recentResources = searchQuery.length > 0 ? resourcesToShow : resourcesToShow.filter((r, i) => dayjs(r.releaseDate).isAfter(tenDaysAgo) || i > resourcesToShow.length-(MIN_RESOURCES_TO_HIGHLIGHT+1)).reverse()
    const olderResources = searchQuery.length > 0 ? [] : resourcesToShow.filter((r, i) => dayjs(r.releaseDate).isBefore(tenDaysAgo) && i <= resourcesToShow.length-(MIN_RESOURCES_TO_HIGHLIGHT+1)).reverse()

    return (
        <Flex flexDir={'column'} w='full' h='full'>
            {/* <AnnouncementModal
                notificationsSeen={userDoc.notificationsSeen}
                uid={user.uid}
            /> */}

            {/* <Box mb='8' p='4' bg={'primary.100'} borderRadius={'md'}>
                <Text>ATA may be down momentarily due to an upstream outage at OpenAI. It should be fixed very soon.</Text>
            </Box> */}

            <Flex flexDir={'column'} mb='8'>
                <Heading size={['md', null, null, 'lg']} flexWrap={'wrap'} mb='3' display={'flex'} fontWeight={"extrabold"} alignItems={'baseline'}>{COURSE_ID_TO_NAME[course.id]} | {course.semester} {course.year}</Heading>
                <Heading size={['sm', null, null, 'md']} flexWrap={'wrap'} mb='3' display={'flex'} fontWeight={"normal"} alignItems={'baseline'}>{course.title}</Heading>
            </Flex>

            <SearchInput maxW='825px' mb='6' searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>

            <Box
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                borderRadius={'6'}
                pb={'6'}
            >
                {recentResources.length > 0 && searchQuery == "" && <Heading size="md" mb={6}>Current Resources</Heading>}

                <SimpleGrid
                    w='full'
                    gap='6'
                    gridTemplateColumns={isFull ? "repeat(auto-fit, 400px)" : ['1fr', null, null, '1fr 1fr']}
                    mb={6}
                >

                    {recentResources.map((r, i) => (
                        <ResourceCard
                            showState={compareDates(today, r.releaseDate) ? 'student-visible' : isAdmin ? 'admin-visible' : 'hidden'}
                            key={i}
                            courseId={params.courseId}
                            {...r}
                        />
                    ))}
                </SimpleGrid>

                {olderResources.length > 0 && <Heading size="md" mb={6} mt={8}>Previous Resources</Heading>}
                
                <SimpleGrid
                    w='full'
                    gap='6'
                    gridTemplateColumns={isFull ? "repeat(auto-fit, 400px)" : ['1fr', null, null, '1fr 1fr']}
                >
                    {olderResources.map((r, i) => (
                            <ResourceCard
                                showState={'past-due'}
                                key={-i}
                                courseId={params.courseId}
                                {...r}
                            />
                        ))}
                </SimpleGrid>

                {recentResources.length == 0 && olderResources.length == 0 && <Heading size="sm">No resources found</Heading>}
            </Box>
        </Flex>
    )
}

export function SearchInput({ searchQuery, setSearchQuery, ...otherArgs }) {
    return (
        <InputGroup {...otherArgs}>
            <InputLeftElement pointerEvents='none'>
                <SearchIcon color={useColorModeValue('slate.300', 'slate.500')} />
            </InputLeftElement>
            <Input _focus={{ borderColor: 'slate.500', boxShadow: 'none', borderWidth: '1px' }} borderColor={useColorModeValue('slate.400', 'slate.600')} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} bg={useColorModeValue('white', 'slate.800')} type='text' placeholder='Search for a resource' />
        </InputGroup>)
}

function ResourcesList({ resources, courseId }: { resources: ResourceMetadata[], courseId: string }) {

    const [searchQuery, setSearchQuery] = useState("")

    const lectures = resources.filter(({ resourceClass }, i) => resourceClass == 'lecture')
    const hws = resources.filter(({ resourceClass }, i) => resourceClass == 'hw')

    const lecturesFuse = new Fuse(lectures, {
        keys: ['title', 'summary']
    })

    const hwsFuse = new Fuse(hws, {
        keys: ['title', 'summary']
    })

    const lecturesToShow = searchQuery.length > 0 ? lecturesFuse.search(searchQuery).map(({ item }, i) => item) : lectures
    const hwsToShow = searchQuery.length > 0 ? hwsFuse.search(searchQuery).map(({ item }, i) => item) : hws


    return (
        <Box>
            <Heading mb='3' display={'flex'} alignItems={'center'}>
                Talk to ATA about a
                <Tag ml='4' mr='2' size='xl' px='3' py='1' colorScheme={RCLASS_TO_COLOR["lecture"]}>lecture</Tag> or
                <Tag ml='2' size='xl' px='3' py='1' colorScheme={RCLASS_TO_COLOR["hw"]}>hw</Tag>
            </Heading>
            <InputGroup mb='3'>
                <InputLeftElement pointerEvents='none'>
                    <SearchIcon color={useColorModeValue('slate.300', 'slate.500')} />
                </InputLeftElement>
                <Input _focus={{ borderColor: 'primary.600', boxShadow: 'none', borderWidth: '2px' }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} bg={useColorModeValue('white', 'slate.800')} type='text' placeholder='Search' />
            </InputGroup>
            <SimpleGrid alignItems={'start'} gap='6' gridTemplateColumns={'1fr 1fr'}>
                <Box>
                    <Heading mb='3' size='md'>Lectures</Heading>
                    <SimpleGrid gridTemplateColumns={'1fr 1fr'} gap='3'>
                        {lecturesToShow.map((r, i) => <ResourceCard showState='student-visible' key={i} courseId={courseId} {...r} />)}
                    </SimpleGrid>
                </Box>
                <Box>
                    <Heading mb='3' size='md'>HW</Heading>
                    <SimpleGrid minChildWidth={'64'} gap='3'>
                        {hwsToShow.map((r, i) => <ResourceCard showState='student-visible' key={i} courseId={courseId} {...r} />)}
                    </SimpleGrid>
                </Box>
            </SimpleGrid>
        </Box>
    )
}


function AnnouncementModal({ notificationsSeen, uid }) {
    const showAnnouncement = notificationsSeen < 1

    if(!showAnnouncement) return

    return (
        <Modal isOpen={showAnnouncement} onClose={() => incrementNotificationsSeen(uid)}>
            <ModalOverlay />
            <ModalContent maxW='3xl' w='full'>
                <ModalHeader alignItems={'center'} display={'flex'}>
                    🎊 11/15/24 Update 🎊
                </ModalHeader>
                <ModalCloseButton />
               
                <ModalBody pb='8'>
                    <Text>As you may have noticed, we've made some changes to ATA over the past few weeks, culminating in a big update today. We've made ATA faster, less repetitive, less confused, and better at referencing slides. And of course, ATA got a bit of makeover. If there are other features/improvements you'd like to see in ATA, <Link target="_blank" color='primary.600' href='mailto:team@talktoata.com'>let us know</Link>. Good luck starting your final projects!</Text>
                    <Text fontStyle={'italic'}> - The ATA Team</Text>
                </ModalBody>

            </ModalContent>
        </Modal>
    )
}