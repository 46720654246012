import { Box, Divider, Stack, Flex, Heading, Text, IconButton, OrderedList, ListItem, Link, VStack, useColorMode, useColorModeValue, Textarea, useToast, useBreakpoint } from "@chakra-ui/react";
import Pair from "./Pair";
import { APP_TITLE, ATA_FEEDBACK_MESSAGE, ATA_INTRO_MESSAGE, MAX_QUERY_LENGTH } from "../../lib/CONSTANTS";
import ExpandingTextarea from 'react-expanding-textarea'
import { useEffect, useRef, useState } from "react";
import { RiSendPlaneFill } from "react-icons/ri";
import FeedbackBody from "./FeedbackBody";
import { LatexMarkdownFormattedText, ResponseShell } from "./Response";
import { ResourceChats } from "../chats";
import { ConvoAdminCard } from ".";


function addColon(title: string) {
    // Turn "Lecture X Title" into "Lecture X: Title"
    let stringParts = title.split(' ')
    if (stringParts.length >= 2 && stringParts[0].toLowerCase() == 'lecture') {
        stringParts[1] += ':'
    }
    return stringParts.join(' ')
}


export default function Chat({ responses, resourceName, onQuerySubmit, tutorState, onFeedback, showFeedbackOnLast, isAdmin, pastingDisabled, onCopyResponseLink, loadingMessage, lastTutorEngineVersion, promptDisabled, responseListRef, onReferenceTagClick, disableSendingQuery, lastFeedback, conversationData }) {

    const [isClient, setIsClient] = useState(false)
    const textareaRef = useRef(null)

    const breakpoint = useBreakpoint()

    useEffect(() => {
        setIsClient(true)
        textareaRef.current.focus()
    }, [])

    const subHeaderColor = useColorModeValue('slate.600', 'slate.400')

    return (
        <Flex
            // h={['calc(95vh)', null, null, 'calc(100vh)']}
            h='100%'
            flexDir={'column'}
            // flexShrink={1}
            w='full'
            flexGrow={1}
            overflow={'hidden'}
            justifyContent={'space-between'}
        >
            <Box
                // h="calc(87vh)"
                overflowY={"auto"}
                flex={1}
                position={'relative'}
                sx={['base', 'sm', 'md'].includes(breakpoint) && {
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                pb={8}

            >
                {isClient && <Stack ref={responseListRef} gap={8}>
                    <VStack gap='8' m='auto' w='full' maxW={['5xl', null, null, null, '4xl']}>
                        {/* <ResponseShell isShare={false} isAdmin={false} speaker={""} userName={undefined} >
                            <LatexMarkdownFormattedText content={ATA_FEEDBACK_MESSAGE} onReferenceTagClick={console.log} />
                        </ResponseShell>
                        <ResponseShell isShare={false} isAdmin={false} speaker={"tutor"} userName={undefined} >
                            <LatexMarkdownFormattedText content={ATA_INTRO_MESSAGE} onReferenceTagClick={console.log} />
                        </ResponseShell> */}
                    </VStack>
                    {responses.length == 0 &&
                        <VStack 
                            w='full'
                            pt='32'
                            m='auto' 
                            justifyContent='center'
                            alignItems='center'
                        >
                            <Heading mb='3' textColor='slate.500' textAlign={'center'}>{addColon(resourceName)}</Heading>
                            
                            {
                                Object.keys(conversationData).length > 0 &&
                                    <>
                                        {/* <Divider mb='6'/> */}
                                        <ResourceChats 
                                            conversationData={conversationData}
                                            resourceName={resourceName} 
                                            k={5} 
                                        />
                                    </>
                            }
                        </VStack>
                    }
                    {responses.map((response, i) => <VStack key={i} gap='8' m='auto' w='full' maxW={['5xl', null, null, null, '4xl']}>
                        <Pair 
                            loadingMessage={i == responses.length-1 ? loadingMessage : ""} 
                            isAdmin={isAdmin} 
                            onCopyResponseLink={() => onCopyResponseLink(i)} 
                            onFeedback={onFeedback} 
                            onReferenceTagClick={onReferenceTagClick}
                            showFeedback={showFeedbackOnLast && (i==responses.length-1)}
                            lastFeedback={lastFeedback}
                            {...response} 
                        />
                    </VStack>
                    )}

                    {/* <ResponseShell speaker={'tutor'}>
                        <FeedbackBody />
                    </ResponseShell> */}

                    {/* (tutorState && isAdmin) && <ConvoAdminCard m='auto' w='full' maxW={['5xl', null, null, null, '4xl']} lastTutorEngineVersion={lastTutorEngineVersion} {...tutorState} /> */}

                </Stack>}

                {/* {responses.length == 0 && <NoResponsesCenterPiece />} */}

            </Box>
            <Box
                // h="calc(8vh)"
                pb='3'
                flexShrink={0}
                m='auto'
                w='full'
                maxW={['5xl', null, null, null, '4xl']}
            >
                <EnterRespondingInput
                    textareaRef={textareaRef}
                    onQuery={onQuerySubmit}
                    placeholder={`Ask ${APP_TITLE} a question`}
                    pastingDisabled={pastingDisabled}
                    promptDisabled={promptDisabled}
                    disableSendingQuery={disableSendingQuery}
                />
            </Box>
        </Flex>
    );
}

function NoResponsesCenterPiece() {
    return (
        <Box
            borderRadius={'6'}
            bg={useColorModeValue('slate.100', 'slate.700')}
            p='3'
            color={useColorModeValue('slate.500', 'slate.300')}
            maxW='lg'
            mx='auto'
            mt={['16', null, null, '48']}
        >
            <Text mb='2' fontWeight={'700'} textAlign={'center'}>Tips for using {APP_TITLE}</Text>
            <OrderedList spacing={'3'}>
                <ListItem>Tell {APP_TITLE} what you know, where you want to go, and how you want to get there.</ListItem>
                <ListItem>{APP_TITLE} isn't like a normal LLM—it's designed to help you learn! Don't expect your interactions with {APP_TITLE} to be transactional.</ListItem>
                <ListItem>Like other LLMs, {APP_TITLE} can make mistakes, so trust your gut.</ListItem>
            </OrderedList>
            <Text mt='2'>{APP_TITLE} is still experimental! Help us improve the learning experience we can provide to your classmates by answering the feedback questions honestly.</Text>
        </Box>
    )
}

const EnterRespondingInput = ({ onQuery, textareaRef, placeholder, pastingDisabled, promptDisabled, disableSendingQuery }) => {
    const [queryText, setQueryText] = useState("")
    const [shiftPressed, setShiftPressed] = useState(false)

    const normalBackground = useColorModeValue('white', '#334155')
    const disabledBackground = useColorModeValue('#e2e8f0', '#475569')

    const toast = useToast()

    const disablePaste = (e) => {
        e.preventDefault()
    }

    useEffect(() => {
        if (queryText == "\n") setQueryText("")
    }, [queryText])

    const handleSubmit = () => {
        if(queryText.length == 0 || queryText == "\n" || promptDisabled || disableSendingQuery) return
        onQuery(queryText)
        setQueryText("")
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (!shiftPressed) {
                handleSubmit()
            }
        }
        if (event.key === 'Shift') setShiftPressed(true)
    }

    const handleKeyUp = (event) => {
        if (event.key === 'Shift') setShiftPressed(false)
    }

    const handleChange = (e) => {
        if(e.target.value.length >= MAX_QUERY_LENGTH){
            toast({
                status: 'warning',
                title: 'Character Limit Reached',
                description: 'You have reached the maximum amount of characters you can input. This is present for security reasons.',
                position: 'top'
            })
        }

        if(!promptDisabled){
            setQueryText(e.target.value)
        }
    }

    return (
        <Box position={'relative'}>
            {/* <Textarea 
                as={ExpandingTextarea}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                maxLength={MAX_QUERY_LENGTH}
                ref={textareaRef}
                value={queryText}
                onChange={e => !promptDisabled && setQueryText(e.target.value)}
                rows={1}
                disabled={promptDisabled}
                placeholder={placeholder}
                
                resize={'none'}
                borderWidth={'1px'}
                borderColor={useColorModeValue('slate.400', '#475569')}
                _hover={{ borderColor: useColorModeValue('slate.300', '#475569') }}
                borderRadius={'0.5rem'}
                fontSize={'md'}
                padding={'1em 3.5em 0.75em 1em'}
                display={'block'}
                outline='0'
                color={useColorModeValue('slate.600', 'slate.400')}
                width={'100%'}
                maxH={'100%'}
                overflowY={'scroll'}
                background={normalBackground}
                onPaste={pastingDisabled && disablePaste}
            /> */}
            <ExpandingTextarea
                className="main-input"
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                maxLength={MAX_QUERY_LENGTH}
                ref={textareaRef}
                value={queryText}
                onChange={handleChange}
                rows={1}
                disabled={promptDisabled}
                placeholder={placeholder}
                style={{
                    resize: 'none',
                    border: `1px solid ${useColorModeValue('#eee', '#475569')}`,
                    borderRadius: '3.5',
                    fontSize: '1.0em',
                    padding: '0.8em 3.5em 0.75em 1em',
                    display: 'block',
                    outline: '0',
                    // 56rem is chakra's 4xl
                    width: '100%',
                    maxHeight: '200px',
                    overflowY: 'scroll',
                    scrollbarColor: useColorModeValue('slate.200', 'slate.700'),
                    background: promptDisabled ? disabledBackground : normalBackground
                }}
                onPaste={pastingDisabled ? disablePaste : () => {}}
            />
            <IconButton
                position={'absolute'}
                colorScheme={(queryText != "" && !promptDisabled && !disableSendingQuery) ? 'primary' : 'gray'}
                right='1.5'
                color={'white'}
                bottom='1.5'
                borderRadius={'3'}
                aria-label="Ask question button"
                onClick={handleSubmit}
                icon={<RiSendPlaneFill />}
            />
        </Box>
    )
}