import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
import { storage } from './firebase'


export async function getURLForPage(path: string){
    const storageRef = ref(storage, path);

    try {
        let url = await getDownloadURL(storageRef)
        return url
    } catch(e) {
        console.error(`Couldn't find ${path} in cloud storage:`, e)
        return undefined
    }
}

export async function uploadFile(path: string, file, updateProgress: (p: number) => void) {
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred * 100) / snapshot.totalBytes)
                updateProgress(progress)
            },
            error => {
                reject(error)
            },
            () => {
                resolve(uploadTask.snapshot)
            }
        )
    })
}



// const handleUpload = async () => {

//     try {
//         // Create a storage reference
//         const storageRef = ref(storage, `uploads/${Date.now()}-${file.name}`);

//         // Create upload task
//         const uploadTask = uploadBytesResumable(storageRef, file);

//         // Monitor upload progress
//         uploadTask.on(
//             'state_changed',
//             (snapshot) => {
//                 const progress = Math.round(
//                     (snapshot.bytesTransferred * 100) / snapshot.totalBytes
//                 );
//                 setUploadProgress(progress);

//                 switch (snapshot.state) {
//                     case 'paused':
//                         setUploadStatus('Upload paused');
//                         break;
//                     case 'running':
//                         setUploadStatus('Upload in progress...');
//                         break;
//                     default:
//                         break;
//                 }
//             },
//             (error) => {
//                 console.error('Upload error:', error);
//                 setUploadStatus(`Upload failed: ${error.message}`);
//             },
//             async () => {
//                 // Upload completed successfully
//                 const url = await getDownloadURL(uploadTask.snapshot.ref);
//                 setDownloadURL(url);
//                 setUploadStatus('Upload completed successfully!');
//             }
//         );
//     } catch (error) {
//         console.error('Error starting upload:', error);
//         setUploadStatus(`Upload failed: ${error.message}`);
//     }
// }