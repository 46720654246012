import { Box, Link, Button, Card, CardBody, CardFooter, CardHeader, Center, Heading, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Text, Tooltip, useColorModeValue, UnorderedList, ListItem } from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { makeCourseId } from "../../lib/database";
import { Course } from "../../types/types";
import { capitalizeWords } from "../../lib/text_utils";
import { AddIcon } from "@chakra-ui/icons";
import { COURSE_ID_TO_FULL_NAME } from "../../lib/CONSTANTS";


export default function CourseCard({ id, title, year, semester, institution, isAdmin, isBootedFromCourse }: Course & { isAdmin: boolean, isBootedFromCourse: boolean }) {

    const bgHover = useColorModeValue('slate.200', 'slate.600')
    const navigate = useNavigate()

    return (
        <Card
            onClick={() => navigate(`/app/${id}`)}
            background={useColorModeValue('slate.100', 'slate.800')}
            borderColor={useColorModeValue('slate.200', 'slate.700')}
            cursor='pointer'
            variant={'outline'}
            position={'relative'}
            boxShadow={'none'}
            _hover={{ bg: bgHover }}
            borderWidth="0px"
        >
            <CardHeader>
                <Heading size={'md'}>{title}</Heading>
                <Heading size='sm' mt='1' fontWeight={400} color={'slate.500'}>{COURSE_ID_TO_FULL_NAME[id]}</Heading>
            </CardHeader>

            {/* <CardFooter display='flex' flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Link to={!isBootedFromCourse && `/app/${id}`}>
                    <Tooltip 
                        label={isBootedFromCourse ? `You have been booted from ${title}. Please contact your instructor if you belive this is a mistake.` : ''} 
                        hasArrow
                    >
                        <Button 
                            disabled={isBootedFromCourse} 
                            colorScheme={isBootedFromCourse ? 'slate' : 'primary'}
                            color={'white'}
                            cursor={isBootedFromCourse ? 'not-allowed' : 'cursor'}
                            fontWeight={'400'}
                        >
                                Enter
                        </Button>
                    </Tooltip>
                </Link>
                { isAdmin && <Link to={`/app/${id}/admin`}>
                    <Button variant='ghost' fontWeight='400' _hover={{ bg: adminBgHover }} colorScheme={'gray'}>Admin</Button>
                </Link> }
            </CardFooter> */}
        </Card>
    )
}

export function AddCourseCard({ studentCourses }) {

    const borderColor = useColorModeValue('slate.200', 'slate.700')
    const hoverBg = useColorModeValue('slate.100', 'slate.800')
    const popoverHeaderBg = useColorModeValue('slate.100', 'slate.700')

    const coursesStudentIsNotIn = Object.entries(COURSE_ID_TO_FULL_NAME).filter(([id, _]) => !studentCourses.includes(id))

    if (coursesStudentIsNotIn.length == 0) return

    return (
        <Popover>
            <PopoverTrigger>
                <Center
                    py='6'
                    borderRadius={'0'}
                    cursor={'pointer'}
                    borderWidth={'0px'}
                    borderStyle={'dashed'}
                    borderColor={borderColor}
                    _hover={{ bg: hoverBg }}
                    flexDir={'column'}
                    flexGrow={1}
                >
                    <AddIcon />
                    <Text mt='2'>Add a Course</Text>
                </Center>
            </PopoverTrigger>
            <PopoverContent w='100%' ring={'none'}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader background={popoverHeaderBg}>All Courses</PopoverHeader>
                <PopoverBody>
                    <Text fontStyle={'italic'} mb='3'>You will only be able to join classes that you are a student in.</Text>
                    <UnorderedList spacing={1}>
                        {coursesStudentIsNotIn.map(([courseId, title], i) =>
                            <ListItem key={i}>
                                <Link display={'block'} to={`/join/${courseId}`} as={ReactRouterLink}>
                                    Brown University {title}
                                </Link>
                            </ListItem>
                        )}
                    </UnorderedList>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}