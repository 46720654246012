// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBsEO-NZf0P8MN4CX-Fc9NbTW7HIsIw_6k",
  authDomain: "artificial-teaching-assistant.firebaseapp.com",
  projectId: "artificial-teaching-assistant",
  storageBucket: "gs://artificial-teaching-assistant.appspot.com",
  messagingSenderId: "511573966113",
  appId: "1:511573966113:web:4cf9d37d3012150c5b5260",
  measurementId: "G-1P1R9LQZRZ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const db = initializeFirestore(app, {})
export const analytics = getAnalytics(app);
export const storage = getStorage(app)