import React, { useEffect, useRef, useState } from 'react'

import { VStack, Image, Divider, useColorModeValue, Text, Box, Tag, Flex, Heading, CloseButton, Tooltip, HStack } from '@chakra-ui/react'

import { getURLForPage } from '../../lib/storage'
import { Exchange, Source } from "../../types/types";
import useList from "../../hooks/useList";
import { capitalizeWords } from '../../lib/text_utils';

const supportedTypes = ['slides', 'handout', 'transcript']

// const REFERENCE_ID_REGEX = /(\[page: '.+?'\])/g
export const REFERENCE_ID_REGEX = /(\<ReferenceID>.+?<\/ReferenceID>)/g

const parseReferenceTag = (resourceTag: string) => {
    const tokens = resourceTag.split("-")
    return {
        resourceClass: tokens[0],
        resourceName: tokens[1],
        resourceType: tokens[2],
        file: tokens[3],
        page: parseInt(tokens[tokens.length - 1])
    }
}

type ResourceReferences = {
    ids: string[], 
    urls: string[], 
    resourceClass: string, 
    resourceName: string, 
    resourceType: string,
    file: string
}

function getFullSourceList(urlBatches: string[][], idBatches: string[][]) {
    const classNameTypeFileToReferences: { [key: string]: { id: string, url: string }[] } = {}
    for (let i = 0; i < Math.min(urlBatches.length, idBatches.length); i++) {
        for (let j = 0; j < urlBatches[i].length; j++) {
            const { resourceClass, resourceName, resourceType, file, page } = parseReferenceTag(idBatches[i][j])

            const key = `${resourceClass}-${resourceName}-${resourceType}-${file}`
            if(!Object.keys(classNameTypeFileToReferences).includes(key)){
                classNameTypeFileToReferences[key] = [{ id: idBatches[i][j], url: urlBatches[i][j] }]
            }else{
                const sourceInList = classNameTypeFileToReferences[key].some(({ id }) => id == idBatches[i][j])
                if(!sourceInList){
                    classNameTypeFileToReferences[key].push({ id: idBatches[i][j], url: urlBatches[i][j] })
                }
            }
        }
    }

    const resourceReferences: ResourceReferences[] = []
    for(const [classNameTypeFile, references] of Object.entries(classNameTypeFileToReferences)){
        const [resourceClass, resourceName, resourceType, file] = classNameTypeFile.split("-")
        references.sort(({ id: id1 }, { id: id2 }) => parseReferenceTag(id1).page - parseReferenceTag(id2).page)
        resourceReferences.push({
            resourceClass, resourceName, resourceType, file,
            ids: references.map(({ id }) => id),
            urls: references.map(({ url }) => url)
        })
    }

    return resourceReferences
}


export default function SourcesPane({ title, responses, display, setAreSourcesToShow, courseId, onClose, focusSourceId, styleProps = {} }: { title: string, display: boolean, onClose: () => void, focusSourceId: string, responses: Exchange[], setAreSourcesToShow: (v: boolean) => void, courseId: string, styleProps?: object }) {

    const [responsesProcessed, setResponsesProcessed] = useState(0)
    const sourcesTrayRef = useRef(null)

    const [
        urlBatches,
        addURLBatch,
        updateURLBatch,
        clearURLBatches,
        setURLBatches,
    ] = useList<string[]>([])

    const [
        idBatches,
        addIdBatch,
        updateIdBatch,
        clearIdBatches,
        setIdBatches,
    ] = useList<string[]>([]);

    const combSourcesForPages = async (sources) => {
        const tags = []
        for (const [sourceType, _sources] of Object.entries(sources as { [key: string]: Source[] })) {
            for (const s of _sources) {
                if (supportedTypes.includes(sourceType)) {
                    const lines = s.data.split("\n")
                    for (const l of lines) {
                        if (l.startsWith('[page:')) tags.push(l.split("[page: '")[1].split("'")[0])

                        if (l.startsWith('[image:')) tags.push(l.split("[image: '")[1].split("'")[0])
                    }
                }
            }
        }
        const promises = tags.map((id, i) => getURLForPage(`${courseId}/${id}.png`))
        // const promises = slidesToShow.map((id, i) => getURLForPage(`test/page_${id.split(".")[0].split("page-")[1]}.pdf`))
        const urls = await Promise.all(promises)
        addURLBatch(urls)
        addIdBatch(tags)
    }

    const paneBorderColor = useColorModeValue('slate.200', 'slate.700')
    const paneBg = useColorModeValue('white', 'slate.800')
    const sourcesTrayBg = useColorModeValue('slate.100', 'slate.700')

    const combResponseTextForSources = async (responseText) => {
        const tags = []
        const parts = responseText.split(REFERENCE_ID_REGEX)
        for (let i = 0; i < parts.length; i++) {
            if (i % 2 == 1) {
                const id = parts[i].split(">")[1].split("<")[0]
                tags.push(id)
            }
        }

        const promises = tags.map((id, i) => getURLForPage(`${courseId}/${id}.png`))
        const urls = await Promise.all(promises)
        addURLBatch(urls)
        addIdBatch(tags)
    }

    // check responseTexts for retrieved slides
    useEffect(() => {
        if (!responses || !responses[responses.length - 1]?.responseText) return

        let lastFullResponse = 0
        for (let i = responsesProcessed; i < responses.length; i++) {
            // combSourcesForPages(responses[responses.length - 1].sources)
            combResponseTextForSources(responses[i].responseText)
            if(Object.keys(responses[i]).includes('log')) lastFullResponse = i + 1
        }
        setResponsesProcessed(lastFullResponse)

    }, [responses])

    useEffect(() => {
        let n = 0
        for (const b of urlBatches) n += b.length

        if (n > 0) setAreSourcesToShow(true)
    }, [urlBatches])

    // scroll to source when its reference tag is clicked
    useEffect(() => {
        const resourceReferences = getFullSourceList(urlBatches, idBatches)
        if (!focusSourceId || !sourcesTrayRef.current || resourceReferences.length == 0 || !display) return

        let resourceIndex = -1
        let sourceIndex = -1

        for(let i=0; i < resourceReferences.length; i++){
            sourceIndex = resourceReferences[i].ids.indexOf(focusSourceId)
            if(sourceIndex !== -1){
                resourceIndex = i
                break
            }
        }

        if(resourceIndex >= 0){
            const focusResource =  sourcesTrayRef.current.children[resourceIndex]
            const focusSourceElement = focusResource.children[sourceIndex+1] as Element
            focusSourceElement.scrollIntoView({ behavior: 'smooth' })
        }else{
            console.error("Couldn't find the source clicked. [resourceReferences, focusSourceId]", resourceReferences, focusSourceId)
        }
    }, [focusSourceId, display])

    let n = 0
    for (const b of urlBatches) n += b.length

    const dividerBorderColor = useColorModeValue('slate.200', 'slate.500')
    const sourceGroupTitleColor = useColorModeValue('slate.600', 'slate.300')


    if (n == 0 || !display) return

    const resourceReferences = getFullSourceList(urlBatches, idBatches)

    console.log(resourceReferences)

    return (
        <Flex
            borderRadius={'6'}
            bg={paneBg}
            borderWidth={'1px'}
            borderColor={paneBorderColor}
            // boxShadow={'md'}
            h='calc(100% - 1.5rem)'
            flexDir={'column'}
            mb={[0,null,null,'3']}
            overflow={'hidden'}
        >
            <Flex px='3' py='1.5' mb='3' borderBottomWidth={'1px'} borderColor={paneBorderColor} alignItems={'center'} justifyContent={'space-between'}>
                <Heading fontSize={'lg'} fontWeight={'400'}>Slides</Heading>
                <Tooltip label="Hide Sources">
                    <CloseButton onClick={onClose} />
                </Tooltip>
            </Flex>
            <VStack
                gap='12'
                mx='3'
                h='100%'
                borderRadius={'6'}
                mt='1.5'
                mb='3'
                overflowY={"auto"}
                {...styleProps}
                p={['2',null,null,'6']}
                bg={sourcesTrayBg}
                ref={sourcesTrayRef}
                alignItems={'stretch'}
            >
                {
                    resourceReferences.map((r, i) => <VStack key={i} gap='8'>
                        <HStack w='full' alignItems={'center'}>
                            <Divider borderColor='slate.300' flex='1' />
                            <Text px='3' color={sourceGroupTitleColor} fontWeight={'700'} textAlign={'center'} whiteSpace={'nowrap'}>{r.file}</Text>
                            <Divider borderColor='slate.300' flex='1' />
                        </HStack>
                        { r.urls.map((url, j) => <SourceDisplay src={url} id={r.ids[j]} key={`${i}-${j}`} />) }
                    </VStack>)
                }
            </VStack>
        </Flex>
    )
}


function SourceDisplay({ src, id }: { src: string, id: string }) {
    if (!src) return

    let { resourceClass, resourceName, resourceType, page } = parseReferenceTag(id)

    // const [resourceClass, resourceName, sourceType, number] = parseInfoFromSourceId(id)

    if(resourceType == 'slides') resourceType = 'slide'

    return (
        <Box position={'relative'} pt='3'>
            <Tag
                position={'absolute'}
                variant={'subtle'}
                left='50%'
                top='0'
                bg='primary.500'
                transform={'translate(-50%,0)'}
                color='white'
            >
                {capitalizeWords(resourceType)} {page}
            </Tag>
            <Image
                src={src}
                width='100%'
                height='auto'
                borderWidth={2}
                borderColor={'slate.200'}
            />
        </Box>
    )
}

export function parseInfoFromSourceId(id: string) {
    const tokens = id.split("-")
    const resourceClass = tokens[0]
    const resourceName = tokens[1]
    const sourceType = tokens[tokens.length - 2]

    const number = id.split(`${sourceType}-`)[1]
    return [resourceClass, resourceName, sourceType, number]
}