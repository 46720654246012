import { SearchIcon } from "@chakra-ui/icons"
import { Box, Flex, Heading, InputGroup, Text, InputLeftElement, Input, VStack, useColorModeValue, useToast, Center, Spinner } from "@chakra-ui/react"
import Fuse from "fuse.js"
import { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useParams } from "react-router-dom"
import useSingleCourse from "../../hooks/useSingleCourse"
import useUserCourseDoc from "../../hooks/useUserCourseDoc"
import { Conversation, Course, UserCourseDoc } from "../../types/types"
import { onArchiveConversation } from "../../lib/database"
import { auth } from "../../lib/firebase"
import { ConversationCard, SimpleConversationCard } from "./ConversationCard"
import { compareDates } from "../../lib/date_utils"
import { SearchInput } from "../resources"


export default function Chats() {

    const params = useParams()
    const toast = useToast()
    const [user, userLoading, userError] = useAuthState(auth)
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)
    const [userCourseData, isUserCourseData] = useUserCourseDoc(user.uid, params.courseId, isCourseLoading ? undefined : course.controls.quota.maxQueriesAccumulated)
    const [searchQuery, setSearchQuery] = useState("")

    const sortedConversations = []
    if (isUserCourseData) sortedConversations.push(...Object.entries(userCourseData.conversations).map(([id, data], i) => ({ id, ...data })))
    sortedConversations.sort((a, b) => compareDates(a.timestampLastUpdated.toDate(), b.timestampLastUpdated.toDate()) ? -1 : 1)

    const noConversationsTextColor = useColorModeValue('slate.600', 'slate.500')

    const conversationsFuse = new Fuse(sortedConversations, { keys: ["title", "resourceClass", "resourceName"] })

    const conversationsToShow = searchQuery.length > 0 ? conversationsFuse.search(searchQuery).map(({ item }, i) => item) : sortedConversations

    const handleArchiveConversation = (convoId: string) => {
        const convoName = userCourseData.conversations[convoId].title
        onArchiveConversation(convoId, params.courseId, user.uid)
        toast({
            title: "Success!",
            description: `Deleted Conversation "${convoName}"`,
            position: "top",
            status: "success",
            duration: 4000,
            isClosable: true,
        })
    }

    return (
        <Box w='full'>
            <Flex mb='3' alignItems={'center'} justifyContent={'space-between'}>
                <Heading size='lg'>Your Chat History</Heading>
            </Flex>

            <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} mb='6' />

            {isUserCourseData ? <VStack
                gap='3'
                alignItems={'stretch'}
            >
                {conversationsToShow.map((convo, i) => !convo?.isArchived && <ConversationCard archiveConversation={handleArchiveConversation} courseId={params.courseId} {...convo} key={i} />)}
            
                { sortedConversations.length == 0 && <Text py='16' color={noConversationsTextColor} textAlign={'center'}>You have had 0 conversations with ATA.</Text> }
            </VStack> : <Center pt='32'><Spinner /></Center>}
        </Box>
    )
}

export function ResourceChats({conversationData, resourceName, k=3} : {conversationData: UserCourseDoc["conversations"], resourceName: string, k?: number}) {
    const params = useParams()

    const sortedConversations = Object.entries(conversationData).map(([id, data], i) => ({ id, ...data })) as Conversation[]
    sortedConversations.sort((a, b) => compareDates(a.timestampLastUpdated.toDate(), b.timestampLastUpdated.toDate()) ? -1 : 1)

    const conversationsToShow = sortedConversations.filter(({resourceName: rName }) => rName == resourceName).slice(0, k)

    return (
        <Box maxW={'3xl'} w='full'>
            <VStack
                gap='3'
                alignItems={'stretch'}
            >
                {conversationsToShow.map((convo, i) => !convo?.isArchived && <SimpleConversationCard courseId={params.courseId} {...convo} key={i}/>)}
            </VStack>
        </Box>
    )
}