import { useEffect, useState } from "react";
import { DaySummary } from "../types/types";
import useList from "./useList";
import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import { db } from "../lib/firebase";
import { splitArrayIntoChunks } from "../lib/array_utils";


export type DaySummaryPlus = DaySummary & { exists: boolean, id: string }

export default function useDaySummaries(courseId: string, days: string[]){
    const [summaries, addSummary, updateSummaryAt, clearSummaries, setSummaries] = useList<DaySummaryPlus>([])
    const [isLoading, setIsLoading] = useState(true)

    const fetchNewDays = async () => {
        setIsLoading(true)
        const retrievedDays = summaries.map((s, _) => s.id)
        const unretrievedDays = days.filter((day, _) => !retrievedDays.includes(day))
    
        if(unretrievedDays.length == 0){
            setIsLoading(false)
            return
        }

        // * firestore only supports 'in' queries for up to 30 items, so we have to chunk
        const dayChunks = splitArrayIntoChunks(unretrievedDays, 30)
        const promises = dayChunks.map(chunk => getDocs(query(collection(db, 'Courses', courseId, 'DaySummaries'), where(documentId(), 'in', chunk))))

        const chunkResults = await Promise.all(promises)
        const docs = chunkResults.map(r => r.docs).flat()

        for(const doc of docs){
            const newDay = { ...doc.data(), id: doc.id, exists: true } as DaySummaryPlus
            addSummary(newDay)
            unretrievedDays.splice(unretrievedDays.indexOf(doc.id), 1)
        }
        setIsLoading(false)

        for(const id of unretrievedDays){
            addSummary({ id: id, exists: false } as DaySummaryPlus)
        }
    }

    useEffect(() => {
        if(days.length == 0) return
        fetchNewDays()
    }, [days])

    const relevantDays = summaries.filter((d, i) => days.includes(d.id))

    const summariesMap = Object.fromEntries(relevantDays.map((d, i) => ([d.id, {...d}])))

    return [summariesMap, isLoading] as [{ [key: string]: DaySummaryPlus }, boolean]
}