import { Heading, Box, Center, Spinner, Tag, Divider, Text, Card, useColorModeValue, VStack, Flex, Button } from "@chakra-ui/react";
import useSingleCourse from "../../../hooks/useSingleCourse";
import { Link, useParams } from "react-router-dom";
import { RCLASS_TO_COLOR } from "../../../lib/CONSTANTS";
import { getResourceListFromExtraCourseData } from "../../resources";
import { ExtraCourseData, ResourceMetadata } from "../../../types/types";

// load resources

// show every resource as card

// button for ingesting new resource

export default function ResourceManager(){
    const params = useParams()
    const [course, isCourseLoading, extraCourseData] = useSingleCourse(params.courseId, true)
 
    if(isCourseLoading) return <Center py='32'><Spinner /></Center>

    const resources = getResourceListFromExtraCourseData(extraCourseData as ExtraCourseData)

    return (
        <Box>
            <Flex alignItems='center' justifyContent='space-between'>
                <Heading mb='12'>Resource Manager</Heading>
                <Button as={Link} to={`/app/${params.courseId}/admin/resources/new`} colorScheme={'primary'} fontWeight='400'>Add Resource</Button>
            </Flex>

            <VStack alignItems={'stretch'}>
                { resources.map((r, i) => <ResourceEntry {...r} key={i} />) }
            </VStack>

        </Box>
    )

}

function ResourceEntry({ title, releaseDate }: ResourceMetadata){
    return (
        <Card
            // w='100%'
            transition="transform 0.2s"
            boxShadow={'none'}
            borderWidth={2}
            borderColor={useColorModeValue('slate.200', 'slate.700')}
            backgroundColor={useColorModeValue('slate.50', 'slate.800')}
            p='3'
            display={'flex'}
            alignItems={'center'}
        >
            <Heading size='sm' fontWeight={'400'}>{ title }</Heading>
        </Card>
    )
}