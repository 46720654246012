import { Box, Center, Divider, Flex, Heading, Img, SimpleGrid, Spinner, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useFirebaseRoutesAnalytics } from "../../hooks/useFirebaseRoutesAnalytics";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../../hooks/useUserDoc";
import { auth, } from "../../lib/firebase";
import useCourses from "../../hooks/useCourses";
import CourseCard, { AddCourseCard } from "./CourseCard";
import { Course, Semester } from "../../types/types";

export default function Dashboard({ }) {
    useFirebaseRoutesAnalytics()
    
    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)
    
    const [courses, coursesAreLoading] = useCourses(userDoc.courses)
    
    const brownLogoSrc = useColorModeValue('/brown_logo.png', '/brown_logo_white_text.png')

    const compileCoursesIntoSemesterYears = (coursesToCompile: Course[]) => {
        const semesterYears = []
        const semesterCourses = []
        for (const c of coursesToCompile) {
            const semesterYear = [c.semester, c.year] as [Semester, number]
            let i = 0
            let insert = true
            while (i < semesterYears.length) {
                if (semesterYears[i][0] == semesterYear[0] && semesterYears[i][1] == semesterYear[1]) {
                    semesterCourses[i].push(c)
                    insert = false
                    break
                }
                if (compareSemesterYears(semesterYear, semesterYears[i])) break
                i += 1
            }
            if (insert) {
                semesterYears.splice(i, 0, semesterYear)
                semesterCourses.splice(i, 0, [c])
            }
        }

        semesterYears.reverse()
        semesterCourses.reverse()

        return [semesterYears, semesterCourses]
    }


    const [semesterYears, semesterCourses] = compileCoursesIntoSemesterYears(courses)


    if (coursesAreLoading) {
        return <Center h='full'><Spinner /></Center>
    }

    const currentSemesterYear = ['Fall', 2024]
    if (semesterYears.length == 0 || semesterYears[0][0] != currentSemesterYear[0] || semesterYears[0][1] != currentSemesterYear[1]) {
        semesterYears.splice(0, 0, currentSemesterYear)
        semesterCourses.splice(0, 0, [])
    }

    return (
        <Box maxW='7xl' m='auto' p={[4, 4, 16]}>
            <Flex alignItems={'center'} justifyContent={'space-between'}>
                <Heading size='xl' mb='12'>Your Courses</Heading>
            </Flex>
            <VStack gap={'8'} alignItems={'stretch'}>
                {
                    (isUserDoc && semesterYears.length > 0) &&
                    Array(semesterYears.length).fill(0).map((_, i) => <Box flexGrow={1} key={i}>
                        <Heading mb='3' size='md'>{semesterYears[i][0]} {semesterYears[i][1]}</Heading>
                        <CoursesGrid isCurrentSemester={i == 0} courses={semesterCourses[i]} isUserDoc={isUserDoc} userDoc={userDoc} />
                    </Box>)
                }
            </VStack>

            {(!coursesAreLoading && courses.length == 0) && <Heading size="sm">Looks like you're not in any classes—Ask your HTAs for the join link!</Heading>}
        </Box>
    )
}

// returns true if semesterYearOne < semesterYearTwo and false otherwise
function compareSemesterYears(semesterYearOne: [Semester, number], semesterYearTwo: [Semester, number]) {
    const [semesterOne, yearOne] = semesterYearOne
    const [semesterTwo, yearTwo] = semesterYearTwo

    if (yearOne < yearTwo) return true

    if (yearOne > yearTwo) return false

    // same year
    if (semesterOne == 'Spring') return true
    if (semesterTwo == 'Spring') return false
    if (semesterOne == 'Summer') return true
    if (semesterTwo == 'Summer') return false

    // should never reach here
    throw Error(`compareSemesterYears was given invalid input: ${semesterYearOne} ${semesterYearTwo}`)
}

function CoursesGrid({ courses, isUserDoc, userDoc, isCurrentSemester }) {
    return (
        <SimpleGrid alignItems={'stretch'} flexGrow={'1'} columns={[1, 1, 1, 2, 3]} spacing={8}>
            {/* {
                isUserDoc ?
                    courses.map((course, i) => <CourseCard
                        isBootedFromCourse={userDoc?.bootedCourses.includes(course.id)}
                        {...course}
                        isAdmin={userDoc.adminCourses.includes(course.id)}
                        key={i}
                    />)
                    : <>
                        <Skeleton height={120} />
                        <Skeleton height={120} />
                        <Skeleton height={120} />
                    </>
            } */}
            {
                courses.map((course, i) => <CourseCard
                    isBootedFromCourse={userDoc?.bootedCourses.includes(course.id)}
                    {...course}
                    isAdmin={userDoc.adminCourses.includes(course.id)}
                    key={i}
                />)
            }

            {/* {isCurrentSemester && <AddCourseCard studentCourses={isUserDoc ? userDoc.courses : []} />} */}
        </SimpleGrid>
    )
}