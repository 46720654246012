import { Box, Card, Flex, Heading, VStack, Text, CardBody, Slider, SliderFilledTrack, SliderThumb, SliderTrack, SliderMark, Tag, Tooltip } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { DocumentReference, Timestamp } from "firebase/firestore";
import { compareDates, getRelativeTimeString } from '../../lib/date_utils'
import useTestSuites from './useTestSuites';
import useRunConversations from './useRunConversations';
import Pair from '../tutor/Pair';
import { Conversation } from '../../types/types';
import { ConvoAdminCard } from '../tutor';


export type TestSuite = {
    courseId: string
    id: string
    hash: string
    versions: Version[]
}

export type Version = {
    description: string
    timestamp: Timestamp
    conversations: DocumentReference[]
}

export type TestConversation = Conversation & { testInfo: { persona: string, starter: { resource_class: string, resource_name: string, starter: string } } }

// const suites: TestSuite[] = [
//     {
//         courseId: 'brown-university-csci0150-fall-2024',
//         title: 'Minimal suite',
//         versions: [{ description: 'v1', timestamp: Timestamp.now(), conversations: [] }, { description: 'v2', timestamp: Timestamp.now(), conversations: [doc(db, 'Courses', 'brown-university-csci0150-fall-2024', 'Conversations', '3c527b8ef7aaa8e2935172fa735f04d7a16d3335')] }]
//     },
//     {
//         courseId: 'brown-university-chem0330-fall-2024',
//         title: 'chem minimal suite',
//         versions: [{ description: 'boop', timestamp: Timestamp.now(), conversations: [] }, { description: 'foo', timestamp: Timestamp.now(), conversations: [] }]
//     }
// ]


export default function TestPage({ }) {
    const [selectedSuite, setSelectedSuite] = useState(0)
    const [selectedVersion, setSelectedVersion] = useState(0)
    const [selectedConversation, setSelectedConversation] = useState(0)

    const suites = useTestSuites()
    
    let versionList = []
    let version: Version | undefined = undefined
    if(suites != undefined){
        versionList = suites[selectedSuite].versions
        version = versionList[selectedVersion]
    }

    versionList.sort((a, b) => compareDates(a.timestamp, b.timestamp, true) ? 1 : -1)
        
    const conversations = useRunConversations(suites ? suites[selectedSuite].hash : undefined, version?.conversations)
    
    useEffect(() => {
        setSelectedVersion(0)
    }, [selectedSuite])

    useEffect(() => {
        setSelectedConversation(0)
    }, [selectedVersion])

    let conversation: Conversation = {
        id: undefined,
        isArchived: undefined,
        isTerminated: undefined,
        lastTutorEngineVersion: undefined,
        maliciousQueries: undefined,
        offTopicQueries: undefined,
        resourceClass: undefined,
        resourceName: undefined,
        numResponses: undefined,
        feedback: undefined,
        hasFeedback: false,
        responses: [],
        timestampCreated: undefined,
        timestampLastUpdated: undefined,
        title: undefined,
        tutorState: undefined,
        userDocRef: undefined,
        userName: undefined
    }
    if (conversations != undefined && conversations.length > 0) conversation = conversations[selectedConversation]

    let tutorState = undefined
    if(conversations != undefined && conversations.length > 0 && Object.keys(conversations[selectedConversation]).includes('tutorState')) tutorState = conversations[selectedConversation].tutorState

    return (
        <Flex justifyContent={'space-between'} bg='slate.50'>
            <VStack
                h='calc(100vh)'
                overflowY={'scroll'}
                gap={6}
                p='3'
                bg='slate.50'
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Heading size='md' mt='3'>Suites</Heading>
                {suites && suites.map(({ courseId, id, versions }, i) => <Card bg={selectedSuite == i ? 'slate.200' : 'white'} _hover={{ bg: 'slate.200' }} cursor='pointer' onClick={() => setSelectedSuite(i)} key={i}><CardBody><Heading size='md'>{id}</Heading> <Text>{courseId}</Text></CardBody></Card>)}
            </VStack>

            <VStack
                h='calc(100vh)'
                overflowY={'scroll'}
                gap={6}
                p='3'
                bg='slate.50'
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Heading size='md' mt='3'>Versions</Heading>
                {versionList.map(({ description, timestamp, conversations }, i) => <Card cursor='pointer' onClick={() => setSelectedVersion(i)} bg={selectedVersion == i ? 'slate.200' : 'white'} _hover={{ bg: 'slate.200' }}><CardBody><Heading size='md'>{description}</Heading> <Text mt='2'>{getRelativeTimeString(timestamp, true)}</Text></CardBody></Card>)}
            </VStack>

            <VStack
                h='calc(100vh)'
                overflowY={'scroll'}
                gap={6}
                p='3'
                bg='slate.50'
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Heading size='md' mt='3'>Conversations</Heading>
                { conversations && conversations.map(({ title, responses, testInfo }, i) => 
                    <Card cursor='pointer' onClick={() => setSelectedConversation(i)} bg={selectedConversation==i ? 'slate.200' : 'white'} _hover={{bg: 'slate.200'}}>
                        <CardBody>
                            <Tooltip label={testInfo.persona}>
                                <Tag colorScheme={'primary'}>
                                    {testInfo.persona.split(" ")[0]}
                                </Tag>
                            </Tooltip>
                            <Heading size='md'>{title}</Heading> 
                        </CardBody>
                    </Card>)  }
            </VStack>

            <VStack
                h='calc(100vh)'
                overflowY={'scroll'}
                gap={6}
                px='3'
                py='16'
                bg='slate.50'
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                maxW='4xl'
            >
                { tutorState && <ConvoAdminCard lastTutorEngineVersion={conversation?.lastTutorEngineVersion} {...tutorState} />}

                {conversation?.responses.map((response, i) =>
                <VStack key={i} gap='6'>
                    {/* <Heading size='md' textAlign={'center'}>Exchange #{i+1}</Heading> */}
                    <Pair
                        loadingMessage=""
                        tutorResponseRef={null}
                        onCopyResponseLink={undefined}
                        onReferenceTagClick={console.log}
                        isShare={false}
                        {...response}
                        isAdmin={true}
                        onFeedback={console.log}
                    />
                </VStack>
                )
                }
            </VStack>
            {/* <VStack
                h='calc(100vh)'
                overflowY={'scroll'}
                gap={6}
                px='12'
                bg='slate.50'
                sx={{
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Heading size='md' mt='3'>Annotations</Heading>
                <ExchangeAnnotation index={1} />
                <Button w='full' fontWeight='400' colorScheme={'primary'}>Save</Button>
            </VStack> */}
        </Flex>
    )
}


function ExchangeAnnotation({ index }) {

    const labelStyles = {
        mt: '2',
        // ml: '2',
        fontSize: 'sm',
    }

    return (
        <Box>
            <Heading size='sm'>Exchange #{index}</Heading>
            <Slider minW='sm' defaultValue={3} min={1} max={5} step={1}>
                <SliderMark value={1} {...labelStyles}>
                    1
                </SliderMark>
                <SliderMark value={2} {...labelStyles}>
                    2
                </SliderMark>
                <SliderMark value={3} {...labelStyles}>
                    3
                </SliderMark>
                <SliderMark value={4} {...labelStyles}>
                    4
                </SliderMark>
                <SliderMark value={5} {...labelStyles}>
                    5
                </SliderMark>
                <SliderTrack h='2'>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb boxSize={6} borderWidth={'2'} borderColor={'slate.200'} />
            </Slider>
        </Box>
    )
}