import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";

const links = [
    { title: 'team@talktoata.com', href: 'mailto:team@talktoata.com' }
]

export default function Footer({ }){

    const linkHoverBg = useColorModeValue('slate.700', 'slate.300')
    const linkBg = useColorModeValue('slate.600', 'slate.200')

    return (
        <Box bg={useColorModeValue('slate.100', 'slate.800')}>
            <Flex maxW='6xl' m='auto' p='3' justifyContent={'space-between'}>
                <Text textAlign={'center'} color={linkBg}>
                    © {new Date().getFullYear()} ATA
                </Text>
                <Flex gap={6}>
                    { links.map(({title, href}, i) => <a href={href} key={i}><Text _hover={{ color: linkHoverBg }} color={linkBg}>{title}</Text></a>) }
                </Flex>
            </Flex>
        </Box>
    )
}