import { Box, Flex, Heading, HStack, Icon, Link, Spinner, Tag, Text } from "@chakra-ui/react";
import useCourseConversations from "../../../hooks/useCourseConversations";
import { ALLTIME_ANNOTATION_ISSUES, ANNOTATION_ISSUES, ATA_FEEDBACK_ISSUES, NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../../../lib/CONSTANTS";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { compareDates, formatDateShort } from "../../../lib/date_utils";
import { Conversation } from "../../../types/types";
import TableWithSorting, { Column, compareStrings, compareNumbers, compareTimestamps } from "../users/TableWithSorting";
import MultiToggle from "../../../ui/MultiToggle";
import { useState } from "react";
import average from "../../../lib/number_utils";

export default function Annotations() {
    const params = useParams()

    const [issueFilter, setIssueFilter] = useState('all')
    

    const [convos, totalConversationCount, isLoadingConversations, fetchMoreConvos] = useCourseConversations(params.courseId, NUM_ADMIN_CONVERSATIONS_TO_FETCH, true)

    const filteredConversations = convos.filter(c => issueFilter == 'all' || unionIssues(c.annotations).includes(issueFilter))

    return (
        <Box>
            <Heading mb='3'>Annotations</Heading>

            <Box mb='6'>
                <Text fontWeight={'700'}>Filter</Text>
                <MultiToggle
                    allowMultipleOn={false}
                    items={["all", ...ALLTIME_ANNOTATION_ISSUES]}
                    onChange={(arr) => setIssueFilter(arr[0])}
                    small
                />
            </Box>

            <AnnotationsTable
                courseId={params.courseId}
                conversations={filteredConversations}
            />
            <Flex mt='3' flexDir={'row'} justifyContent={'center'} alignItems={'center'} gap={3}>
                <Text size='lg'>Showing {convos.length} / {totalConversationCount} conversations. </Text>
                {(convos.length < totalConversationCount && !isLoadingConversations) && <Text onClick={fetchMoreConvos} cursor={'pointer'} fontWeight={400} textDecoration={'underline'} _hover={{ textDecoration: 'none' }} variant={'link'}>Fetch more conversations</Text>}
                {isLoadingConversations && <Spinner />}
            </Flex>
        </Box>
    )
}

function AnnotationsTable({ courseId, conversations }) {
    const columns: Column<Conversation>[] = [
        {
            title: 'Conversation',
            isNumeric: false,
            compareFunc: (c1: Conversation, c2: Conversation) => compareStrings(c1.title, c2.title),
            accessor: ({ title, id }) => <Link as={ReactRouterLink} gap='2' display={'flex'} alignItems={'center'} to={`/app/${courseId}/admin/annotations/${id}`}>{title}<Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400' /></Link>
        },
        {
            title: 'Issues',
            isNumeric: false,
            // accessor: ({ annotations }) => <Text fontSize={'sm'} whiteSpace="normal" wordBreak="break-word">{unionIssues(annotations).join(", ")}</Text>,
            accessor: ({ annotations }) => <HStack flexWrap={'wrap'}>{unionIssues(annotations).map((issue, i) => <Tag colorScheme={'primary'} key={i}>{issue}</Tag>)}</HStack>,
            compareFunc: ({ annotations: annotations1 }, { annotations: annotations2 }) => compareNumbers(unionIssues(annotations1).length, unionIssues(annotations2).length)
        },
        {
            title: 'Avg. Response Rating',
            accessor: ({ annotations }) => averageResponseHelpfulness(annotations),
            compareFunc: ({ annotations: annotations1 }, { annotations: annotations2 }) => compareNumbers(averageResponseHelpfulness(annotations1), averageResponseHelpfulness(annotations2)),
            isNumeric: true
        },
        {
            title: 'Date Last Annotated',
            accessor: ({ annotations }) => formatDateShort(dateLastAnnotated(annotations)),
            isNumeric: false,
            compareFunc: ({ annotations: annotations1 }, { annotations: annotations2 }) => compareDates(dateLastAnnotated(annotations1), dateLastAnnotated(annotations2)) ? 1 : -1
        },
        {
            title: 'Annotators',
            accessor: ({ annotations }) => annotations.map(({ annotator }) => annotator.displayName).join(", "),
            compareFunc: ({ annotations: annotations1 }, { annotations: annotations2 }) => compareNumbers(annotations1.length, annotations2.length),
            isNumeric: false
        },
        {
            title: '# Exchanges',
            isNumeric: true,
            compareFunc: (c1, c2) => compareNumbers(c1.numResponses, c2.numResponses),
            accessor: (c) => c.numResponses
        },
    ]

    return (
        <TableWithSorting
            columns={columns}
            items={conversations}
            name="annotations-table"
        />
    )
}

function dateLastAnnotated(as: Conversation["annotations"]) {
    let dateLastAnnotated = as[0].timestampLastEdited.toDate()
    for (const a of as) {
        if (compareDates(dateLastAnnotated, a.timestampLastEdited.toDate())) dateLastAnnotated = a.timestampLastEdited.toDate()
    }
    return dateLastAnnotated
}

function averageResponseHelpfulness(as: Conversation["annotations"]){
    let helpfulnesses: number[] = []
    for(const a of as){
        for(const an of a.responseLevelAnnotations) helpfulnesses.push(an.likert)
    }
    return Math.round(average(helpfulnesses) * 100) / 100
}

function unionIssues(as: Conversation["annotations"]) {
    let issues: string[] = []
    for (const a of as) {
        // for (const issue of a.conversationLevelAnnotation.issues) {
        //     if (!issues.includes(issue)) issues.push(issue)
        // }

        for (const an of a.responseLevelAnnotations) {
            for (const issue of an.issues) {
                if (!issues.includes(issue)) issues.push(issue)
            }
        }
    }
    return issues
}