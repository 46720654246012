import { Center, Progress, Spinner, Step, StepIcon, StepIndicator, Stepper, StepStatus, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";


export type LoadingTextItem = {
    text: string
    approximateSeconds: number
}

export default function LoadingTextSpinner({ loadingTextList, ...otherArgs }: { loadingTextList: LoadingTextItem[], [key: string]: any }) {
    const [loadingTextIndex, setLoadingTextIndex] = useState(0)
    const latestLoadingTextIndex = useRef(loadingTextIndex)

    const updateLoadingText = () => {
        if (latestLoadingTextIndex.current >= loadingTextList.length - 1) return // don't move on if on last text

        const noise = loadingTextList[loadingTextIndex + 1].approximateSeconds * Math.random() / 2
        const delay = loadingTextList[loadingTextIndex + 1].approximateSeconds + noise

        setLoadingTextIndex((index) => index + 1)
        setTimeout(updateLoadingText, delay * 1000)
    }

    // Update the ref whenever the state changes
    useEffect(() => {
        latestLoadingTextIndex.current = loadingTextIndex
    }, [loadingTextIndex])


    useEffect(() => {
        const noise = loadingTextList[0].approximateSeconds * Math.random() / 2
        const delay = loadingTextList[0].approximateSeconds + noise
        setTimeout(updateLoadingText, delay * 1000)
    }, [])

    const max = loadingTextList.length + 1
    const progressPercent = ((loadingTextIndex + 1) / max) * 100

    return (
        <Center gap='6' flexDir={'column'} {...otherArgs}>
            <Spinner />
            {/* <Progress
                colorScheme={'primary'}
                max={100}
                w='full'
                maxW='3xl'
                hasStripe
                value={progressPercent}
                sx={{
                    "& > div:first-child": {
                        transitionProperty: "width",
                    },
                }}
                borderRadius={6}
            /> */}
            <Text color={useColorModeValue('slate.700', 'slate.200')} size='xl'>{loadingTextList[loadingTextIndex].text}</Text>
        </Center>
    )
}