import { Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";
import { APP_TITLE, DESCRIPTION } from "../../lib/CONSTANTS";


export default function Master(){
    return (
        <>
            <Helmet>
                <title>{APP_TITLE}</title>
                <meta charSet="UTF-8"/>
                <meta name="description" content={DESCRIPTION}/>
                <meta name="keywords" content="ATA Brown University, AI TA, AI Teaching Assistant, LLM TA, LLM Teaching Assistant"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <meta property="og:title" content={APP_TITLE} />
                <meta property="og:description" content={DESCRIPTION} />
                <meta property="og:image" content="https://talktoata.com/ata_logo_2.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={APP_TITLE} />
                <meta name="twitter:description" content={DESCRIPTION} />
                <meta name="twitter:image" content="https://talktoata.com/ata_logo_2.png" />

            </Helmet>
            <Outlet />
        </>
    )
}