import { Box, Card, CardBody, CardFooter, CardHeader, Center, Heading, Text, SimpleGrid, Spinner, VStack, Button, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Textarea, useColorModeValue, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Switch, FormControl, FormLabel, Tag, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useSingleConversation from "../../../hooks/useSingleConversation";
import { useEffect, useRef, useState } from "react";
import Pair from "../../tutor/Pair";
import { ANNOTATION_ISSUES, ATA_FEEDBACK_ISSUES, RCLASS_TO_COLOR } from "../../../lib/CONSTANTS";
import MultiCheckbox from "../../../ui/MultiCheckbox";
import { Annotator, ResponseLevelAnnotation } from "../../../types/types";
import useList from "../../../hooks/useList";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../../../hooks/useUserDoc";
import { auth } from "../../../lib/firebase";
import { updateConversationAnnotations } from "../../../lib/database";
import useStickyState from "../../../hooks/useStickyState";
import SourcesPane from "../../tutor/SourcesPane";

const MAX_LIKERT_VALUE = 5
const DEFAULT_LIKERT_VALUE = 0
const BLANK_RESPONSE_ANNOTATION: ResponseLevelAnnotation = { comment: "", issues: [], likert: DEFAULT_LIKERT_VALUE }
const LIKERT_STEP = 1

export default function Annotation() {
    const params = useParams()

    const toast = useToast()

    const [conversation, isConversationInvalid] = useSingleConversation(params.courseId, params.conversationDocId)
    const [isViewMode, setIsViewMode] = useStickyState(true, 'isAnnotationViewMode')
    const [viewAnnotatorIndex, setViewAnnotatorIndex] = useState(0)
    const [focusSourceId, setFocusSourceId] = useState(undefined)
    const [areSourcesToShow, setAreSourcesToShow] = useState(false)

    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)

    const [hasFetchedInitialAnnotations, setHasFetchedInitialAnnotations] = useState(false)

    const responseListRef = useRef(null)

    const [responseIndexToAnnotate, setResponseIndexToAnnotate] = useState(0)
    const [responseAnnotations, addAnnotation, updateAnnotationAt, clearAnnotations, setAnnotations, removeAnnotationAt] = useList<ResponseLevelAnnotation>()

    // const [conversationAnnotation, setConversationAnnotation] = useState<ConversationLevelAnnotation>(BLANK_CONVERSATION_ANNOTATION)

    const handleNext = (a: ResponseLevelAnnotation) => {
        updateAnnotationAt(responseIndexToAnnotate, a)

        if(responseIndexToAnnotate+1 == conversation.responses.length) {
            toast({
                title: 'Annotations saved!',
                status: 'success',
                position: 'top',
                duration: 2000
            })
            return
        }

        if (responseIndexToAnnotate + 1 == responseAnnotations.length) {
            addAnnotation(BLANK_RESPONSE_ANNOTATION)
        }

        setResponseIndexToAnnotate(i => i + 1)
    }

    const saveAnnotations = () => {
        if (isUserDoc && !isConversationInvalid && conversation && responseAnnotations.length > 0 && !isViewMode) {
            const annotator: Annotator = { displayName: userDoc.displayName, uid: user.uid }
            const annotations = conversation?.annotations ?? []
            console.log("saving updated annotations")
            updateConversationAnnotations(params.courseId, params.conversationDocId, responseAnnotations, annotator, annotations)
        }
    }

    const handleBack = (a: ResponseLevelAnnotation) => {
        updateAnnotationAt(responseIndexToAnnotate, a)
        setResponseIndexToAnnotate(i => i - 1)
    }

    useEffect(() => {
        scrollToAnnotateResponse()
    }, [responseIndexToAnnotate])

    const scrollToAnnotateResponse = () => {
        if (responseListRef.current) {
            const focusResponse = responseListRef.current.children[responseIndexToAnnotate]
            focusResponse?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    useEffect(() => {
        if(isConversationInvalid || !conversation || !conversation?.annotations || hasFetchedInitialAnnotations) return

        const annotatorIndex = conversation.annotations.findIndex(({ annotator }) => annotator.uid == user.uid)
        if(annotatorIndex != -1){
            console.log("hey")
            setAnnotations(conversation.annotations[annotatorIndex].responseLevelAnnotations)
            setHasFetchedInitialAnnotations(true)
        }
    }, [conversation, isConversationInvalid])

    useEffect(() => {
        if (responseAnnotations.length == 0) addAnnotation(BLANK_RESPONSE_ANNOTATION)
    }, [])

    useEffect(saveAnnotations, [responseAnnotations])

    if (conversation === null || responseAnnotations.length == 0) return <Center py='32'><Spinner /></Center>
    if (isConversationInvalid) return <Center py='32'><Text>This conversation doesn't exist.</Text></Center>

    
    let annotations = conversation?.annotations ?? []
    console.log(annotations)
    const annotatorNames = annotations.map(({ annotator }) => annotator.displayName)

    return (
        <Flex flexDir={'column'} background={'slate.100'} p='8' h='full'>
            <Flex flexDir={'column'}>
                <FormControl justifyContent={'center'} display='flex' alignItems='center'>
                    <FormLabel htmlFor='view-mode' mb='0' fontSize={'lg'}>
                        View Mode
                    </FormLabel>
                    <Switch size={'lg'} colorScheme={'primary'} isChecked={isViewMode} onChange={e => setIsViewMode(e.target.checked)} id='view-mode' />
                </FormControl>
                <HStack mb='6' justifyContent={'space-between'}>
                    <Heading display={'flex'} alignItems={'center'}>
                        Annotating: {conversation.title }
                        <Tag ml='3' colorScheme={RCLASS_TO_COLOR[conversation.resourceClass]}>{conversation.resourceClass}</Tag>
                        /
                        <Tag colorScheme='primary'>{conversation.resourceName}</Tag>
                    </Heading>
                    <Text>Annotating as <i>{userDoc?.displayName}</i></Text>
                </HStack>
            </Flex>
            <SimpleGrid h='full' overflowY={'hidden'} gap='6' gridTemplateColumns={isViewMode ? (areSourcesToShow ? '2fr 1fr' : '1fr') : (areSourcesToShow ? '1fr 1fr 1fr' : '1fr 1fr')} alignItems={'baseline'} position={'relative'}>
                <VStack flexGrow={1} h='100%' overflowY={'scroll'} ref={responseListRef} gap='8'>
                    {conversation?.responses.map((response, i) => <HStack key={i}><VStack m='auto' w='full' maxW='4xl' borderWidth={(responseIndexToAnnotate == i && !isViewMode) ? '4px' : '0'} borderColor={'primary.600'} gap={8}>
                        <Pair
                            userName={conversation?.userName}
                            isShare
                            onReferenceTagClick={setFocusSourceId}
                            loadingMessage=""
                            onCopyResponseLink={undefined}
                            {...response}
                            isAdmin={false}
                            onFeedback={console.log}
                        />
                    </VStack>

                        {isViewMode &&
                            <AnnotationDisplay
                                annotatorIndex={viewAnnotatorIndex}
                                annotation={conversation?.annotations ? (conversation.annotations[viewAnnotatorIndex].responseLevelAnnotations.length >= i + 1 ? conversation.annotations[viewAnnotatorIndex].responseLevelAnnotations[i] : BLANK_RESPONSE_ANNOTATION) : BLANK_RESPONSE_ANNOTATION}
                                annotatorNames={annotatorNames}
                                setAnnotatorIndex={setViewAnnotatorIndex}
                                isAnnotation={conversation?.annotations ? conversation.annotations[viewAnnotatorIndex].responseLevelAnnotations.length >= i + 1 : false}
                            />
                        }
                    </HStack>)}
                </VStack>
                {!isViewMode && 
                    <ResponseAnnotationBox
                        responseIndex={responseIndexToAnnotate}
                        doScroll={scrollToAnnotateResponse}
                        onBack={handleBack}
                        onNext={handleNext}
                        annotation={responseAnnotations[responseIndexToAnnotate]}
                        isLastResponse={responseIndexToAnnotate+1 == (conversation?.responses.length ?? 0)}
                    />
                }
                <SourcesPane 
                    courseId={params.courseId}
                    display={true}
                    focusSourceId={focusSourceId}
                    onClose={console.log}
                    responses={conversation.responses}
                    title={conversation.title}
                    setAreSourcesToShow={setAreSourcesToShow}
                />
            </SimpleGrid>
        </Flex>
    )
}

function AnnotationDisplay({ annotation, isAnnotation, annotatorNames, setAnnotatorIndex, annotatorIndex }:
    { annotation: ResponseLevelAnnotation, isAnnotation: boolean, annotatorNames: string[], setAnnotatorIndex: (i: number) => void, annotatorIndex: number }) {

    const percentage = Math.round((annotation.likert / MAX_LIKERT_VALUE) * 100)

    return (
        <Card w='3xl'>
            <CardBody>
                <Tabs index={annotatorIndex} onChange={setAnnotatorIndex} colorScheme={'primary'}>
                    <TabList mb='3'>
                        {annotatorNames.map((n, i) => <Tab key={i}>{n}</Tab>)}
                    </TabList>
                </Tabs>

                {isAnnotation ? <><Box h='2' bg='slate.300' w='100%' borderRadius={'md'} position={'relative'} overflow={'hidden'}>
                    <Box
                        position={"absolute"}
                        left='0'
                        top='0'
                        bg='primary.500'
                        h='100%'
                        w={`calc(${percentage}%)`}
                    />
                </Box>

                    <Text my='3'><strong>Issues:</strong><br />{annotation.issues.join(", ")}</Text>

                    <Text><strong>Comments:</strong><br />{annotation.comment}</Text></> : <Text>No annotation.</Text>}
            </CardBody>
        </Card>
    )
}

function ResponseAnnotationBox({ responseIndex, annotation, onNext, onBack, isLastResponse, doScroll }: { responseIndex: number, annotation: ResponseLevelAnnotation, onNext: (a: ResponseLevelAnnotation) => void, onBack: (a: ResponseLevelAnnotation) => void, isLastResponse: boolean, doScroll: ()=>void }) {
    const [scaleValue, setScaleValue] = useState(annotation.likert)
    const [textFeedback, setTextFeedback] = useState(annotation.comment)
    const [issues, setIssues] = useState(annotation.issues)

    const likertTextColor = useColorModeValue('slate.500', 'slate.400')
    const sliderTrackBg = useColorModeValue('primary.600', 'primary.500')

    useEffect(() => {
        setScaleValue(annotation.likert)
        setTextFeedback(annotation.comment)
        setIssues(annotation.issues)
    }, [responseIndex, annotation])

    const handleNext = () => {
        onNext({ likert: scaleValue, comment: textFeedback, issues })
    }

    const handleBack = () => {
        onBack({ likert: scaleValue, comment: textFeedback, issues })
    }

    return (
        <Card>
            <CardHeader display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Heading size={'md'}>
                    Response {responseIndex + 1}
                </Heading>
                <Button variant={'ghost'} fontWeight={'400'} onClick={doScroll}>Scroll</Button>
            </CardHeader>
            <CardBody>
                <Box mb='6'>
                    <Heading mb='1' size='sm'>How good is ATA's response here?</Heading>
                    <Slider
                        colorScheme={'primary'}
                        defaultValue={DEFAULT_LIKERT_VALUE}
                        min={1}
                        max={MAX_LIKERT_VALUE}
                        step={LIKERT_STEP}
                        value={scaleValue}
                        onChange={(n) => setScaleValue(n)}
                    >
                        <SliderTrack>
                            <SliderFilledTrack bg={sliderTrackBg} />
                        </SliderTrack>
                        <SliderThumb borderWidth={'1px'} borderColor={'slate.200'} />
                    </Slider>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Text fontSize='sm' color={likertTextColor}>Not helpful at all</Text>
                        <Text fontSize='sm' color={likertTextColor}>Very Helpful</Text>
                    </Flex>
                </Box>

                <Box mb='6'>
                    <Heading mb='1' size='sm'>Issues</Heading>
                    <MultiCheckbox
                        allowMultipleOn
                        itemsChecked={annotation.issues}
                        items={ANNOTATION_ISSUES}
                        onChange={setIssues}
                    />
                </Box>

                <Box>
                    <Heading mb='1' fontWeight={400} size='sm'><strong>Comments</strong> <Box as={'span'} fontSize='sm' color='slate.500'>(optional)</Box></Heading>
                    <Textarea value={textFeedback} _focus={{ borderColor: 'primary.500', boxShadow: 'none', borderWidth: '2px' }} onChange={(e) => setTextFeedback(e.target.value)} placeholder="<What ATA should have said>, <student confusion ATA is missing>, etc." />
                </Box>
            </CardBody>
            <CardFooter gap='3'>
                {responseIndex > 0 && <Button variant={'ghost'} fontWeight={'400'} onClick={handleBack}>Back</Button>}
                <Button fontWeight={'400'} colorScheme={'primary'} onClick={handleNext}>{ isLastResponse ? 'Save' : 'Next'}</Button>
            </CardFooter>
        </Card>
    )
}

function ConversationAnnotationBox({ annotation, onSave }: { annotation: ResponseLevelAnnotation, onSave: (a: ResponseLevelAnnotation) => void }) {
    const [scaleValue, setScaleValue] = useState(annotation.likert)
    const [textFeedback, setTextFeedback] = useState(annotation.comment)
    const [issues, setIssues] = useState(annotation.issues)

    const likertTextColor = useColorModeValue('slate.500', 'slate.400')
    const sliderTrackBg = useColorModeValue('primary.600', 'primary.500')

    useEffect(() => {
        setScaleValue(annotation.likert)
        setTextFeedback(annotation.comment)
        setIssues(annotation.issues)
    }, [annotation])

    return (
        <Card>
            <CardHeader>
                <Heading size={'md'}>
                    Conversation Annotation
                </Heading>
            </CardHeader>
            <CardBody>
                <Box mb='6'>
                    <Heading mb='1' size='sm'>How good is ATA's overall in this conversation?</Heading>
                    <Slider
                        colorScheme={'primary'}
                        defaultValue={DEFAULT_LIKERT_VALUE}
                        min={1}
                        max={MAX_LIKERT_VALUE}
                        step={LIKERT_STEP}
                        value={scaleValue}
                        onChange={(n) => setScaleValue(n)}
                    >
                        <SliderTrack>
                            <SliderFilledTrack bg={sliderTrackBg} />
                        </SliderTrack>
                        <SliderThumb borderWidth={'1px'} borderColor={'slate.200'} />
                    </Slider>
                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Text fontSize='sm' color={likertTextColor}>Not helpful at all</Text>
                        <Text fontSize='sm' color={likertTextColor}>Very Helpful</Text>
                    </Flex>
                </Box>


                <Box mb='6'>
                    <Heading mb='1' size='sm'>Issues</Heading>
                    <MultiCheckbox
                        allowMultipleOn
                        itemsChecked={annotation.issues}
                        items={ATA_FEEDBACK_ISSUES}
                        onChange={setIssues}
                    />
                </Box>

                <Box>
                    <Heading mb='1' fontWeight={400} size='sm'><strong>Comments</strong> <Box as={'span'} fontSize='sm' color='slate.500'>(optional)</Box></Heading>
                    <Textarea value={textFeedback} _focus={{ borderColor: 'primary.500', boxShadow: 'none', borderWidth: '2px' }} onChange={(e) => setTextFeedback(e.target.value)} placeholder="<What ATA should have said>, <student confusion ATA is missing>, etc." />
                </Box>
            </CardBody>
            <CardFooter>
                <Button colorScheme={'primary'} fontWeight={400} onClick={() => onSave({ likert: scaleValue, comment: textFeedback, issues })}>Save</Button>
            </CardFooter>
        </Card>
    )
}