import { Box, Button, Flex, Heading, Spinner, Tag, useColorModeValue } from "@chakra-ui/react";
import Logo from "../../ui/Logo";
import { useNavigate } from "react-router-dom";


export default function NotFound() {
    const navigate = useNavigate()

    return (
        <Box h='100vh' w='full' bg={useColorModeValue('slate.100', 'slate.800')}>
            <Flex mx='auto' flexDir={'column'} justifyContent='center' alignItems={'start'} maxW='6xl' p='32'>
                <Logo darkOnLight />
                <Heading fontSize={200} mb='0'>404</Heading>
                <Heading my='16' fontWeight={400}>Oh no! We can't find the page you're looking for.</Heading>
                    
                <Button
                    px='12'
                    fontSize='2xl'
                    size='lg'
                    colorScheme={'primary'}
                    color='white'
                    fontWeight={'400'}
                    onClick={() => navigate('/')}
                >
                    Go Home
                </Button>
            </Flex>
        </Box>
    )
}