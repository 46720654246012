import { LoadingTextItem } from "../routes/tutor/LoadingTextSpinner";

export const APP_TITLE = "ATA"
export const DESCRIPTION = "ATA is a AI-powered Teaching Assistant for college courses currently available in select Brown University courses."
// export const CLOUD_FUNCTION_URL = "https://kodiak-service-kdavspsweq-ue.a.run.app/query"
// export const CLOUD_FUNCTION_URL = "https://kodiak-service-dev-511573966113.us-east1.run.app/query"
export const CLOUD_FUNCTION_URL = "https://kodiak-service-kdavspsweq-ue.a.run.app/query" // prod endpoint
// export const CLOUD_FUNCTION_URL = "https://kodiak-service-dev-511573966113.us-east1.run.app/query" // dev endpoint
//export const CLOUD_FUNCTION_URL = "http://127.0.0.1:8080/query"

export const INGESTION_SERVICE_ENDPOINT = "https://ingestion-service-511573966113.us-east1.run.app/single"
// export const INGESTION_SERVICE_ENDPOINT = "http://localhost:8080/single"

export const MAX_QUERY_LENGTH = 1000;
export const BASE_DOMAIN = 'https://www.talktoata.com'
export const NUM_PAST_CONVERSATIONS_TO_FETCH = 5
export const NUM_ADMIN_CONVERSATIONS_TO_FETCH = 100 // page size on the analytics dashboard
export const NUM_ADMIN_USERS_TO_FETCH = 25
export const REQUEST_FEEDBACK_EVERY = 5
export const MAX_CONVERSATION_SIZE = 25


export const COURSE_ID_TO_FULL_NAME = {
    "brown-university-csci0150-fall-2023": "CSCI0150 Fall 2023",
    "brown-university-csci0150-fall-2024": "CSCI0150 Fall 2024",
    "brown-university-chem0330-fall-2024": "CHEM0330 Fall 2024"
}

export const COURSE_ID_TO_NAME = {
    "brown-university-csci0150-fall-2023": "CSCI0150",
    "brown-university-csci0150-fall-2024": "CSCI0150",
    "brown-university-chem0330-fall-2024": "CHEM0330"
}

export const FILTER_DECISIONS = ["ENGAGE", "OFFTOPIC", "MALICIOUS", "N/A"]
export const ACTION_DECISIONS = ["DIAGNOSTIC", "SOCRATIC", "INFORMATION", "DEFLECT"]

export const ATA_FEEDBACK_ISSUES = [
    "Repetitive",
    "Incorrect",
    // "Security issue",
    "Doesn't understand my questions",
    // "Poor retrieval",
    "Doesn't give enough information",
    // "Unsure"
]

export const ANNOTATION_ISSUES = [
    "Repetitive",
    "Incorrect",
    "Security issue",
    "Poor retrieval",
    // "Doesn't understand questions",
    "Doesn't give enough information",
    "Unsure",
    "Forgetting context from earlier",
    "Misunderstands student",
    "Provides poor feedback/advice",
    "False Deflection"
]

export const ALLTIME_ANNOTATION_ISSUES = [
    "Repetitive",
    "Incorrect",
    "Security issue",
    "Poor retrieval",
    "Doesn't understand questions",
    "Doesn't give enough information",
    "Unsure",
    "Forgetting context from earlier",
    "Misunderstands student",
    "Provides poor feedback/advice",
    "False Deflection"
]


export const RCLASS_TO_COLOR = {
    "hw": "facebook",
    "lecture": "orange",
    "exam": "green",
    "lab": "pink",
    "logistics": "gray"
}

export const RCLASS_TO_TITLE = {
    "hw": "Homework",
    "lecture": "Lecture",
    "exam": "Exam",
    "lab": "Lab",
    "logistics": "Logistics"
}

export const MOBILE_BREAKPOINTS = ['base', 'sm', 'md']


export const RESPONSE_LOADING_TEXT: LoadingTextItem[] = [
    { text: 'Performing Security Checks...', approximateSeconds: 3 },
    { text: 'Finding Relevant Documents...', approximateSeconds: 5 },
    { text: 'Analyzing Student Knowledge...', approximateSeconds: 6 },
    { text: 'Planning Response...', approximateSeconds: 15 }
]

export const CS15_EDSTEM_LINK = "https://edstem.org/us/courses/63887"
export const CS15_COURSE_CALENDAR = "https://calendar.google.com/calendar/u/0?cid=Y19lYWZiMjYzZjIyMWFjOTRhZTIwMDkzNmI0NTNkOGYxZTYyMDY0ZmViMGIzYTZmZDEwODg3NDY0YWI0YmRhMTUyQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20"

export const ATA_INTRO_MESSAGE = `Hello, I'm ATA: CSCI0150's artificial teaching assistant! 🤖

I work differently than other generative language models. You can ask me about anything related to the topics in this resource, as I'll have access to all relevant course materials.

It helps if you provide me with as much information as possible about your current understanding, what you've tried so far, and what you think might be the issue.

My goal is to guide you through the learning process, not just give you answers.

So, what can I help you with today?`

export const ATA_FEEDBACK_MESSAGE = `#### The ATA Team

*ATA is experimental. Your feedback will have a large impact on how ATA looks in the future, so filling feedback forms out honestly will be super helpful! You can always reach us (Noah, Julian, and Woody) at [team@talktoata.com](mailto:team@talktoata.com) with any ideas, bugs, or just to say hi (seriously, we'd love to hear from you!).*`

//`👋 Hi there! We're the ATA Team (*Noah, Julian, & Woody*) and we're so psyched for you all to use ATA this semester.
 
// ATA is at a highly experimental stage and your use/feedback will have a large impact on how ATA looks a few years from now. Please fill out feedback honestly and you can always reach us at [team@talktoata.com](mailto:team@talktoata.com) with any ideas, bugs, or just to say hi (*Seriously, we'd love to hear from you!*).

// Here are some ways you can interact with ATA:

// ### Information Retrieval
// 1. *"Could you tell me when AndyBot is due?"*
// 2. *"Can you give me an overview of this lecture?"*

// ### Office Hours
// 1. *"I'm confused about classes vs. methods. It seems like to create an instance of a class you have to call a method?"*
// 2. *"I'm not sure on how to get the paddles to move in Pong. I've tried [insert conceptual description of implementation]... but I don't think it's quite right. Can you guide me?"*

// So, how can ATA help you today?`
// export const ATA_INTRO_MESSAGE = `Hi there 👋! I'm ATA, CS 15's AI Teaching Assistant. 

// I'm not the same as other chatbots you may have used so, for our conversations to be productive you'll need to interact with me differently. 

// Here are some guiding principles and examples.

// ### Retrieving Information
// Just like a normal TA, I have access to all of the course materials. You can ask me about that information for a broad overview.
// - *"When is Othello due?"*
// - *"Can you give me an overview of this lecture?"*

// ### Office Hours 
// Yet, the main (*and exciting!*) way I differ from a normal chatbot is that I'm not here just to give you information, but to guide you in the process of learning.

// Expect me to act like a TA in office hours: I won't give you answers, but I'll work with you to refine your understanding and iterate on your design and implementation. 
// Similar to writing a good EdStem, start by giving me as much information as possible about what your current understanding, what you've tried so far, and what you think might be the issue.
// - *"I'm confused about the conceptual side of <specific problem>. My current understanding is that A does B, but then I'm not sure what C is for. Can you help me?"*
// - *"I'm struggling with implementing <specific feature>. My current implementation uses A to do B, but I've also tried X and Y. I'm a little confused about Z also. My next idea was to do C—is that on the right track?"*

// ---

// So, what can I help you with today?`

export const SUGGEST_MESSAGE = "It may be a good time to take a break or get help from another source."