import { Conversation } from '../../types/types'
import { Card, Menu, MenuList, Heading, Text, Flex, Tag, MenuButton, MenuItem, Icon, Button, IconButton, Portal, useToast, useColorModeValue } from '@chakra-ui/react'
import { ChatIcon, EditIcon, LinkIcon } from '@chakra-ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getRelativeTimeString } from '../../lib/date_utils'
import { BASE_DOMAIN, RCLASS_TO_COLOR } from '../../lib/CONSTANTS'
import { BsThreeDotsVertical, BsTrash, BsTrashFill } from "react-icons/bs";
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../lib/firebase'

export function ConversationCard({ id, title, timestampCreated, timestampLastUpdated, responses, resourceName, resourceClass, courseId, archiveConversation }: Conversation & { courseId: string, archiveConversation: (id: string) => void }) {
    const navigate = useNavigate()

    const toast = useToast()

    const handleCopyShareLink = async (e) => {
        e.stopPropagation()

        const shareableLink = `${BASE_DOMAIN}/app/${courseId}/share/${id}`;

        logEvent(analytics, "shareable_link_generated")

        try {
            await navigator.clipboard.writeText(shareableLink);
            toast({
                title: "Shareable link copied to clipboard!",
                position: "top",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            console.log("Shareable link copied to clipboard");
        } catch (err) {
            console.error("Failed to copy shareable link to clipboard: ", err);
            toast({
                title: "Failed to copy shareable link to clipboard.",
                description: `Error: ${err}`,
                position: "top",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    }

    const handleArchiveConversation = (e) => {
        e.stopPropagation()
        archiveConversation(id)
    }

    return (
        <Card
            onClick={() => navigate(`/app/${courseId}/tutor/${resourceClass}/${encodeURIComponent(resourceName)}?id=${id}`)}
            w='full'
            cursor='pointer'
            transition="transform 0.2s"
            _hover={{ bg: useColorModeValue('slate.200', 'slate.800') }}
            boxShadow={'none'}
            borderWidth={0}
            borderColor={useColorModeValue('slate.200', 'slate.700')}
            backgroundColor={useColorModeValue('slate.100', 'slate.800')}
            p='3'
        >
            <Flex alignItems={'center'} justifyContent={'space-between'}>
                <Flex alignItems={'center'} gap='2'>
                    <ChatIcon color='slate.500' />
                    <Heading size='sm' fontWeight={'400'}>{title}</Heading>
                </Flex>

                <Menu>
                    <MenuButton
                        as={IconButton}
                        variant={'ghost'}
                        size='md'
                        color='slate.500'
                        onClick={(e) => e.stopPropagation()}
                        fontWeight={'400'}
                        icon={<BsThreeDotsVertical/>}
                     />
                     <Portal>
                        <MenuList>
                            <MenuItem onClick={handleCopyShareLink} icon={<LinkIcon/>}>
                                Share
                            </MenuItem>
                            {/* <MenuItem icon={<EditIcon />} onClick={handleRenameConversation}>
                                Rename
                            </MenuItem> */}
                            <MenuItem onClick={handleArchiveConversation} color={useColorModeValue('red.700', 'red.400')} icon={<Icon as={BsTrashFill}/>}>
                                Delete
                            </MenuItem>
                        </MenuList>
                     </Portal>
                </Menu>
            </Flex>
            
            <Flex alignItems={'center'} gap='1'>
                <Tag size='sm' colorScheme={RCLASS_TO_COLOR[resourceClass]}>{resourceClass}</Tag>
                /
                <Tag size='sm' colorScheme='primary'>{resourceName}</Tag>
            </Flex>

            <Text mt='1.5' fontSize='sm' color='slate.500'>{getRelativeTimeString(timestampLastUpdated.toDate())}</Text>
        </Card>
    )
}

export function SimpleConversationCard({ id, title, timestampLastUpdated, resourceName, resourceClass, courseId }: Conversation & { courseId: string }) {
    const navigate = useNavigate()

    return (
        <Card
            onClick={() => navigate(`/app/${courseId}/tutor/${resourceClass}/${encodeURIComponent(resourceName)}?id=${id}`)}
            // w='100%'
            cursor='pointer'
            transition="transform 0.2s"
            _hover={{ borderColor: useColorModeValue('slate.300', 'slate.600') }}
            boxShadow={'none'}
            borderWidth={2}
            borderColor={useColorModeValue('slate.200', 'slate.700')}
            backgroundColor={useColorModeValue('slate.50', 'slate.800')}
            p='3'
        >
            <Flex alignItems={'center'} justifyContent={'space-between'} w='full' gap={2}>
                <Flex gap={2} alignItems={'center'}>
                    <ChatIcon color='slate.500' />
                    <Heading size='sm' fontWeight={'400'}>{title}</Heading>
                </Flex>
                <Text fontSize='sm' color='slate.500'>{getRelativeTimeString(timestampLastUpdated.toDate())}</Text>
            </Flex>
        </Card>
    )
}