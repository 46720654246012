import { doc, onSnapshot } from "firebase/firestore"
import { useState, useEffect } from "react"
import { db } from "../lib/firebase"
import { UserCourseDoc } from "../types/types"
import { addBlankUserCourseDocToUser } from "../lib/database"

export default function useUserDoc(uid: string, courseId: string, courseQueriesQuota: number){
    const [userCourseDoc, setUserCourseDoc] = useState<UserCourseDoc>()
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if(!uid || !courseId || !courseQueriesQuota) {
            setIsReady(false)
            return
        }

        const userCourseDocRef = doc(db, "Users", uid, "UserCourseData", courseId)

        const unsub = onSnapshot(userCourseDocRef, (doc) => {
            const fromCache = doc.metadata.fromCache
            if(doc.exists()) {
                setUserCourseDoc(doc.data() as UserCourseDoc)
                setIsReady(true)
            }
            else if(!fromCache) {
                addBlankUserCourseDocToUser(uid, courseId, courseQueriesQuota)
            }
        })

        return () => unsub()
    }, [uid, courseId, courseQueriesQuota])

    return [userCourseDoc, isReady] as [UserCourseDoc, boolean]
}