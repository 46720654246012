import { Checkbox, Grid } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export default function MultiCheckbox({ items, onChange, allowMultipleOn, itemsChecked=[], ...otherArgs }: { items: string[], onChange: (its: string[]) => void, allowMultipleOn: boolean, itemsChecked: string[] }){
    const [activeItems, setActiveItems] = useState<boolean[]>(() => items.map(it => itemsChecked.includes(it)))

    useEffect(() => {
        setActiveItems(() => items.map(it => itemsChecked.includes(it)))
    }, [itemsChecked])

    useEffect(() => {
        const itemsEnabled = []
        for(let i=0;i<items.length;i++){
            if(activeItems[i]) itemsEnabled.push(items[i])
        }

        onChange(itemsEnabled)
    }, [activeItems])

    const handleItemChange = (index) => {
        // can't un-select if it's on
        if(!allowMultipleOn && activeItems[index]) return

        let temp: boolean[]
        if(allowMultipleOn){
            temp = [...activeItems]
            temp[index] = !temp[index]
        }else{
            temp = Array(items.length).fill(false)
            temp[index] = true
        }

        setActiveItems(temp)
    }

    return (
        <Grid gridTemplateColumns={'1fr 1fr'} gap={2} {...otherArgs}>
            { items.map((item, i) => 
                <Checkbox colorScheme={'primary'} key={i} isChecked={activeItems[i]} onChange={() => handleItemChange(i)}>{item}</Checkbox>) }
        </Grid>
    )
}
