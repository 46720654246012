'use client'

import { Button, Flex, Tag } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export default function MultiToggle({ items, onChange, allowMultipleOn, small=false, ...otherArgs }){
    const [activeItems, setActiveItems] = useState<Boolean[]>([true].concat(Array(items.length-1).fill(false) as unknown as boolean[]))

    useEffect(() => {
        const itemsEnabled = []
        for(let i=0;i<items.length;i++){
            if(activeItems[i]) itemsEnabled.push(items[i])
        }

        onChange(itemsEnabled)
    }, [activeItems])

    const handleItemChange = (index) => {
        // can't un-select if it's on
        if(!allowMultipleOn && activeItems[index]) return

        let temp
        if(allowMultipleOn){
            temp = [...activeItems]
            temp[index] = !temp[index]
        }else{
            temp = Array(items.length).fill(false)
            temp[index] = true
        }

        setActiveItems(temp)
    }

    return (
        <Flex wrap={'wrap'} gap={2} {...otherArgs}>
            { items.map((item, i) => 
                <Tag 
                    fontWeight='400'
                    cursor={'pointer'} 
                    size={small ? 'md' : 'lg'} 
                    key={i} 
                    onClick={() => handleItemChange(i)} 
                    variant={activeItems[i] ? 'solid' : 'subtle'} 
                    colorScheme={activeItems[i] ? 'primary' : 'slate'}
                >
                    { item }
                </Tag>) }
        </Flex>
    )
}
