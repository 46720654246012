import { DocumentData, QueryDocumentSnapshot, collection, collectionGroup, doc, documentId, getCountFromServer, getDoc, getDocs, limit, query, startAfter, where } from "firebase/firestore";
import { useEffect, useReducer, useState } from "react";
import { db } from "../lib/firebase";
import { User, UserCourseDoc } from "../types/types";

export type UserPlus = User & UserCourseDoc

export default function useCourseUsers(courseId: string){
    // const [users, dispatch] = useReducer<(state: UserPlus[], action: any) => any[]>(reducer, []);
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    // const [totalUserCount, setTotalUserCount] = useState<number | undefined>(undefined)
    // const [lastFetched, setLastFetched] = useState<QueryDocumentSnapshot<DocumentData, DocumentData> | undefined>(undefined)

    const fetchSingleUserCourseDoc = async (uid: string, courseId: string) => {
        const ref = doc(db, `Users/${uid}/UserCourseData/${courseId}`)
        const snapshot = await getDoc(ref)
        return { ...snapshot.data(), uid, courseId }
    }

    const fetchAllUsers = async () => {
        // fetch all user docs
        const q = query(collection(db, "Users"),
            where('courses', 'array-contains', courseId)
        )
        const userDocsSnapshot = await getDocs(q)
        const uidToUserDoc = Object.fromEntries(userDocsSnapshot.docs.map((d, i) => [d.id, {...d.data(), uid: d.id}]))

        // fetch all user-course docs
        const uids = Object.keys(uidToUserDoc)

        const promises = uids.map((uid) => fetchSingleUserCourseDoc(uid, courseId))
        const userCourseDocs = await Promise.all(promises)

        // match them up
        const usersPlus = userCourseDocs.map((d) => ({ ...d, ...uidToUserDoc[d.uid] }))
        setUsers(usersPlus)
    }

    // Fetch first conversations
    useEffect(() => {
        if(!courseId) return

        if(users.length == 0){
            fetchAllUsers()
        }
    }, [courseId])

    useEffect(() => {
        if(users.length > 0) setIsLoading(false)
    }, [users])

    return [
        users, 
        isLoading, 
    ] as [
        UserPlus[],
        boolean 
    ]
}