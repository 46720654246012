import { Box, useColorMode, useColorModeValue } from "@chakra-ui/react";

export default function Container({ children, bg='white' }){
    return (
        <Box bg={bg} py={{'base': 16, 'md': 32}}>
            <Box maxW='6xl' p='3' m='auto'>
                {children}
            </Box>
        </Box>
    )
}