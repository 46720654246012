import { useEffect, useState } from "react"
import { getConversationDoc } from "../lib/database"
import { Conversation } from "../types/types"
import { doc, onSnapshot } from "firebase/firestore"
import { db } from "../lib/firebase"

export default function useSingleConversation(courseId: string, convoId: string){
    const [conversationDoc, setConversationDoc] = useState(null)
    const [isInvalid, setIsInvalid] = useState(false)

    useEffect(() => {
        if(!convoId) return
        
        const conversationDocRef = doc(db, "Courses", courseId, "Conversations", convoId)

        const unsub = onSnapshot(conversationDocRef, (doc) => {
            if(doc.exists()) {
                setConversationDoc({ ...doc.data(), id: convoId })
            }
            else{
                setIsInvalid(true)
            }
        })

        return unsub
    }, [courseId, convoId])

    return [conversationDoc, isInvalid] as [Conversation, boolean]
}