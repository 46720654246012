import { Box, Button, Card, CardBody, Text, CardHeader, Center, FormControl, FormLabel, Heading, HStack, IconButton, Input, Progress, Spinner, useToast, VStack, InputGroup, InputRightElement, useColorModeValue, Checkbox, FormHelperText, Modal, ModalContent, ModalOverlay, ModalHeader, ModalBody, Flex } from "@chakra-ui/react";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import useSingleCourse from "../../../hooks/useSingleCourse";
import MultiToggle from "../../../ui/MultiToggle";
import { capitalizeWords } from "../../../lib/text_utils";
import { AttachmentIcon, DeleteIcon } from "@chakra-ui/icons";
import { uploadFile } from "../../../lib/storage";
import { auth } from "../../../lib/firebase";
import { INGESTION_SERVICE_ENDPOINT } from "../../../lib/CONSTANTS";
import { formatISO } from "../../../lib/date_utils";
import { stat } from "fs";

const CLEAR = "CLEAR"
const UPDATE_KEY = "UPDATE_KEY"

const fileGroupsReducer = (state, action) => {
    switch (action.type) {
        case CLEAR:
            return {}
        case UPDATE_KEY:
            return {
                ...state,
                [action.key]: action.items
            }
        default:
            return state
    }
}

const fileProgressReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_KEY:
            return {
                ...state,
                [action.key]: action.value
            }
        default:
            return state
    }
}

type State = 'form' | 'uploading' | 'ingesting'

type IngestionServiceRequest = {
    courseId: string
    resourceClass: string
    resourceName: string
    releaseDate: string
    dueDate?: string
}

async function sendIngestRequest(req: IngestionServiceRequest) {
    const accessToken = await auth.currentUser.getIdToken()

    const headers = {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json",
    }

    const response = await fetch(INGESTION_SERVICE_ENDPOINT, {
        method: "POST",
        headers,
        body: JSON.stringify(req),
    })

    return response
}

export default function AddResource() {
    const params = useParams()
    const toast = useToast()

    const [state, setState] = useState<State>('form')

    const [course, isCourseLoading] = useSingleCourse(params.courseId)

    const [resourceName, setResourceName] = useState('')
    const [resourceClass, setResourceClass] = useState(null)

    const [releaseDate, setReleaseDate] = useState<Date>(new Date())
    const [isDueDate, setIsDueDate] = useState(false)
    const [dueDate, setDueDate] = useState<Date>(undefined)

    const [resourceTypeToFiles, dispatchFileGroups] = useReducer(fileGroupsReducer, {})
    const [filePathToProgress, dispatchFilePathToProgress] = useReducer(fileProgressReducer, {})

    const handleResourceClassChange = (rc) => {
        // clear files
        dispatchFileGroups({ type: CLEAR })

        const resourceTypes = course.schema[rc]
        for (const resourceType of resourceTypes) {
            dispatchFileGroups({ type: UPDATE_KEY, key: resourceType, items: [] })
        }

        setResourceClass(rc)
    }

    const handleResourceTypeFilesUpdate = (resourceType: string, files: File[]) => {
        dispatchFileGroups({ type: UPDATE_KEY, key: resourceType, items: files })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // disallow changes
        setState('uploading')

        // start uploading files
        const pathsToFile = {}
        for (const [resourceType, files] of Object.entries(resourceTypeToFiles)) {
            for (const file of files as File[]) {
                const path = `data/${params.courseId}/${resourceClass}/${resourceName}/${resourceType}/${file.name}`
                pathsToFile[path] = file
            }
        }

        const promises: Promise<unknown>[] = Object.entries(pathsToFile).map(
            ([path, file]) => uploadFile(path, file, (p) => dispatchFilePathToProgress({ type: UPDATE_KEY, key: path, value: p }))
        )

        const filesUploadedPromise = Promise.all(promises)

        const request: IngestionServiceRequest = {
            courseId: params.courseId,
            resourceClass,
            resourceName,
            releaseDate: formatISO(releaseDate)
        }

        if (isDueDate) request.dueDate = formatISO(dueDate)

        filesUploadedPromise.then(async () => {
            setState('ingesting')
            toast({
                title: "Ingestion job submitted",
                position: "top",
                status: "info",
                duration: 4000,
                isClosable: true,
            })
            const response = await sendIngestRequest(request)
            if (!response.ok) {
                const errorData = (await response.json()).error
                toast({
                    title: "Error ingesting",
                    description: errorData,
                    position: "top",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: `Successfully Ingested ${resourceName}!`,
                    position: "top",
                    status: "success",
                    duration: 9000,
                    isClosable: true
                })
            }
            setState('form')
        })
            .catch(err => {
                console.error(err)
                toast({
                    title: "Error while uploading files",
                    description: err,
                    position: "top",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            })
    }

    useEffect(() => {
        if (isCourseLoading || resourceClass !== null) return
        setResourceClass(Object.keys(course.schema)[0])
    }, [course, isCourseLoading, resourceClass])

    if (isCourseLoading || !resourceClass) {
        return <Center pt='32'><Spinner /></Center>
    }

    const resourceTypes = course.schema[resourceClass]

    return (
        <Box maxW={'3xl'} m='auto'>
            <Heading>Add Resource</Heading>



            <Modal isOpen={state == 'uploading'} onClose={undefined}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Uploading Materials</ModalHeader>
                    <ModalBody>
                        <VStack gap='3' alignItems={'stretch'}>
                            {
                                Object.entries(filePathToProgress).map(([path, progress], i) => <FileUploadProgress key={i} path={path} progress={progress} />)
                            }
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>

            {state == 'ingesting' && <LoadingOverlay /> }


            <form onSubmit={handleSubmit}>
                <VStack gap='6' alignItems={'stretch'}>
                    <FormControl isRequired id="resource-name">
                        <FormLabel>Resource Name</FormLabel>
                        <Input
                            type='text'
                            value={resourceName}
                            onChange={e => setResourceName(e.target.value)}
                            placeholder='Problem Set #1'
                        />
                    </FormControl>

                    <Box>
                        <FormLabel>Resource Class</FormLabel>
                        <MultiToggle
                            allowMultipleOn={false}
                            items={Object.keys(course.schema)}
                            onChange={(items) => handleResourceClassChange(items[0])}
                        />
                    </Box>

                    <SimpleDateInput 
                        isRequired
                        label={"Release Date"}
                        onChange={setReleaseDate}
                        value={releaseDate}
                    />

                    <FormControl>
                        <Checkbox
                            isChecked={isDueDate}
                            onChange={(e) => setIsDueDate(e.target.checked)}
                            colorScheme={'primary'}
                        >
                            Due Date?
                        </Checkbox>
                    </FormControl>

                    {isDueDate && <SimpleDateInput 
                        isRequired={false}
                        label={"Due Date"}
                        onChange={setDueDate}
                        value={dueDate}
                    />}

                    {resourceTypes.map((resourceType, i) =>
                        <ResourceTypeFiles
                            key={i}
                            name={i}
                            resourceType={resourceType}
                            onSelectedFilesUpdate={(files) => handleResourceTypeFilesUpdate(resourceType, files)}
                        />
                    )}

                    <Button type="submit" colorScheme="primary" fontWeight='400' width="full">
                        Submit
                    </Button>
                </VStack>
            </form>

        </Box>
    )
}

const LoadingOverlay = ({ }) => {
    return (
        <Box
            bg="rgba(0, 0, 0, 0.5)"
            position={'fixed'}
            h='100vh'
            w='100vw'
            left='0'
            top='0'
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            zIndex="overlay"
        >
            <Flex
                direction="column"
                align="center"
                justify="center"
                bg="white"
                p={8}
                borderRadius={8}
                boxShadow="lg"
            >
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="primary.500"
                    size="xl"
                />
            </Flex>
        </Box>
    )
}

function FileUploadProgress({ path, progress }) {
    return (
        <HStack alignItems={'center'} p={2} bg="slate.100" borderRadius="md">
            <Text fontSize="sm">{path}</Text>
            <Progress colorScheme="primary" size="md" value={progress} />
        </HStack>
    )
}

function ResourceTypeFiles({ resourceType, onSelectedFilesUpdate, name }: { resourceType: string, onSelectedFilesUpdate: (files: File[]) => void, name: string | number }) {

    return (
        <Card variant={'outline'}>
            <CardHeader>
                <Heading fontSize={'xl'}>{capitalizeWords(resourceType)}</Heading>
            </CardHeader>
            <CardBody>
                <FileUploadUI onSelectedFilesUpdate={onSelectedFilesUpdate} name={name} />
            </CardBody>
        </Card>
    )
}

function FileUploadUI({ onSelectedFilesUpdate, name }) {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prev => [...prev, ...files]);
    }

    const removeFile = (index) => {
        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    }

    useEffect(() => onSelectedFilesUpdate(selectedFiles), [selectedFiles])


    return (
        <VStack spacing={4} alignItems={'start'} w='full'>

            <Button
                as="label"
                htmlFor={`file-upload-${name}`}
                leftIcon={<AttachmentIcon />}
                colorScheme="primary"
                cursor="pointer"
            >
                Select Files
                <Input
                    id={`file-upload-${name}`}
                    type="file"
                    multiple
                    onChange={handleFileSelect}
                    hidden
                />
            </Button>

            <VStack align="stretch" maxH="300px" w='full' overflowY="auto">
                {selectedFiles.map((file, index) => (
                    <HStack key={index} p={2} bg="slate.100" borderRadius="md">
                        <Text flex="1" noOfLines={1}>
                            {file.name} ({(file.size / 1024).toFixed(1)} KB)
                        </Text>
                        <IconButton
                            icon={<DeleteIcon />}
                            size="sm"
                            colorScheme="red"
                            onClick={() => removeFile(index)}
                            aria-label="Remove file"
                        />
                    </HStack>
                ))}
            </VStack>
        </VStack>
    )
}


const SimpleDateInput = ({
    label,
    value,
    onChange,
    isRequired
}) => {
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel>
                {label} 
                {/* {!isRequired && <Button ml='2' fontWeight='400' variant='link' onClick={() => onChange(undefined)}>Clear</Button>}  */}
            </FormLabel>
            <InputGroup>
                <Input
                    type="datetime-local"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    bg={useColorModeValue('white', 'gray.800')}
                />
            </InputGroup>
        </FormControl>
    )
}