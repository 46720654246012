import React, { useState } from 'react'
import { ResourceMetadata } from '../../types/types'
import { Card, CardHeader, Heading, CardBody, Flex, Text, Button, Tag, Box, useColorModeValue, Tooltip, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Icon, Wrap } from '@chakra-ui/react'
import { CalendarIcon, TimeIcon } from '@chakra-ui/icons'
import { formatDateShort, getToday, getLastXDays, compareDates } from '../../lib/date_utils'
import { Link, useNavigate } from 'react-router-dom'
import { RCLASS_TO_COLOR, RCLASS_TO_TITLE } from '../../lib/CONSTANTS'


const colors = ['blue', 'red', 'orange', 'green', 'purple', 'pink']

function addColon(title: string) {
    // Turn "Lecture X Title" into "Lecture X: Title"
    let stringParts = title.split(' ')
    if (stringParts.length >= 2 && stringParts[0].toLowerCase() == 'lecture') {
        stringParts[1] += ':'
    }
    return stringParts.join(' ')
}

export default function ResourceCard({ title, resourceClass, dueDate, releaseDate, summary, stub, topics, prereqs, courseId, showState }: ResourceMetadata & { courseId: string, showState: 'hidden' | 'student-visible' | 'admin-visible' | 'past-due' }) {

    const navigate = useNavigate()
    const descriptionColor = useColorModeValue('slate.500', 'slate.500')
    const borderColor = useColorModeValue('slate.200', 'slate.700')
    const bgColor = useColorModeValue('slate.100', 'slate.800')
    const hoverBgColor = useColorModeValue('slate.200', 'slate.700')

    const bgClassColor = useColorModeValue('400', '600')
    const borderClassColor = useColorModeValue('500', '500')
    const textClassColor = useColorModeValue('50', '100')

    if (showState == 'hidden') return

    return (
        <Card
            cursor="pointer"
            variant="outline"
            borderWidth={0}
            overflow='hidden'
            position="relative"
            transition="transform 0.2s"
            _hover={{ bg: hoverBgColor }}
            onClick={() => navigate(`/app/${courseId}/tutor/${resourceClass}/${encodeURIComponent(title)}`)}
            bg={bgColor}
        >
            <Flex
                borderRadius='2'
                flexDirection="column"
                h="full"
                overflowY="hidden"
            >    
                
                {showState !== 'past-due' && releaseDate ? (
                <Flex 
                    w='full' 
                    p={2} 
                    px={4} 
                    bg={`${RCLASS_TO_COLOR[resourceClass]}.${bgClassColor}`} 
                    // borderTopWidth={'1px'} 
                    // borderRightWidth={'1px'}
                    // borderLeftWidth={'1px'}
                    // borderColor={`${RCLASS_TO_COLOR[resourceClass]}.${borderClassColor}`}
                    justifyContent="space-between" 
                    flexDir="row"
                >
                    <Text fontWeight="400" textAlign={'left'} color={`${RCLASS_TO_COLOR[resourceClass]}.${textClassColor}`}>{RCLASS_TO_TITLE[resourceClass]}</Text>
                    <Flex alignItems="center" gap="2" mt="auto">
                        {/*<CalendarIcon color={`${RCLASS_TO_COLOR[resourceClass]}.${textClassColor}`} />*/}
                        <Text fontWeight="400" color={`${RCLASS_TO_COLOR[resourceClass]}.${textClassColor}`}>
                            {dueDate ? `${formatDateShort(releaseDate)} - ${formatDateShort(dueDate)}` : formatDateShort(releaseDate)}
                        </Text>
                        {/* <CalendarIcon color={`${RCLASS_TO_COLOR[resourceClass]}.${textClassColor}`} /> */}
                    </Flex>
                </Flex>
                ) : (
                    <Box w='full' p={0.5} bg={`${RCLASS_TO_COLOR[resourceClass]}.${bgClassColor}`}></Box>
                )}
                
                <Box 
                    p='4'
                    flexGrow={'1'}
                    borderColor={borderColor}
                    borderBottomWidth={'2px'}
                    borderLeftWidth={'2px'}
                    borderRightWidth={'2px'}
                >
                {showState !== 'past-due' ? (
                    <Heading mb='2' size="sm">{addColon(title)}</Heading>
                ) : (
                    <Heading size="xs" isTruncated mt="1" flex="1">{addColon(title)}</Heading>
                )}
                                
                {showState !== 'past-due' && releaseDate && (
                    <Text textColor={descriptionColor} fontSize='sm'>{stub}</Text>
                )}
                </Box>
            </Flex>

            {showState === 'admin-visible' && <HiddenIcon />}
        </Card>
    )
}


function HiddenIcon() {
    return (
        <Tooltip label="This resource is not visible to students yet" hasArrow placement={'left'}>
            {/* <Icon 
                position={'absolute'} 
                top='0'
                right='0'
                transform='auto'
                translateX={'50%'}
                translateY={'-50%'}
                as={GrHide} 
                boxSize='8'
                color={'#475569'}
            /> */}
            <Box
                position={'absolute'} 
                top='0'
                right='0'
                transform='auto'
                translateX={'50%'}
                translateY={'-50%'}
                p='4'
                borderRadius={'xl'}
                borderColor={useColorModeValue('slate.300', 'slate.600')}
                borderWidth={'1px'}
                bg={useColorModeValue('slate.200', 'slate.700')}
            >
                <TimeIcon 
                    color={useColorModeValue('slate.700', 'slate.200')}
                    position={'absolute'}
                    top='50%'
                    left='50%'
                    transform='auto'
                    translateX={'-50%'}
                    translateY={'-50%'}
                />
            </Box>
        </Tooltip>
    )
}