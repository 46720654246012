import { Alert, AlertIcon, AlertTitle, Box, Button, Center, FormLabel, Heading, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, Textarea, useToast } from "@chakra-ui/react";
import UserTable from "./UserTable";
import { useParams } from "react-router-dom";
import useCourseUsers from "../../../hooks/useCourseUsers";
import { useState } from "react";
import { isValidEmail } from "../../../lib/text_utils";
import { addEmailsToUserList } from "../../../lib/database";
import useCourseAllowedUsersList from "../../../hooks/useCourseAllowedUsersList";


export default function Users() {
    const params = useParams()
    const toast = useToast()
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false)
    const [isAllowedUsersListModalOpen, setIsAllowedUsersListModalOpen] = useState(false)

    const [users, isUsersLoading] = useCourseUsers(params.courseId)
    const [allowedStudents, allowedAdmins, isAllowedUsersListLoading] = useCourseAllowedUsersList(params.courseId)

    const onAddUser = async (emails: string[]) => {
        setIsAddUserModalOpen(false)
        await addEmailsToUserList(params.courseId, emails)
        toast({
            title: `${emails.length} emails added to allowed user list`,
            description: `Send them the join link (talktoata.com/join/${params.courseId}) for them to join the course!`,
            position: "top",
            status: "success",
            duration: 5000,
            isClosable: true,
        })
    }

    const usersWithAtLeastOneQuery = isUsersLoading ? undefined : users.reduce((acc, { totalExchanges }) => totalExchanges > 0 ? acc + 1 : acc, 0)
    const usersWithAtLeastFiveQueries = isUsersLoading ? undefined : users.reduce((acc, { totalExchanges }) => totalExchanges > 4 ? acc + 1 : acc, 0)

    return (
        <Box>
            <HStack alignItems={'center'} justifyContent={'space-between'}>
                <Heading mb='3'>Users</Heading>
                <HStack>
                    <Button size='sm' fontWeight={'400'} variant={'ghost'} onClick={() => setIsAllowedUsersListModalOpen(true)}>User List</Button>
                    <Button size='sm' fontWeight={'400'} colorScheme={'primary'} onClick={() => setIsAddUserModalOpen(true)}>Add User</Button>
                </HStack>
            </HStack>

            <Text mb='1'><strong>Total Users:</strong> { isUsersLoading ? <Spinner size={'sm'} /> : users.length }</Text>
            <Text mb='1'><strong>Users with 1+ Queries:</strong> { usersWithAtLeastOneQuery===undefined ? <Spinner size={'sm'} /> : usersWithAtLeastOneQuery }</Text>
            <Text mb='3'><strong>Users with 5+ Queries:</strong> { usersWithAtLeastFiveQueries===undefined ? <Spinner size={'sm'} /> : usersWithAtLeastFiveQueries }</Text>

            { isUsersLoading ? <Center py='64'><Spinner /></Center> : <UserTable users={users} courseId={params.courseId} />}

            <AddUserModal 
                isOpen={isAddUserModalOpen}
                onClose={() => setIsAddUserModalOpen(false)}
                onAddUser={onAddUser}
            />

            <AllowedUsersListModal 
                isOpen={isAllowedUsersListModalOpen}
                onClose={() => setIsAllowedUsersListModalOpen(false)}
                adminEmails={allowedAdmins}
                studentEmails={allowedStudents}
            />
        </Box>
    )
}

function AllowedUsersListModal({ isOpen, onClose, studentEmails, adminEmails }){
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>User Lists</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Heading size='lg'>Students</Heading>
                    { studentEmails.map((s, i) => <Text key={i} fontSize={'small'}>{s}</Text>) }

                    <Heading mt='3' size='lg'>Admins</Heading>
                    { adminEmails.map((s, i) => <Text key={i} fontSize={'small'}>{s}</Text>) }
                </ModalBody>
            </ModalContent>

        </Modal>
    )
}

function AddUserModal({ isOpen, onClose, onAddUser }){

    const [emailsText, setEmailsText] = useState('')
    const [error, setError] = useState("")

    const onAddClick = () => {
        const emails = emailsText.trim().split(/[\n,]+/) // split on \n or ,
        
        for(const e of emails){
            if(!isValidEmail(e)){
                setError(`'${e}' is not a valid email`)
                return
            }
            
        }

        onAddUser(emails)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>Add Users</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormLabel><strong>User Emails</strong> - Enter one or more emails separated by commas or newlines</FormLabel>
                    <Textarea _focus={{ borderColor: 'primary.500', boxShadow: 'none', borderWidth: '2px' }} value={emailsText} placeholder="john@brown.edu" onChange={e => setEmailsText(e.target.value)} />

                    {error && <Alert my={'3'} status='error'>
                                <AlertIcon />
                                <AlertTitle>{ error }</AlertTitle>
                            </Alert>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onAddClick} fontWeight={'400'} colorScheme={'primary'}>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}