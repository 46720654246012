import { Box, Button, Divider, Flex, Heading, ListItem, Img, OrderedList, SimpleGrid, Stack, Tag, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import Nav from "./Nav";
import { signOut } from "firebase/auth";
import { auth } from "../../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import useUserDoc from "../../hooks/useUserDoc";
import { useNavigate } from "react-router-dom";
import Container from "./Container";
import Footer from "../../ui/Footer";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { loginUserWithPopup } from "../../lib/login";
import { useFirebaseRoutesAnalytics } from "../../hooks/useFirebaseRoutesAnalytics";
import { useEffect, useState } from "react";

export default function Home() {
    useFirebaseRoutesAnalytics()

    const [user, userLoading, userError] = useAuthState(auth)
    const [userDoc, isUserDoc] = useUserDoc(user)
    const navigate = useNavigate()
    const [shouldRouteToDashboard, setShouldRouteToDashboard] = useState(false)

    const handleLoginClick = () => {
        setShouldRouteToDashboard(true)
        loginUserWithPopup()
    }

    useEffect(() => {
        if(isUserDoc && shouldRouteToDashboard) navigate('/app/dashboard')
    }, [shouldRouteToDashboard, isUserDoc])

    return (
        <Box minH={'calc(100vh)'} bg={useColorModeValue('slate.100', 'slate.800')}>
            <Nav 
                enterApp={() => navigate("/app/dashboard")} 
                onClickLogin={handleLoginClick} 
                loggedIn={!!user} 
                onClickLogout={() => signOut(auth)}
            />
            <Container bg={useColorModeValue('white', 'slate.900')}>
                <Hero 
                    onClickGetStarted={() => isUserDoc ? navigate("/app/dashboard") : handleLoginClick()}
                />
            </Container>
            <Container bg={useColorModeValue('slate.100', 'slate.800')}>
                <HowItWorks />
            </Container>
            <Container bg={useColorModeValue('white', 'slate.900')}>
                <StudentsTAsTeachers />
            </Container>
            <Footer />
        </Box>
    );
}

function Hero({ onClickGetStarted }){
    const textStyle = {
        backgroundClip: 'text',
        color: 'transparent',
        fontWeight: 'black',
        fontSize: { base: '5xl', md: '6xl' },
        mb: 8,
        
        backgroundImage: '/gradient.jpg',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    };
    
    return (
        <Box textAlign={{'base': 'left', 'md': 'center'}} maxW='4xl' m='auto'>
            <Text fontSize={{'base': '4xl', 'md': '4xl'}} m='auto' maxW='3xl' fontWeight={'thin'} color={useColorModeValue('slate.500', 'slate.500')} pb='6'>
                Introducing
            </Text>
            <Heading fontSize={{'base': '6xl', 'md': '6xl'}} fontWeight={'black'} mb='8' color={useColorModeValue('primary.500', 'primary.500')}> Artificial Teaching Assistant </Heading>
            <Text fontSize={{'base': '2xl', 'md': '2xl'}} m='auto' maxW='3xl' fontWeight={'bold'} color={useColorModeValue('slate.700', 'slate.100')} pb='6'>
                AI-powered 24/7 access to personalized, interactive, and course-specific help for students. Direct and actionable insights for staff.
            </Text>
            <Flex flexDir={{base: 'column', md: 'row'}} alignItems={{'base': 'start', 'md': 'center'}} justify={'center'} mt={6} mb={16} gap={8}>
                <Button color='white' onClick={onClickGetStarted} rightIcon={<ArrowForwardIcon boxSize={'28px'} />} size={'lg'} px={16} py={6} colorScheme={'primary'}>Get started</Button>
            </Flex>
            <Text py={6} color='slate.400' fontSize={{'base': 'md', 'md': 'lg'}} maxW='2xl' m='auto'>Currently only open to select courses at Brown University. If that's not you, send us an email and we'll let you know when we launch.</Text>
            <a href="mailto:team@talktoata.com" target="_blank">
                <Button size='lg' px={16} py={6} fontWeight={'400'} colorScheme={'slate'} variant={'ghost'} _hover={{ bg: useColorModeValue('slate.100', 'slate.800') }}>team@talktoata.com</Button>
            </a>
        </Box>
    )
}


function WhyATA({  }){
    return (
        <Box textAlign={'center'}>
            <Heading fontSize='5xl' fontWeight={'700'} mb='4'>
                Our Mission
            </Heading>
            <Text fontSize='xl'>Inspired by our firsthand experiences in the Brown CS department, we at ATA are committed to closing the gap between the demand for personalized educational support and the constraints of traditional TA resources. Our mission is to empower students with instant, personalized, and interactive learning experiences facilitated by the latest AI technologies.</Text>
        </Box>
    )
}

function StudentsTAsTeachers({  }){
    const data = [
        {
            title: 'For Students',
            subtitle: 'ATA provides a course-immersed, intuitive learning experience',
            items: [
                { icon: '/bolt.svg', title: 'Instant Access', subtitle: 'Obtain immediate help when you need it' },
                { icon: '/person.svg', title: 'Personalized Assistance', subtitle: 'Benefit from support that\'s informed by your course\'s assignments, code, TA forums, and more' },
                { icon: '/comments.svg', title: 'Interactive Learning', subtitle: 'Engage with material through socratic prompting and guidance' },
            ]
        },
        {
            title: 'For TAs',
            subtitle: 'As a TA sidekick, ATA allows TAs to focus on the complex questions',
            items: [
                { icon: '/magnifying-glass.svg', title: 'Focus on Complex Issues', subtitle: 'Reduce time spent on frequently asked questions' },
                { icon: '/check-double.svg', title: 'Enhance Productivity', subtitle: 'Leverage AI to manage and prioritize student queries' },
                { icon: '/person-chalkboard.svg', title: 'Improve Teaching', subtitle: 'Use advanced AI tools to offer constructive and pedagogically sound guidance' },
            ]
        },
        {
            title: 'For Professors',
            subtitle: 'ATA provides a sleek interface for measuring engagement and understanding',
            items: [
                { icon: '/upload.svg', title: 'Effortless Integration', subtitle: 'Easily upload course materials' },
                { icon: '/chart-simple.svg', title: 'Curated Insights', subtitle: 'Gain access to valuable data on common student pain points' },
                { icon: '/gear.svg', title: 'Customizability', subtitle: "Tailor ATA's responsiveness with tuneable prompting" },
            ]
        },
    ]

    return (
        <Stack textAlign={'center'} gap={16} divider={<Divider mt='0' />}>
            { data.map(({ title, subtitle, items }, i) => 
                <Box key={i}>
                    <Heading fontSize={'5xl'} fontWeight={'700'} mb='2'>{ title }</Heading>
                    <Text maxW='3xl' m='auto' fontSize={'xl'}>{ subtitle }</Text>
                    <SimpleGrid pt='16' columns={{base: 1, lg: items.length}} gap={'8'}>
                        { items.map(({icon, title, subtitle}, j) => 
                            <Flex key={j} flexDir={'column'} alignItems={'center'}>
                                <Img width={100} height={100} p={7} color='primary.500' src={icon}></Img>
                                <Text mt='2' fontSize={'xl'} fontWeight={700}>{title}</Text>
                                <Text fontSize={'lg'}>{subtitle}</Text>
                            </Flex>
                        ) }
                    </SimpleGrid>
                </Box>
            ) }
        </Stack>
    )
}

function HowItWorks({  }){
    const items = [
        { title: 'Course Integration', body: 'ATA leverages assignment specifications, syllabi, code, slides, and relevant discussions to provide a course-curated experience.' },
        { title: 'Intelligent Interaction', body: 'Through optimized language model prompting, including the latest in retreival-augmented generation, few-shot learning, and chain-of-thought, ATA intelligently interacts with students.' },
        { title: 'Student Learning', body: 'With a multi-agent architecture informed by the latest in higher education research, ATA provides a constructive, engaging learning experience.' },
    ]

    return (
        <Flex maxW='3xl' m='auto' flexDir={'column'} alignItems={{'base': 'start', 'md': 'center'}}>
            <Heading mb='6' fontSize='5xl' fontWeight={'700'}>How it works</Heading>
            <Text fontSize={'xl'} textAlign={{'base': 'left', 'md': 'center'}} mb='6'>ATA unifies the state-of-the-art in large language modeling, retrieval-augmented generation, and education research.</Text>
            <OrderedList>
                { items.map(({title, body}, i) => <ListItem key={i} mb='6' fontSize='xl' style={{ textAlign: 'justify' }}><strong>{title}</strong>: {body}</ListItem>) }
            </OrderedList>
        </Flex>
    )
}