import { Box, Center, Flex, Spinner } from "@chakra-ui/react";
import AppNav from "../../ui/AppNav";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../lib/firebase";

export default function App({ }) {

    const [user, userLoading, userError] = useAuthState(auth)
    const onSharePage = !!useMatch("/app/:courseId/share/:conversationId")

    const navigate = useNavigate()

    useEffect(() => {
        if(userLoading) return

        if(!user && !onSharePage) navigate("/")
    }, [user, userLoading, onSharePage])


    return (
        <Flex h='calc(100vh)' flexDir='column'>
            <AppNav photoURL={user?.photoURL} />

            {userLoading ?
                <Center h='full'><Spinner /></Center> :
                <Box
                    overflowY={'scroll'}
                    h='full'
                    flexGrow={1}
                    sx={{
                        "::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <Outlet />
                </Box>
            }
        </Flex>
    )
}