import { Box, Center, Heading, Spinner, Text, Link, Icon } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useCourseFeedback from "../../../hooks/useCourseFeedback";
import TableWithSorting, { Column, compareNumbers, compareStrings, compareTimestamps } from "../users/TableWithSorting";
import { Conversation } from "../../../types/types";

import { Link as ReactRouterLink } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { compareDates, formatDateShort } from "../../../lib/date_utils";


export default function Feedback() {
    const params = useParams()
    const [conversationsWithFeedback, isLoading] = useCourseFeedback(params.courseId)

    if (isLoading) return <Box><Heading>Feedback</Heading> <Center py='32'><Spinner /></Center></Box>

    return (
        <Box>
            <Heading mb='3'>Feedback</Heading>
            <Text>{conversationsWithFeedback.length} conversations with feedback</Text>
            {/* <Text>30 is the default helpfulness</Text> */}
            <FeedbackTable 
                conversations={conversationsWithFeedback}
                courseId={params.courseId}
            />
        </Box>
    )
}

function FeedbackTable({ courseId, conversations }) {
    const feedbackTableColumns: Column<Conversation>[] = [
        {
            title: 'Conversation',
            isNumeric: false,
            compareFunc: (c1: Conversation, c2: Conversation) => compareStrings(c1.title, c2.title),
            accessor: ({ title, id }) => <Link as={ReactRouterLink} gap='2' display={'flex'} alignItems={'center'} to={`/app/${courseId}/share/${id}`}>{title}<Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400' /></Link>
        },
        {
            title: 'Helpfulness (out of 70)',
            isNumeric: true,
            accessor: (c1: Conversation) => c1.feedback[c1.feedback.length - 1].helpfulness,
            compareFunc: (c1, c2) => compareNumbers(c1.feedback[c1.feedback.length - 1].helpfulness, c2.feedback[c2.feedback.length - 1].helpfulness),
        },
        {
            title: 'Issues',
            isNumeric: false,
            accessor: (c: Conversation) => <Text fontSize={'sm'} whiteSpace="normal" wordBreak="break-word">{c.feedback[c.feedback.length - 1].issues.join(", ")}</Text>,
            compareFunc: (c1: Conversation, c2: Conversation) => compareNumbers(c1.feedback[c1.feedback.length - 1].issues.length, c2.feedback[c2.feedback.length - 1].issues.length)
        },
        {
            title: 'Comment',
            isNumeric: false,
            accessor: (c: Conversation) => <Text fontSize={'sm'} whiteSpace="normal" wordBreak="break-word">{c.feedback[c.feedback.length - 1].comments}</Text>,
            compareFunc: (c1: Conversation, c2: Conversation) => compareNumbers(c1.feedback[c1.feedback.length - 1].comments.length, c2.feedback[c2.feedback.length - 1].comments.length)
        },
        {
            title: 'Date Last Updated',
            accessor: ({timestampLastUpdated}) => formatDateShort(timestampLastUpdated.toDate()),
            isNumeric: false,
            compareFunc: ({timestampLastUpdated: timestamp1}, {timestampLastUpdated: timestamp2}) => compareTimestamps(timestamp1, timestamp2)
        },
        {
            title: '# Exchanges',
            isNumeric: true,
            compareFunc: (c1, c2) => compareNumbers(c1.numResponses, c2.numResponses),
            accessor: (c) => c.numResponses
        },
    ]

    return (
        <TableWithSorting
            columns={feedbackTableColumns}
            items={conversations}
            name="feedback-table"
        />
    )
}