import { DocumentReference, Timestamp } from "firebase/firestore";

export type modeType = 'conceptual' | 'debugger' | 'summarizer'
export type modelType = 'gpt-4o' | 'claude-3-5-sonnet-20240620' | 'claude-3-haiku-20240307'

export type Semester = 'Summer' | 'Fall' | 'Spring'

export type User = {
    uid?: string
    email: string
    exists?: boolean
    courses: string[]
    displayName: string,
    photoURL: string,
    adminCourses: string[]
    bootedCourses: string[]
    notificationsSeen: number
}

export const validFilterDecisions = ['SOCRATIC', 'MALICIOUS', 'OFFTOPIC', 'ENGAGE']
export const validActionDecisions = ['DEFLECT', 'SOCRATIC', 'INFORMATION', 'DIAGNOSTIC', 'HINT']
export type FilterDecision = 'SOCRATIC' | 'MALICIOUS' | 'OFFTOPIC' | 'ENGAGE'
export type ActionDecision = 'DEFLECT' | 'SOCRATIC' | 'INFORMATION' | 'DIAGNOSTIC' | 'HINT'

type Summary = {
    numExchanges: number
    numTotalConversations: number
    numConversationsStarted: number
    filterDecisionCounts: {
        [key in FilterDecision]: number
    }
    actionCounts: {
        [key in ActionDecision]: number
    }
    activeUIDs: string[]
    averageConversationLength: number
    averageExchangeCost: number
    numActiveUsers: number
    timestamp: Timestamp
}

export type HourSummary = Summary
export type DaySummary = Summary & { hourSummaries: HourSummary[], id: string }

export type Query = {
    queryText: string
    resourceClass: string
    resourceName: string
    uid: string
    conversationDocId: string
    userName: string
    courseId: string
}

export type UserCourseDoc = {
    totalExchanges: number
    days: {
        [key: string]: { queriesSent: number, maliciousQueries: number }
    }
    filterDecisionCounts: {
        [key in FilterDecision]: number
    }
    actionCounts: {
        [key in ActionDecision]: number
    }
    totalConversations: number
    conversations: {
        [key: string]: {
            title: string
            timestampLastUpdated: Timestamp
            resourceName: string
            resourceClass: string
            isTerminated: boolean
            isArchived?: boolean
        }
    }
    timestampLastQueried: Timestamp
    timestampLastRegenerated: Timestamp
    queriesLeft: number
}

export type Conversation = {
    id: string
    title: string
    timestampCreated: Timestamp
    timestampLastUpdated: Timestamp
    responses: Exchange[] 
    isArchived: boolean
    userName: string
    userDocRef: DocumentReference
    resourceName: string
    resourceClass: string
    maliciousQueries: number
    offTopicQueries: number
    tutorState: object
    isTerminated: boolean
    lastTutorEngineVersion: string
    hasFeedback: boolean
    feedback: Feedback[]
    numResponses: number
    numAnnotations?: number,
    annotations?: {
        responseLevelAnnotations: ResponseLevelAnnotation[],
        // conversationLevelAnnotation: ConversationLevelAnnotation,
        annotator: Annotator,
        timestampLastEdited: Timestamp
    }[]
}

export type Annotator = {
    displayName: string,
    uid: string
}

export type Feedback = {
    helpfulness: number
    issues: string[]
    comments: string
}

export type Cluster = {
    topic: { id: string, query: string },
    conversations: { id: string, query: string }[]
}

export type ClusterDoc = {
    resourceClassClusters: {
        [key: string]: Cluster[]
    },
    resourceClusters: {
        [key: string]: {
            [key: string]: Cluster[]
        }
    }
}

export type Exchange = {
    queryText: string
    responseText: string
    timestamp: Timestamp
    log: ExchangeLog
    sources: { [key: string]: Source[] }
    stats: ExchangeStats
    action: ActionDecision
    isSubtaskCompleted: boolean
    isSuggest: boolean
    isTerminalExchange: boolean
    tutorEngineVersion: string
}

export type ExchangeLog = {
    agents: {
        [key: string]: {
            output: string
            log: {
                [key: string]: string
            }
            usage: {
                input_tokens: number,
                output_tokens: number,
                elapsed: number,
                model: string
            }
        }
    }
}

export type ExchangeStats = {
    elapsed: number,
    usage: {
        [key: string]: {
            input_tokens: number,
            output_tokens: number
        }
    },
    timestamp: Timestamp
}

export type Source = {
    title: string
    data: string
    url: string
}

export type SourceType = 'specs' | 'stencil' | 'support' | 'slides'

export type Course = {
    title: string
    year: number
    semester: Semester    
    id: string
    institution: string
    controls: {
        pastingDisabled: boolean
        anonymizeStudents: boolean
        quota: {
            minutesUntilIncrement: number
            maxQueriesPerDay: number
            maxQueriesAccumulated: number
        }
    },
    schema: {
        [key: string]: SourceType[]
    },
    resources: {
        [key: string]: {
            [key: string]: {
                [key: string]: {}
            }
        }
    }
}

export type ExtraCourseData = {
    [key: string]: {
        [key: string]: ResourceMetadata
    }
}

export type ResourceMetadata = {
    title: string
    resourceClass?: string
    releaseDate?: string
    dueDate?: string
    topics: string[]
    prereqs: string[]
    stub: string[]
    summary: string
}


export type UserListDocument = {
    userEmails: string[],
    adminEmails: string[]
}

export type ResponseLevelAnnotation = {
    likert: number,
    issues: string[],
    comment: string,
}

// export type ConversationLevelAnnotation = ResponseLevelAnnotation