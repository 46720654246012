import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useURLParams() {
    const [urlParams, setURLParams] = useState(new URLSearchParams());
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setURLParams(params);
        setLoading(false);
    }, [location.search]);

    return [urlParams, loading] as [URLSearchParams, boolean]
}