'use client'

import { Box, Button, Card, Code, Divider, Flex, Heading, HStack, Image, Link, ListItem, OrderedList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Portal, Spinner, Tag, TagLabel, TagLeftIcon, Text, UnorderedList, useBreakpointValue, useColorModeValue, useDisclosure, usePrefersReducedMotion } from "@chakra-ui/react"
import { ReactNode, useEffect, useState } from "react";
import Markdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight, atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import './styles.css'
import { ExchangeLog, ExchangeStats, Source, SourceType } from "../../types/types";
import { capitalizeWords, wrapLatex } from "../../lib/text_utils";
import { ChatIcon } from "@chakra-ui/icons"
import { BiSlideshow } from "react-icons/bi";
import { IoMdDocument } from "react-icons/io";
import { FaCalendar, FaFileCode } from "react-icons/fa";
import { GrTextAlignFull } from "react-icons/gr";
import React from "react";
import { parseInfoFromSourceId, REFERENCE_ID_REGEX } from "./SourcesPane";
import { useNavigate, useParams } from "react-router-dom";
import { unBlockConversation } from "../../lib/database";
import FeedbackBody from "./FeedbackBody";
import LoadingTextSpinner from "./LoadingTextSpinner";
import { Link as ReactRouterLink } from "react-router-dom";
import { CS15_COURSE_CALENDAR, CS15_EDSTEM_LINK, RESPONSE_LOADING_TEXT, SUGGEST_MESSAGE } from "../../lib/CONSTANTS";


const merge = ['calendar']


export default function Response({ speaker, content, sendFeedback, showFeedback, loadingMessage = "", sources, stats, isAdmin, log, onReferenceTagClick, lastFeedback, isSubtaskCompleted, isSuggest, isTerminalExchange, isShare, userName }) {
    const [localIsSuggest, setLocalIsSuggest] = useState(isSuggest)
    const [pendingTurnOffIsSuggest, setPendingTurnOffIsSuggest] = useState(false)

    const params = useParams()
    const urlParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate()

    useEffect(() => {
        // log is only defined when this data is from the convo doc (probably should be improved later)
        if(!log || !pendingTurnOffIsSuggest || !urlParams.has("id")) return

        unBlockConversation(params.courseId, urlParams.get("id"))
    }, [pendingTurnOffIsSuggest, urlParams, log])

    useEffect(() => {
        setLocalIsSuggest(isSuggest)
    }, [isSuggest])

    let sourcesList = []
    const sourceTitles = []
    const sourceTypes = []
    if (sources) {
        for (const [sourceType, _sources] of Object.entries(sources as { [key: string]: Source[] })) {
            for (const s of _sources) {
                // accumulate sourcesList for tags
                if (merge.includes(sourceType)) {
                    if (!sourceTypes.includes(sourceType)) {
                        sourceTypes.push(sourceType)
                        sourceTitles.push(s.title)
                        sourcesList.push({ ...s, sourceType, title: sourceType })
                    }
                    continue
                }
                if (!sourceTitles.includes(s.title)) {
                    sourceTitles.push(s.title)
                    sourcesList.push({ ...s, sourceType })
                }
            }

        }
    }

    const onClickContinue = () => {
        setPendingTurnOffIsSuggest(true)
        setLocalIsSuggest(false)
    }

    const feedbackValues: any = {}
    if (lastFeedback !== undefined){
        feedbackValues.initialScaleValue = lastFeedback.helpfulness
        feedbackValues.initialTextValue = lastFeedback.comments
        feedbackValues.initialIssues = lastFeedback.issues
    }

    return (
        <ResponseShell isAdmin={isAdmin} userName={userName} speaker={speaker} isShare={isShare}>
            { showFeedback && <FeedbackBody sendFeedback={sendFeedback} {...feedbackValues} inChat /> }


            {(!showFeedback && loadingMessage && (!localIsSuggest)) && <LoadingTextSpinner h='120px' loadingTextList={RESPONSE_LOADING_TEXT} />}
            {(!showFeedback && !loadingMessage && !localIsSuggest) && <LatexMarkdownFormattedText onReferenceTagClick={onReferenceTagClick} content={wrapLatex(content)} />}

            {!showFeedback && localIsSuggest && <SuggestBreakOrTA isShare={isShare} onClickBreak={() => navigate("/app/dashboard")} onClickContinue={onClickContinue} />}

            {/* {(isTerminalExchange && !isShare) && <>
                <Divider my='6' />
                <Text mb='3' fontStyle={'italic'}>You have completed all learning objectives for this conversation! Please give ATA some feedback below and then start a new conversation if you want to chat more.</Text>
                <FeedbackBody conversationDocId={urlParams.has("id") ? urlParams.get("id") : undefined} courseId={params.courseId} />
            </>} */}


            {(sourcesList.length > 0 && !isSuggest && !showFeedback) && <Flex gap='2' mt='3' flexWrap={'wrap'}>
                {sourcesList.map((source, i) => <SourceTag {...source} key={i} />)}
            </Flex>}

            {/* {isAdmin && <AdminInfo
                stats={stats}
                sources={sources}
                log={log}
            />} */}
        </ResponseShell>


    )
}

export function ResponseShell({ speaker, children, isAdmin, userName, isShare }) {

    const speakerTitle = speaker === "" 
        ? ""
        : speaker === "tutor" 
            ? "ATA" 
            : isShare 
                ? (isAdmin ? userName : "Anonymous") 
                : "You";

    return (
        <Flex w='full' flexDir={['column', null, null, 'row']} flexGrow={1} gap={4} role="group">
            <Box flexShrink={0} w={[8]} h={8} pt='2' position='relative'>
                {speakerTitle && (
                    <img 
                        alt={speaker === 'tutor' ? 'ATA icon' : 'User icon'}
                        src={speaker === 'tutor' ? '/ata_stool_icon.png' : '/user_icon.png'}
                    />
                )}
            </Box>
            <Card
                w='full'
                flexGrow={1}
                maxW={['full', null, null, 'calc(100% - 3.25rem)']}
                borderRadius={3}
                p='3'
                borderWidth={1}
                borderColor={useColorModeValue('slate.200', 'slate.700')}
                boxShadow={'none'}
            >
                <Text fontWeight={'bold'}>{ speakerTitle }</Text>

                {children}

            </Card>
        </Flex>
    )
}

function SuggestBreakOrTA({ onClickBreak, onClickContinue, isShare }) {
    return (
        <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} py='6' gap='3' mb='3'>
            <Text fontSize={'lg'}>{SUGGEST_MESSAGE}</Text>
            <HStack gap='3' justifyContent={'center'} flexWrap={'wrap'}>
                <Button fontWeight='400' color='white' as={ReactRouterLink} disabled={isShare} to={CS15_EDSTEM_LINK} target="_blank" colorScheme={'green'}>Open EdStem</Button>
                <Button fontWeight='400' color='white' as={ReactRouterLink} disabled={isShare} to={CS15_COURSE_CALENDAR} target="_blank" colorScheme={'blue'}>Open Hours Calendar</Button>
                {/* <Button disabled={isShare} onClick={() => !isShare && onClickBreak()} colorScheme={'primary'} fontWeight={'400'}>Yes, take a break/see a TA</Button> */}
                <Button color='white' disabled={isShare} onClick={() => !isShare && onClickContinue()} colorScheme={'slate'} fontWeight={'400'}>No, continue</Button>
            </HStack>
        </Flex>
    )
}

const SOURCE_TYPE_TO_COLOR = {
    "edstem": "green",
    "handout": "blue",
    "website": "blue",
    "code": "orange",
    "slides": "yellow",
    "notes": "purple",
    "transcript": "purple",
}

const SOURCE_TYPE_TO_ICON = {
    "edstem": ChatIcon,
    "handout": IoMdDocument,
    "website": IoMdDocument,
    "code": FaFileCode,
    "slides": BiSlideshow,
    "calendar": FaCalendar,
    "notes": GrTextAlignFull,
    "transcript": GrTextAlignFull
}

function SourceTag({ title, data, url, sourceType }: Source & { sourceType: SourceType }) {

    const tokens = title.split(".")
    if(tokens.length > 1){
        tokens.splice(tokens.length-1, 1)
    }
    const prettyTitle = tokens.join(" ").replaceAll("_", " ")

    return (
        <Tag size={'md'} variant='subtle' colorScheme={SOURCE_TYPE_TO_COLOR[sourceType]}>
            <TagLeftIcon boxSize='12px' as={SOURCE_TYPE_TO_ICON[sourceType]} />
            <TagLabel>{prettyTitle}</TagLabel>
        </Tag>
    )
}

function AdminInfo({ stats, sources, log }: {
    stats: ExchangeStats, sources: { [key: string]: Source[] }, log: ExchangeLog
}) {
    const buttonColor = useColorModeValue('slate.200', 'slate.600')
    const hoverButtonColor = useColorModeValue('slate.300', 'slate.500')

    if (!stats || !log) return <></>

    // const agentsToTags = {
    //     'MetaAgent': ['scratchpad', 'action'],
    //     'EvaluateLearningObjectiveAgent': ['scratchpad', 'is_student_intent_clear'],
    //     'StudentKnowledgeAgent': ['scratchpad', 'tutor'],
    //     'PlannerAgent': ['scratchpad', 'learning_objective', 'planned_subtasks'],
    //     'InformationAgent': ['scratchpad', 'plan', 'tutor'],
    //     'HintAgent': ['scratchpad', 'plan', 'tutor'],
    //     'SocraticQuestionAgent': ['scratchpad', 'plan', 'tutor'],
    //     'DiagnosticAgent': ['scratchpad', 'plan', 'tutor'],
    //     'FilterAgent': ['scratchpad', 'nature_of_query'],
    //     'DeflectAgent': ['tutor'],
    //     'SuggestAgent': ['plan', 'tutor'],
    //     'RefineLearningObjectiveAgent': ['scratchpad', 'tutor'],
    //     'SubtaskTerminationAgent': ['scratchpad', 'termination_decision']
    // }

    let cost = undefined
    if (Object.keys(stats.usage).includes('gpt-4o')) {
        cost = (stats.usage['gpt-4o'].input_tokens / 1_000_000 * 5) + (stats.usage['gpt-4o'].output_tokens / 1_000_000 * 15)
    } else if (Object.keys(stats.usage).includes('claude-3-5-sonnet-20240620')) {
        cost = (stats.usage['claude-3-5-sonnet-20240620'].input_tokens / 1_000_000 * 3) + (stats.usage['claude-3-5-sonnet-20240620'].output_tokens / 1_000_000 * 15)
    }

    return (
        <Flex mt='3' flexDir={'row'} gap={5} alignItems={'center'} flexWrap={'wrap'}>
            {stats?.usage && Object.entries(stats.usage).map(([model, usage], i) => (
                <Text key={i}><strong>{model} in/out</strong> <br /> {usage.input_tokens} / {usage.output_tokens} </Text>
            ))
            }

            {cost && <Text><strong>Cost</strong> <br /> ${cost.toFixed(5)}</Text>}

            {stats?.elapsed && <Text><strong>Elapsed</strong> <br /> {Math.round(stats.elapsed)}s</Text>}

            { Object.keys(log).includes('submodules') ? Object.entries(log.agents).map(([agent, { log: agentLog }], i) =>
                Object.entries(agentLog).map(([tag, output], j) => <AgentPopup agent={agent} tag={tag} output={output} key={`${i}-${j}`} />)
            ) : Object.entries(log).map(([agent, { output }], i) =>
                Object.entries(output).map(([tag, output], j) => <AgentPopup agent={agent} tag={tag} output={output} key={`${i}-${j}`} />)
            )
            }

            {sources && <Box>
                <Text><strong>Sources:</strong></Text>
                <Flex flexDir={'row'} alignItems={'center'} gap={2}>
                    <br />
                    {Object.entries(sources).map(([rtype, chunks], i) => chunks.length > 0 &&
                        <Popover key={i} size={'xl'}>
                            <PopoverTrigger>
                                <Button size='sm' fontWeight={'400'} bg={buttonColor} _hover={{ bg: hoverButtonColor }}>{rtype}</Button>
                            </PopoverTrigger>
                            <PopoverContent w='xl' rootProps={{ style: { transform: 'scale(0)' } }}>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>{rtype} chunks</PopoverHeader>
                                <PopoverBody maxH='calc(300px)' overflowY={'scroll'}>
                                    <LatexMarkdownFormattedText onReferenceTagClick={console.log} content={chunks.map(({ data }, _) => data).join("\n\n")} isSmall />
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                </Flex>
            </Box>}
        </Flex>
    )
}

function AgentPopup({ agent, output, tag }) {
    const buttonColor = useColorModeValue('slate.200', 'slate.600')
    const hoverButtonColor = useColorModeValue('slate.300', 'slate.500')

    return (
        <Popover size='xl'>
            <PopoverTrigger>
                <Button size='sm' fontWeight='400' bg={buttonColor} _hover={{ bg: hoverButtonColor }}>{agent}/{tag}</Button>
            </PopoverTrigger>
            <PopoverContent w='xl' rootProps={{ style: { transform: 'scale(0)' } }} >
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{tag}</PopoverHeader>
                <PopoverBody maxH='calc(300px)' overflowY='scroll'>
                    <LatexMarkdownFormattedText content={output} onReferenceTagClick={console.log} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export function LatexMarkdownFormattedText({ content, onReferenceTagClick, isSmall = false }) {
    const codeBlockStyle = useColorModeValue(oneLight, atomDark)
    const dividerColor = useColorModeValue('slate.200', 'slate.600')

    const normalTextSize = useBreakpointValue({
        'base': 'md',
        'lg': 'lg'
    })
    const textSize = isSmall ? 'sm' : normalTextSize

    const listLeftMargin = useBreakpointValue({
        'base': 4,
        'lg': 6
    })

    return (
        <Box as={Markdown}
            mb='-6'
            children={content}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
                code(props) {
                    const { children, className, node, ...rest } = props
                    const match = /language-(\w+)/.exec(className || '')
                    return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            className="syntax-highlighter"
                            children={String(children).replace(/\n$/, '')}
                            customStyle={{ 'overflowX': 'scroll' }}
                            language={match[1]}
                            style={codeBlockStyle}
                            wrapLongLines={true}
                        // wrapLines

                        />
                    ) : (
                        // <code {...rest} className={className}>
                        //     {children}
                        // </code>
                        <Code fontSize={textSize} {...rest} className={className}>
                            {children}
                        </Code>
                    )
                },
                p(props) {
                    return <ReferenceTagWrapper
                        onReferenceTagClick={onReferenceTagClick}
                        children={props.children}
                        Component={Text}
                        componentProps={{ mb: 6, fontSize: textSize, wordBreak: 'break-word' }}
                    />
                },
                ol(props) {
                    return <ReferenceTagWrapper
                        onReferenceTagClick={onReferenceTagClick}
                        children={props.children}
                        Component={OrderedList}
                        componentProps={{ mb: 6, ml: listLeftMargin }}
                    />
                },
                blockquote(props) {
                    return <BlockQuote {...props} />
                },
                ul(props) {
                    return <ReferenceTagWrapper
                        onReferenceTagClick={onReferenceTagClick}
                        children={props.children}
                        Component={UnorderedList}
                        componentProps={{ mb: 6, ml: listLeftMargin }}
                    />
                },
                li(props) {
                    return <ListItem fontSize={textSize} listStylePos={'outside'}>{props.children}</ListItem>
                },
                a(props) {
                    return <Link color={'primary.600'} as={'a'} href={props.href} target="_blank">{props.children}</Link>
                },
                h1(props) { return <Heading size='xl'>{props.children}</Heading> },
                h2(props) { return <Heading size='lg'>{props.children}</Heading> },
                h3(props) { return <Heading size='md'>{props.children}</Heading> },
                h4(props) { return <Heading size='sm'>{props.children}</Heading> },
                h5(props) { return <Heading size='sm'>{props.children}</Heading> },
                h6(props) { return <Heading size='sm'>{props.children}</Heading> },
                hr(props) {
                    return <Divider my='6' border='1px' borderColor={dividerColor} />
                }

            }}
        />
    )
}

const ReferenceTagWrapper = ({ children, componentProps, Component, onReferenceTagClick }) => {
    const renderContent = (node: ReactNode) => {
        if (typeof node === 'string') {

            const parts = node.split(REFERENCE_ID_REGEX)

            return parts.map((part, index) => {
                if (index % 2 === 0) {
                    // Regular text
                    return part;
                } else {
                    // Page ID
                    return <ReferenceTag id={part.split(">")[1].split("<")[0]} key={index} onClick={onReferenceTagClick} />
                }
            });
        }

        if (React.isValidElement(node)) {
            const { children: nodeChildren, ...props } = node.props;

            // merge broken strings
            const mergedChildren = []
            if(Array.isArray(nodeChildren)){
                let currentString = ""
                for(const child of nodeChildren){
                    if(typeof child === 'string'){
                        currentString += child
                    }else{
                        if(currentString.length > 0){
                            mergedChildren.push(currentString)
                            currentString = ""
                        }
                        mergedChildren.push(child)
                    }
                }
    
                if(currentString.length > 0) mergedChildren.push(currentString)
            }else{
                mergedChildren.push(nodeChildren)
            }

            return React.cloneElement(
                node,
                props,
                React.Children.map(mergedChildren, renderContent)
            );
        }

        if (Array.isArray(node)) {

            // merge broken strings
            const mergedChildren = []
            let currentString = ""
            for(const child of node){
                if(typeof child === 'string'){
                    currentString += child
                }else{
                    if(currentString.length > 0){
                        mergedChildren.push(currentString)
                        currentString = ""
                    }
                    mergedChildren.push(child)
                }
            }

            if(currentString.length > 0) mergedChildren.push(currentString)

            return mergedChildren.map((child, index) => (
                <React.Fragment key={index}>
                    {renderContent(child)}
                </React.Fragment>
            ))
        }

        return node;
    };

    return <Component {...componentProps}>{renderContent(children)}</Component>;
}

function ReferenceTag({ id, onClick }: { id: string, onClick: (id: string) => void }) {
    const [resourceClass, resourceName, sourceType, number] = parseInfoFromSourceId(id)

    return (
        <Tag
            onClick={() => sourceType && onClick(id)}
            colorScheme={'primary'}
            size='md'
            // mt='1' 
            cursor={sourceType ? 'pointer' : 'text'}
        // _hover={{ transform: 'scale(1.05, 1.05)' }}
        // transition={'0.2s'}
        >
            {/* { resourceName }  */}
            { sourceType ? capitalizeWords(sourceType) : id}
            {sourceType && ' '}
            { sourceType && number }
        </Tag>
    )
}

function BlockQuote(props) {
    return (
        <Box borderLeftWidth={5} borderColor={'slate.200'} pl='3' fontStyle={'italic'}>
            {props.children}
        </Box>
    )
}

function ContextImages({ srcs }) {
    return (
        <Flex flexDir={'row'} gap='3' flexWrap={'wrap'} my='3'>
            {srcs.map((src, i) => <BlowUpImage src={src} key={i} />)}
        </Flex>
    )
}

function HoverImage({ src }) {
    return (
        <Box
            borderWidth={2}
            borderColor={'slate.200'}
            dropShadow={'md'}
        >
            <Image
                src={src}
                width='200px'
                height='auto'
            />
        </Box>
    )
}


const BlowUpImage = ({ src }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box>
            <Image
                src={src}
                width='200px'
                height='auto'
                onMouseEnter={onOpen}
            />
            {isOpen && (
                <Portal>
                    <Box
                        position="absolute"
                        top={'50%'}
                        borderWidth={'2'}
                        borderColor='slate.200'
                        left={'50%'}
                        transform='auto'
                        translateX={'-50%'}
                        translateY={'-50%'}
                        zIndex="popover"
                        onMouseLeave={onClose}
                        boxShadow="xl"
                    >
                        <Image src={src} width='calc(75vw)' height='auto' />
                    </Box>
                </Portal>
            )}
        </Box>
    )
}