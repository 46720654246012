import { Card, CardBody, Center, Flex, FormLabel, Spinner, Switch } from "@chakra-ui/react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useParams, useNavigate } from "react-router-dom";
import useCourseConversations from "../../../hooks/useCourseConversations";
import useSingleCourse from "../../../hooks/useSingleCourse";
import useUserDoc from "../../../hooks/useUserDoc";
import { NUM_ADMIN_CONVERSATIONS_TO_FETCH } from "../../../lib/CONSTANTS";
import { auth } from "../../../lib/firebase";
import { updateCourseControl } from "../../../lib/database";


export default function Controls() {
    const [user, userLoading, userError] = useAuthState(auth)
    const params = useParams()

    const [course, courseLoading] = useSingleCourse(params.courseId)

    if (courseLoading) return <Card w='full'><CardBody><Center><Spinner /></Center></CardBody></Card>

    return (
        <Flex gap='3' flexDir={'column'}>
            <Flex alignItems={"center"}>
                <Switch
                    id="pastingDisabled"
                    isChecked={course.controls.pastingDisabled}
                    colorScheme={'primary'}
                    onChange={(e) => updateCourseControl(params.courseId, 'pastingDisabled', e.target.checked)}
                />
                <FormLabel ml="1" mb='0' fontSize={"lg"} htmlFor="pastingDisabled" cursor={'pointer'} userSelect={'none'}>
                    Students <strong>cannot</strong> paste into the query box
                </FormLabel>
            </Flex>

            <Flex alignItems={"center"}>
                <Switch
                    id="anonymizeStudents"
                    colorScheme={'primary'}
                    isChecked={course.controls.anonymizeStudents}
                    onChange={(e) => updateCourseControl(params.courseId, 'anonymizeStudents', e.target.checked)}
                />
                <FormLabel ml="1" mb='0' fontSize={"lg"} htmlFor="anonymizeStudents" cursor={'pointer'} userSelect={'none'}>
                    Students are anonymized
                </FormLabel>
            </Flex>
        </Flex>
    )
}

