import { Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { APP_TITLE } from "../lib/CONSTANTS";

export default function Logo({ isSmall=false, darkOnLight=false }){
    return (
        // <Image
        //     src="/logo.png"
        //     width={80}
        //     height={80}
        //     alt="Kodiak Logo image"
        // />
        <img width={isSmall ? 100 : 200} src={useColorModeValue(darkOnLight ? "/ata_logo_2.png" : "/ata_logo_2_white.png", "/ata_logo_2_white.png")} />
        // <Flex alignItems={'center'} flexDir={'row'} justifyContent={'center'} mr='3'>
        //     <img width={isSmall ? 50 : 75} src="/bear_logo.png" />
        //     <Text ml='3' fontSize={'3xl'}>Kodiak</Text>
        // </Flex>
    )
}