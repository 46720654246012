import { doc, getDoc, onSnapshot} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../lib/firebase";
import { Course, ExtraCourseData } from "../types/types";


export default function useSingleCourse(courseId: string, includeParsedData=false, includeUserEmails=false){
    const [course, setCourse] = useState(undefined)
    const [isMainDataLoading, setIsMainDataLoading] = useState(true)
    const [isParsedDataLoading, setIsParsedDataLoading] = useState(true)
    const [isUserEmailsLoading, setIsUserEmailsLoading] = useState(true)

    const [parsedData, setParsedData] = useState(undefined)
    const [userEmails, setUserEmails] = useState(undefined)
    const [adminEmails, setAdminEmails] = useState(undefined)

    const fetchParsedData = async () => {
        const docRef = doc(db, "Courses", courseId, 'Data', 'parsed')

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()){
                setParsedData(doc.data())
            }else{
                console.error(`Parsed data document Courses/${courseId}/Data/parsed does not exist`)
            }
            setIsParsedDataLoading(false)
        })
    }

    const fetchUserEmails = async () => {
        const docRef = doc(db, "Courses", courseId, 'Data', 'users')

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()){
                setUserEmails(doc.data().userEmails)
                setAdminEmails(doc.data().adminEmails)
            }else{
                console.error(`Parsed data document Courses/${courseId}/Data/users does not exist`)
            }
            setIsUserEmailsLoading(false)
        })
    }

    const fetchCourse = async () => {

        const docRef = doc(db, "Courses", courseId)

        const unsub = onSnapshot(docRef, (doc) => {
            if (doc.exists()){
                setCourse({...doc.data(), id: courseId})
            }else{
                console.error(`Course document Courses/${courseId} does not exist`)
            }
            setIsMainDataLoading(false)
        })
    }

    useEffect(() => {     
        fetchCourse()

        if(includeParsedData) fetchParsedData()
        else setIsParsedDataLoading(false)

        if(includeUserEmails) fetchUserEmails()
        else setIsUserEmailsLoading(false)
    }, [courseId])


    if(!includeParsedData && !includeUserEmails){
        return [course, isParsedDataLoading || isMainDataLoading || isUserEmailsLoading] as [Course, boolean]
    }

    if(!includeParsedData && includeUserEmails){
        return [course, isParsedDataLoading || isMainDataLoading || isUserEmailsLoading, userEmails, adminEmails] as [Course, boolean, string[], string[]]
    }

    if(includeParsedData && !includeUserEmails){
        return [course, isParsedDataLoading || isMainDataLoading || isUserEmailsLoading, parsedData] as [Course, boolean, ExtraCourseData]
    }

    if(includeParsedData && includeUserEmails){
        return [course, isParsedDataLoading || isMainDataLoading || isUserEmailsLoading, parsedData, userEmails, adminEmails] as [Course, boolean, ExtraCourseData, string[], string[]]
    }
}