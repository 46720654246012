import { Button, Modal, ModalOverlay, Heading, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Box, Checkbox, Stack, Divider, Link, Alert, AlertDescription, AlertIcon, AlertTitle, useColorModeValue } from "@chakra-ui/react"
import * as terms from './terms.json'
import { useState } from "react"

const sectionTitles = ['User Authentication and Identity Logging', 'Usage Guidelines and Restrictions']

function getAllQuestions() {
    const questions = []
    for (const title of sectionTitles) {
        for (const { question } of terms[title]) questions.push(question)
    }
    return questions
}

export default function TermsModal({ isOpen,onTermsAccept }) {
    const [questionsAccepted, setQuestionsAccepted] = useState([])
    const [error, setError] = useState(false)

    const handleAcceptChange = (accepted, question) => {
        let temp = [...questionsAccepted]
        if (accepted) temp = [...questionsAccepted, question]
        else temp = temp.filter((v, _) => v != question)
        setQuestionsAccepted(temp)
    }

    const handleAccept = () => {
        let missingQuestions = []
        for (const q of getAllQuestions()) {
            if (!questionsAccepted.includes(q)) missingQuestions.push(q)
        }

        if (missingQuestions.length > 0) {
            setError(true)
            return
        }

        onTermsAccept()
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={console.log} // closing is not allowed hehe
            size={'3xl'}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <ModalOverlay />
            {/* <ModalOverlay
                bg='none'
                backdropFilter='auto'
                backdropInvert='80%'
                backdropBlur='2px'
            /> */}
            <ModalContent
                maxH='85vh'
                overflowY={'scroll'}
            >
                <ModalHeader>Terms of Service</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>

                    <Text mb='6' fontSize={'lg'}>Welcome to ATA! By accessing or using our platform, you agree to be bound by these Terms of Service ("Terms"). If you do not agree with any part of these Terms, you cannot use our services.</Text>

                    <Stack gap={6} divider={<Divider />}>
                        {sectionTitles.map((title, i) => (
                            <Box key={i}>
                                <Heading fontSize={'2xl'}>{title}</Heading>
                                <Stack gap={6}>
                                    {terms[title].map(({ text, question }, j) =>
                                        <Clause
                                            text={text}
                                            question={question}
                                            key={j}
                                            onAcceptChange={(accepted) => handleAcceptChange(accepted, question)}
                                        />)}
                                </Stack>
                            </Box>
                        ))}
                    </Stack>

                    <Text mt='6' fontSize='lg'>For any questions or concerns regarding these Terms, please contact us at <Link href="mailto:team@talktoata.com" color={useColorModeValue('primary.600', 'primary.400')}>team@talktoata.com</Link>.</Text>

                    {error && <Alert my={'3'} status='error'>
                                <AlertIcon />
                                <AlertTitle>Please check 'yes' for each clause.</AlertTitle>
                            </Alert>
                    }

                </ModalBody>

                <ModalFooter>
                    {/* <Button variant='ghost' fontWeight={400}>Close without accepting</Button> */}
                    <Button colorScheme='primary' ml='3' color={'white'} fontWeight={400} onClick={handleAccept}>
                        Accept
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

function Clause({ text, question, onAcceptChange }) {
    const inputName = `checkbox-${question.replaceAll(" ", "-")}`

    return (
        <Box>
            <label htmlFor={inputName}>
                <Text cursor='pointer' fontSize='md'>{text}</Text>
                <Text cursor='pointer' fontSize='md' mt='3' fontWeight={'bold'}>{question}</Text>
            </label>
            <Checkbox name={inputName} id={inputName} onChange={(e) => onAcceptChange(e.target.checked)} mt={2} colorScheme={'primary'}>Yes</Checkbox>
        </Box>
    )
}
