import { Box, Button, Circle, Container, Flex, Hide, Icon, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import Logo from "../../ui/Logo";
import { FaCircle } from 'react-icons/fa'
import { ColorModeToggle } from "../../ui/AppNav";


export default function Nav({ onClickLogin, loggedIn, onClickLogout, enterApp }){

    return (
        <Container py='4' display={'flex'} maxW='5xl' justifyContent={'space-between'} alignItems={'center'}>
            <Flex alignItems={'center'}>
                <Logo darkOnLight />
                <Hide below='md'>
                    <Tag size='md' alignSelf={'center'} ml={0} colorScheme={'primary'} variant={'outline'}>
                        <TagLeftIcon as={FaCircle} boxSize={'8px'} />
                        <TagLabel>Beta</TagLabel>
                    </Tag>
                </Hide>
            </Flex>
            <Flex alignItems={'center'} gap='3'>
                {/* <ColorModeToggle /> */}
                {loggedIn && <Button 
                    fontWeight={400} 
                    variant={'solid'} 
                    color='white' 
                    colorScheme={'primary'} 
                    onClick={enterApp} 
                    px={12}
                >
                    Go to Dashboard
                </Button>}
                { !loggedIn && <Button 
                    fontWeight={400} 
                    variant={'solid'} 
                    color='white' 
                    colorScheme={'primary'} 
                    onClick={onClickLogin} 
                    px={12}
                >
                    Login
                </Button> }
            </Flex>
        </Container>
    )
}