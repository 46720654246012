import { TableContainer, Table, Thead, Tr, Th, Tbody, Tfoot, Td, HStack, Box } from "@chakra-ui/react"
import { Timestamp } from "firebase/firestore"
import { ReactNode, useState } from "react"
import { compareDates } from "../../../lib/date_utils"
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"
import useStickyState from "../../../hooks/useStickyState"


export type Column<T> = {
    title: string
    accessor: (a: T) => string | ReactNode
    isNumeric: boolean
    compareFunc: (a: T, b: T) => number
}

export default function TableWithSorting<T>({ items, columns, name, initialColumnIndexToSort=-1, isInitiallyReverse=false }: { items: T[], columns: Column<T>[], name: string, initialColumnIndexToSort?: number, isInitiallyReverse?: boolean }) {
    const [columnIndexToSort, setColumnIndexToSort] = useStickyState(initialColumnIndexToSort, `${name}-columnIndexToSort`)
    const [isReverseSort, setIsReverseSort] = useStickyState(isInitiallyReverse, `${name}-isReverseSort`)

    const handleSortClick = (columnIndex: number) => {
        if(columnIndexToSort == columnIndex){ 
            setIsReverseSort(!isReverseSort)
        } else{
            setColumnIndexToSort(columnIndex)
            setIsReverseSort(false)
        }
    }

    if(columnIndexToSort !== -1){
        const compareFunc = isReverseSort ? (a, b) => -columns[columnIndexToSort].compareFunc(a, b) : columns[columnIndexToSort].compareFunc
        items.sort(compareFunc)
    }

    return (
        <TableContainer>
            <Table variant='striped' colorScheme={'slate'} borderWidth={1} borderRadius={10}>
                <Thead>
                    <Tr>
                        {columns.map(({ title, isNumeric }, i) => 
                            <Th userSelect={'none'} cursor='pointer' onClick={() => handleSortClick(i)} key={i} isNumeric={isNumeric}>
                                {title}
                                <Box ml='1' as='span'>
                                    { columnIndexToSort==i && (isReverseSort ? <TriangleDownIcon aria-label="sorted descending" /> : <TriangleUpIcon aria-label="sorted ascending" />) }
                                </Box>
                            </Th>)}
                    </Tr>
                </Thead>

                <Tbody>
                    {items.map((item, i) => <TableItem item={item} columns={columns} key={i} />)}
                </Tbody>

                <Tfoot>
                    <Tr>
                        {columns.map(({ title, isNumeric }, i) => <Th key={i} isNumeric={isNumeric}>{title}</Th>)}
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

function TableItem<T>({ item, columns}: { item: T, columns: Column<T>[] }) {

    return (
        <Tr>
            { columns.map(({ accessor, isNumeric }, i) => <Td key={i} isNumeric={isNumeric}>{ accessor(item) }</Td>) }
        </Tr>
    )
}


export function compareStrings(a: string, b: string){
    if(!a) return 1
    if(!b) return -1
    return a.toLowerCase() < b.toLowerCase() ? 1 : -1
}

export function compareTimestamps(a: Timestamp, b: Timestamp){
    if(!a) return 1
    if(!b) return -1
    return compareDates(a.toDate(), b.toDate()) ? 1 : -1
}

export function compareNumbers(a: number, b: number){
    // if(!a) return 1
    // if(!b) return -1
    return a - b
}