import { doc, onSnapshot } from "firebase/firestore"
import { useState, useEffect } from "react"
import { logEvent } from "firebase/analytics"
import { analytics, db } from "../lib/firebase"
import { User } from "../types/types"

export default function useUserDocFromUID(uid: string){
    const [userDoc, setUserDoc] = useState<User>(undefined)
    const [isUserDoc, setIsUserDoc] = useState(false)

    useEffect(() => {
        if(!uid) {
            setUserDoc(undefined)
            setIsUserDoc(false)
            return
        }

        const userDocRef = doc(db, "Users", uid)

        const unsub = onSnapshot(userDocRef, (doc) => {
            setUserDoc({...doc.data() as User, uid, exists: true})
            setIsUserDoc(true)
            logEvent(analytics, "login")
        })

        return () => unsub()
    }, [uid])

    return [userDoc, isUserDoc] as [User, boolean]
}
