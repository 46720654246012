import { Button, Icon, Link } from "@chakra-ui/react"
import { Conversation } from "../../../types/types"
import { formatDateShort } from "../../../lib/date_utils"
import { Link as ReactRouterLink } from "react-router-dom"
import TableWithSorting, { Column, compareNumbers, compareStrings, compareTimestamps } from "../users/TableWithSorting"
import { FaExternalLinkAlt } from "react-icons/fa"

export default function ConversationTable({ convos, courseId, renderUser, name }: { convos: Conversation[], courseId: string, renderUser: boolean, name: string }) {

    const columns: Column<Conversation>[] = [
        {
            title: 'Conversation',
            accessor: ({title, id}) => <Link as={ReactRouterLink} gap='2' display={'flex'} alignItems={'center'} to={`/app/${courseId}/share/${id}`}>{title}<Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400'/></Link>,
            isNumeric: false,
            compareFunc: ({title: title1}, {title: title2}) => compareStrings(title1, title2)
        },
        {
            title: 'Queries',
            accessor: ({responses}) => responses.length,
            isNumeric: true,
            compareFunc: ({responses: responses1}, {responses: responses2}) => compareNumbers(responses1.length, responses2.length)
        },
        {
            title: 'Malicious Queries',
            accessor: ({maliciousQueries}) => maliciousQueries,
            isNumeric: true,
            compareFunc: ({maliciousQueries: queries1}, {maliciousQueries: queries2}) => compareNumbers(queries1, queries2)
        },
        {
            title: 'Off-Topic Queries',
            accessor: ({offTopicQueries}) => offTopicQueries,
            isNumeric: true,
            compareFunc: ({offTopicQueries: queries1}, {offTopicQueries: queries2}) => compareNumbers(queries1, queries2)
        },
        // {
        //     title: 'Date Created',
        //     accessor: ({timestampCreated}) => formatDateShort(timestampCreated.toDate()),
        //     isNumeric: false,
        //     compareFunc: ({timestampCreated: timestamp1}, {timestampCreated: timestamp2}) => compareTimestamps(timestamp1, timestamp2)
        // },
        {
            title: 'Date Last Updated',
            accessor: ({timestampLastUpdated}) => formatDateShort(timestampLastUpdated.toDate()),
            isNumeric: false,
            compareFunc: ({timestampLastUpdated: timestamp1}, {timestampLastUpdated: timestamp2}) => compareTimestamps(timestamp1, timestamp2)
        }
    ]

    if(renderUser){
        columns.push({
            title: "Student",
            accessor: ({ userName, userDocRef }) => <Link as={ReactRouterLink} gap='2' display={'flex'} alignItems={'center'} to={userDocRef && `/app/${courseId}/admin/users/${userDocRef.id}`}>{userName}<Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400'/></Link>,
            isNumeric: false,
            compareFunc: ({userName: user1}, {userName: user2}) => compareStrings(user1, user2)
        })
    }

    return (
        <TableWithSorting 
            columns={columns}
            items={convos}
            name={name}
            initialColumnIndexToSort={4} // date last updated
            isInitiallyReverse
        />
    )
}