import { Icon, Link } from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { UserPlus } from "../../../hooks/useCourseUsers"
import { formatDateShort } from "../../../lib/date_utils"
import TableWithSorting, { Column, compareNumbers, compareStrings, compareTimestamps } from "./TableWithSorting"
import { LinkIcon } from "@chakra-ui/icons"
import { FaExternalLinkAlt } from "react-icons/fa"

export default function UserTable({ users, courseId }: { users: UserPlus[], courseId: string }) {
    const columns: Column<UserPlus>[] = [
        {
            title: 'Name',
            accessor: ({ displayName, uid }) => <Link as={ReactRouterLink} textDecor={'none'} display={'flex'} alignItems={'center'} gap='1' to={uid && `/app/${courseId}/admin/users/${uid}`}>{displayName}<Icon as={FaExternalLinkAlt} boxSize='3' color='slate.400'/></Link>,
            isNumeric: false,
            compareFunc: ({displayName: name1, displayName: name2}) => compareStrings(name1, name2)
        },
        {
            title: 'Total Queries',
            accessor: ({ totalExchanges }) => totalExchanges ?? 0,
            isNumeric: true,
            compareFunc: ({ totalExchanges: exchanges1 }, { totalExchanges: exchanges2 }) => compareNumbers(exchanges1, exchanges2)
        },
        {
            title: 'Total Conversations',
            accessor: ({ totalConversations }) => totalConversations ?? 0,
            isNumeric: true,
            compareFunc: ({ totalConversations: conversations1 }, { totalConversations: conversations2 }) => compareNumbers(conversations1, conversations2)
        },
        {
            title: "Last Active",
            accessor: ({ timestampLastQueried }) => timestampLastQueried ? formatDateShort(timestampLastQueried.toDate()) : 'N/A',
            isNumeric: false,
            compareFunc: ({ timestampLastQueried: timestamp1 }, { timestampLastQueried: timestamp2 }) => compareTimestamps(timestamp1, timestamp2)
        },
        {
            title: "Total Malicious Queries",
            accessor: ({ filterDecisionCounts }) => filterDecisionCounts ? filterDecisionCounts.MALICIOUS : '0',
            isNumeric: true,
            compareFunc: ({ filterDecisionCounts: counts1 }, { filterDecisionCounts: counts2 }) => compareNumbers(counts1 ? counts1.MALICIOUS : 0, counts2 ? counts2.MALICIOUS : 0)
        },
        {
            title: "Total Off-Topic Queries",
            accessor: ({ filterDecisionCounts }) => filterDecisionCounts ? filterDecisionCounts.OFFTOPIC : '0',
            isNumeric: true,
            compareFunc: ({ filterDecisionCounts: counts1 }, { filterDecisionCounts: counts2 }) => compareNumbers(counts1 ? counts1.OFFTOPIC : 0, counts2 ? counts2.OFFTOPIC : 0)
        }
    ]

    return (
        <TableWithSorting 
            columns={columns}
            items={users}
            name={"user_table"}
        />
    )
}
