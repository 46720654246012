import { useEffect, useState } from "react"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from '../../lib/firebase'
import { TestSuite } from "."

export default function useTestSuites(){
    const [suites, setSuites] = useState<TestSuite[] | undefined>(undefined)

    const fetchSuites = async () => {
        const snapshot = await getDocs(collection(db, 'Test'))

        const docs = snapshot.docs.map((d, i) => ({...d.data(), hash: d.id}))
        setSuites(docs as TestSuite[])
    }

    useEffect(() => {
        fetchSuites()
    }, [])

    return suites
}