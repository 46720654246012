import { useEffect, useState } from "react"
import { Conversation } from '../../types/types'
import { collection, documentId, DocumentReference, getDocs, query, where } from "firebase/firestore"
import { db } from '../../lib/firebase'
import { TestConversation } from "."

export default function useRunConversations(suite?: string, convoRefs?: DocumentReference[]){
    const [conversations, setConversations] = useState<TestConversation[] | undefined>(undefined)

    const fetchConversations = async () => {
        if(!suite || !convoRefs) return

        const ids = convoRefs.map((r, i) => r.id)

        const q = query(
            collection(db, 'Test', suite, 'Conversations'), 
            where(documentId(), 'in', ids)
        )

        const snapshot = await getDocs(q)

        const docs = snapshot.docs.map((d, i) => d.data())
        setConversations(docs as TestConversation[])
    }

    useEffect(() => {
        if(!suite || !convoRefs) return
        fetchConversations()
    }, [suite, convoRefs])

    return conversations
}