
export default function average(numbers: number[]) {
    return numbers.reduce((acc, curr) => acc + curr / numbers.length, 0)
}

export function deepEqual(obj1, obj2) {
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
        return obj1 === obj2; // Base case for primitives
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export function compareListOfObjects(list1, list2) {
    if (list1.length !== list2.length) return false;

    for (let i = 0; i < list1.length; i++) {
        if (!deepEqual(list1[i], list2[i])) {
            return false;
        }
    }

    return true;
}