import { doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../lib/firebase"
import { UserListDocument } from "../types/types"


export default function useCourseAllowedUsersList(courseId: string){
    const [studentEmails, setStudentEmails] = useState([])
    const [adminEmails, setAdminEmails] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchUserList = () => {
        setIsLoading(true)
        const docRef = doc(db, "Courses", courseId, "Data", "users")
        
        const unsub = onSnapshot(docRef, snapshot => {
            const userListDoc = snapshot.data() as UserListDocument
            setStudentEmails(userListDoc.userEmails)
            setAdminEmails(userListDoc.adminEmails)
            setIsLoading(false)
        })

        return unsub
    }

    useEffect(() => {
        return fetchUserList()
    }, [courseId])

    return [studentEmails, adminEmails, isLoading]
}