import { Text, Tooltip, useColorModeValue } from "@chakra-ui/react"
import { query, Timestamp } from "firebase/firestore"
import { useEffect, useState } from "react"
import { getMinutesSince, getSecondsSince } from "../../lib/date_utils"

export function computeActualQueriesInStock(queriesInStock: number, timestampLastRegenerated: Timestamp, maxStockSize: number, minutesPerRegen: number) {
    const minutesSinceLastRegen = getMinutesSince(timestampLastRegenerated.toDate())

    const toAdd = Math.floor(minutesSinceLastRegen / minutesPerRegen)

    const result = Math.min(queriesInStock + toAdd, maxStockSize)
    // console.log("in stock:", queriesInStock, "minutes since:", minutesSinceLastRegen, "to add:", toAdd, "result:", result)
    return result
}

export default function Quota({ queriesInStock, timestampLastRegenerated, maxStockSize, minutesPerRegen }) {
    const [actualQueriesInStock, setActualQueriesInStock] = useState(() => computeActualQueriesInStock(queriesInStock, timestampLastRegenerated, maxStockSize, minutesPerRegen))

    const normalTextColor = useColorModeValue('slate.700', 'slate.300')
    const emptyQuotaColor = useColorModeValue('red.500', 'red.600')

    useEffect(() => {
        const interval = setInterval(() => {
            setActualQueriesInStock(computeActualQueriesInStock(queriesInStock, timestampLastRegenerated, maxStockSize, minutesPerRegen));
        }, 1000)

        return () => clearInterval(interval)
    }, [queriesInStock, timestampLastRegenerated, maxStockSize, minutesPerRegen]);


    const minutesSinceRegen = getMinutesSince(timestampLastRegenerated.toDate())
    const minutesTillRegen = minutesPerRegen - (minutesSinceRegen % minutesPerRegen)
    // const secondsTillRegen = ((minutesPerRegen*60) - (getSecondsSince(timestampLastRegenerated.toDate()) & (minutesPerRegen*60))) % 60
    const tooltipLabel = actualQueriesInStock == maxStockSize ? 'Query Quota. Stock is full!' : `Query Quota. Next regen in ${minutesTillRegen} ${minutesTillRegen > 1 ? 'minutes' : 'minute'}`

    return (
        <Tooltip label={tooltipLabel} hasArrow>
            <Text color={actualQueriesInStock == 0 ? emptyQuotaColor : normalTextColor} fontSize={'md'} fontWeight={'bold'}>{actualQueriesInStock} queries left</Text>
        </Tooltip>
    )
}